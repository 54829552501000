import moment from 'moment-timezone'
import * as api from '@bakkt/api'
import {Application as BakktComponentApplication, RelativeTime} from '@bakkt/components'
import {FilterSearchCriteriaEnum} from '@bakkt/api'

const getDateTimeWithGlobalTimezone = (date: moment.MomentInput) => {
	if (moment(date).isValid()) {
		const timezone = BakktComponentApplication.timezone
		const formattedDate = moment(date).tz(timezone).format('MM/DD/YYYY')
		const formattedTime = moment(date).tz(timezone).format('HH:mm:ss')
		const timeZoneAbbr = moment.tz(timezone).zoneAbbr()
		return {formattedDate, formattedTime, timeZoneAbbr}
	}
	return {}
}

export const formatDateTimeWithGlobalTimezone = (date: moment.MomentInput) => {
	if (moment(date).isValid()) {
		if (date && !date.toString().includes(':')) {
			return `${moment(date).format('MM/DD/YYYY')}`
		}
		const {formattedDate, formattedTime, timeZoneAbbr} = getDateTimeWithGlobalTimezone(date)
		return `${formattedDate} | ${formattedTime} ${timeZoneAbbr}`
	}
}

export const formatDateTime = (date: moment.MomentInput) => {
	if (moment(date).isValid()) {
		const {formattedDate} = getDateTimeWithGlobalTimezone(date)
		return formattedDate
	}
}

const toDateRange = (eventTime: api.Filter) => {
	if (eventTime == null) return [null, null]
	if (eventTime.searchCriteria === 'AFTER') {
		return [eventTime.fieldValues[0], null]
	}
	if (eventTime.searchCriteria === 'BEFORE') {
		return [null, eventTime.fieldValues[0]]
	}
	return eventTime.fieldValues
}

const relativeDateRange = (duration: string) => {
	let from
	const to = moment().tz(BakktComponentApplication.timezone)
	switch (duration) {
		case RelativeTime.LAST7DAYS:
			from = moment().tz(BakktComponentApplication.timezone).subtract(7, 'days')
			break
		case RelativeTime.LAST14DAYS:
			from = moment().tz(BakktComponentApplication.timezone).subtract(14, 'days')
			break
		case RelativeTime.LAST30DAYS:
			from = moment().tz(BakktComponentApplication.timezone).subtract(30, 'days')
			break
		case RelativeTime.LAST90DAYS:
			from = moment().tz(BakktComponentApplication.timezone).subtract(90, 'days')
			break
		default:
			from = moment().tz(BakktComponentApplication.timezone).subtract(14, 'days')
	}
	return [from.format(), to.format()]
}

export const toDateTimeRange = (eventTime: api.Filter) => {
	if (eventTime.searchCriteria === FilterSearchCriteriaEnum.RELATIVETIME) {
		return relativeDateRange(eventTime.fieldValues[0])
	}
	const dateRange = toDateRange(eventTime)
	const toDate =
		dateRange[1] &&
		(eventTime['filterType'] === 'date'
			? moment(dateRange[1]).endOf('day').utc(true)
			: moment(dateRange[1]).endOf('day'))
	const fromDate =
		dateRange[0] &&
		(eventTime['filterType'] === 'date'
			? moment(dateRange[0]).startOf('day').utc(true)
			: moment(dateRange[0]).startOf('day'))
	const dateSpecified = fromDate || toDate
	const fromDateReturned = dateSpecified && (fromDate || toDate.clone().subtract(6, 'month')).format()
	const toDateReturned = dateSpecified && (toDate || moment()).format()
	return [fromDateReturned, toDateReturned]
}

export function formatDateTimeWithTimeZone(datetime: string, timezone: string) {
	if (datetime && moment(datetime).isValid()) {
		const dateWithTimeZone = appendTimeZoneToDatetime(datetime, timezone)
		const timeZoneAbbr = getTimeZoneAbbr(timezone)
		const formatedDate = moment(dateWithTimeZone).format('MM-DD-YYYY')
		const time = moment(dateWithTimeZone).format('HH:mm')
		return `${formatedDate} | ${time} ${timeZoneAbbr}`
	}

	return datetime
}

export function appendTimeZoneToDatetime(datetime: moment.MomentInput, timezone: string) {
	return moment.tz(datetime, timezone)
}

export function getTimeZoneAbbr(timezone: string) {
	return moment.tz(timezone).zoneAbbr()
}
