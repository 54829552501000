import * as api from '@bakkt/api'
import {PortalPermission, PortalRole, User} from './AppState'
export {PortalPermission, PortalRole}

export const PORTAL_PERMISSIONS_LIST = Object.entries(api.ApiMerchantPortalRolePermissionsEnum).map(e => e[1])
export const PARTNER_PORTAL_PERMISSIONS_LIST = Object.entries(api.ApiPortalRolePermissionsEnum).map(e => e[1])

class PermissionManager {
	currentUser: User | null = null

	/*
    returns boolean whether user has ANY of the required permissions
    @param userPermissions - array containing the user's permissions
    @param requiredPermissions - array containing permissions that are allowed to access
    */
	canAccess(userPermissions: PortalPermission[], requiredPermissions: PortalPermission[]) {
		if (userPermissions.find(userPermission => requiredPermissions.indexOf(userPermission) >= 0) != null)
			return true
		return false
	}

	/*
    state-dependent wrapper around canAccess that uses the user currently in the store
    returns boolean whether the operation is allowed for the user
    @param requiredPermissions - any allowed permissions
    */
	userAllowed(requiredPermissions: PortalPermission[]) {
		const user = this.getCurrentUser()
		if (user == null) return false
		return this.canAccess(user.permissions, requiredPermissions)
	}

	getCurrentUser() {
		return this.currentUser
	}

	setCurrentUser(user: User) {
		this.currentUser = user
	}

	isBakktUser = () => {
		const user = this.getCurrentUser()
		const regexEmail = /^\w+([\.-]?\w+)*@bakkt(\.\w{2,3})+$/
		return user.email.match(regexEmail) ? true : false
	}

	// Returns boolean whether user has ANY of the required CSR permissions
	isAnyCSRAdminPermission() {
		return this.userAllowed(Object.values(PortalPermission))
	}

	canAcceptDispute() {
		return this.userAllowed([PortalPermission.DISPUTEACCEPT])
	}

	canRejectDispute() {
		return this.userAllowed([PortalPermission.DISPUTEREJECT])
	}

	canAddComment() {
		return this.userAllowed([PortalPermission.DISPUTEADDCOMMENT])
	}

	canTakeDisputeAction() {
		return this.userAllowed([
			PortalPermission.DISPUTEACCEPT,
			PortalPermission.DISPUTEREJECT,
			PortalPermission.DISPUTEADDCOMMENT,
		])
	}

	canCancelTransaction() {
		return this.userAllowed([PortalPermission.TRANSACTIONCANCEL])
	}

	canReverseTransaction() {
		return this.userAllowed([PortalPermission.TRANSACTIONREVERSE])
	}

	canTakeTransactionAction() {
		return this.userAllowed([PortalPermission.TRANSACTIONCANCEL, PortalPermission.TRANSACTIONREVERSE])
	}

	canAddUser() {
		return this.userAllowed([PortalPermission.USERSCREATE])
	}

	canUpdateUser() {
		return this.userAllowed([PortalPermission.USERSUPDATE])
	}
}

export default new PermissionManager()
