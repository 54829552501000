import {usersApi, logout, openURLTab, partnerUserApi, getPortalType} from '../common'
import {actions} from './reducer'
import {User, URLParam} from './AppState'
import {store} from '../store'
import {WIDGET_ID} from '../manifest'
import PermissionManager from './PermissionManager'
import {Application as BakktComponentApplication} from '@bakkt/components'

class Application {
	respenseReceived() {
		const dispatch = store.dispatch
		dispatch(actions.responseReceived())
	}
	requestSent() {
		const dispatch = store.dispatch
		dispatch(actions.requestSent())
	}

	getURLInfo() {
		let searchedURL = new URLSearchParams(window.location.search)
		if (searchedURL.has('transaction-id')) {
			let urlSetting = JSON.stringify({
				paramName: 'transaction-id',
				paramId: searchedURL.get('transaction-id'),
			} as URLParam)
			localStorage.setItem('url-parameter', urlSetting)
		}
	}

	async fetchUserInfo() {
		let info
		if (localStorage.getItem('portal-type') === 'partner') {
			info = await partnerUserApi.getPartnerUserSessionInfoUsingGET()
		} else if (localStorage.getItem('portal-type') === 'merchant,partner') {
			//TODO: Display popup modal indicating whether to use merchant or partner - store user response in localStorage 'portal-type'
		} else {
			info = await usersApi.getUserSessionInfoUsingGET1()
		}

		const {roles, email, permissions, name} = info.data

		const partnerIds = info.data.partnerIds || []

		return {
			email,
			name,
			partnerIds,
			roles,
			permissions,
		} as User
	}

	async loadOnStartup() {
		await getPortalType()
		this.getURLInfo()
		const dispatch = store.dispatch
		const currentUser = await this.fetchUserInfo()
		PermissionManager.setCurrentUser(currentUser)
		// handle no portal permissions case
		if (!PermissionManager.isAnyCSRAdminPermission()) {
			logout()
		} else {
			dispatch(actions.staticDataLoad(currentUser))
			dispatch(actions.loadWidgetsForUser(JSON.parse(localStorage.getItem('m_last_app_config'))))
			//Open tab from url search param.
			openURLTab()
			setInterval(() => {
				const settings = JSON.stringify(store.getState().App.workspace)
				console.log('saving settings', settings)
				localStorage.setItem('m_last_app_config', settings)
			}, 30000)
		}
	}

	openTab(name: string, typeId: WIDGET_ID, options?: any, id?: string, newTab?: boolean) {
		const dispatch = store.dispatch
		dispatch(actions.addWidget({title: name, type: typeId, id, options, newTab, isClosable: true}))
	}

	activateTab(id: string) {
		const dispatch = store.dispatch
		dispatch(actions.activateTab(id))
	}

	setTimeZone(timezone: string) {
		BakktComponentApplication.timezone = timezone
	}

	getTimeZone() {
		return BakktComponentApplication.timezone
	}
}

export default new Application()
