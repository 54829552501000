import Alert from '../common/components/Alert'
import {WIDGET_ID} from '../manifest'
import application from '../app/Application'

export const hasApiErrorOccurred = response => {
	if (response == null || response.status !== 200) {
		Alert.error()
		return true
	}
	const err = response.data && response.data.error

	if (err) {
		Alert.error(response.data.error.message, response.data.error.code)
		return true
	}
	return false
}

export const BLANK_VALUE = '--'

export const capitalize = input => {
	return (
		input &&
		input
			.toLowerCase()
			.split(' ')
			.map(s => s.charAt(0).toUpperCase() + s.substring(1))
			.join(' ')
	)
}

export const validUUID = uuidString => {
	const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
	return regexExp.test(uuidString)
}

export const openPartyDetails = data => {
	if (data?.id || data?.partnerPartyRef) {
		const firstName = data?.firstName || ' '
		const lastName = data?.lastName || ' '
		let name = `${firstName} ${lastName}`
		name = name && name.trim()
		const email = data?.email || ''
		const partnerPartyRef = data?.partnerPartyRef || ''
		const alias = data?.alias || ''
		const partnerId = data?.partnerId || ''
		const partyId = data?.partyId || data?.id || ''
		application.openTab(
			alias || name || email || partnerPartyRef,
			WIDGET_ID.PARTNER_CONSUMER_DETAIL,
			{
				partnerPartyRef: partnerPartyRef || null,
				partyId: partyId || null,
				partnerId: partnerId || null,
				viewInTab: true,
			},
			partnerPartyRef,
		)
	}
}
