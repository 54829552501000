import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import AppState, {User, Widget, Workspace} from './AppState'
import manifest from '../manifest'
import {RootState} from '../store'
import PermissionManager from './PermissionManager'
import {ApiMerchantPortalUser} from '@bakkt/api'

const init: AppState = {
	currentUser: {email: '', roles: [], permissions: [], name: '', partnerIds: []},
	workspace: {widgets: [], activeWidgetId: null},
	activeRequests: 0,
	zendeskUsers: [],
	type: '',
}
export const appSlice = createSlice({
	name: 'application',
	initialState: init,
	reducers: {
		staticDataLoad: (state, action: PayloadAction<User>) => {
			state.currentUser = action.payload
			state.type = localStorage.getItem('portal-type') === 'partner' ? 'partner' : 'merchant'
			PermissionManager.setCurrentUser(action.payload)
		},
		zendeskUserLoad: (state, action: PayloadAction<ApiMerchantPortalUser[]>) => {
			state.zendeskUsers = action.payload
		},
		responseReceived: state => {
			state.activeRequests = state.activeRequests - 1
		},
		requestSent: state => {
			state.activeRequests = state.activeRequests + 1
		},
		addWidget: (state, action: PayloadAction<Widget>) => {
			let newTab: any = {id: '' + new Date().getTime(), ...action.payload}
			if (action.payload.newTab) {
				state.workspace.widgets = [...state.workspace.widgets, newTab]
			} else {
				const index = state.workspace.widgets.findIndex(w => w.id === action.payload.id)
				if (index < 0) {
					state.workspace.widgets = [...state.workspace.widgets, newTab]
				} else {
					state.workspace.widgets[index] = newTab
				}
			}
			state.workspace.activeWidgetId = newTab.id
		},

		removeWidget: (state, action: PayloadAction<string>) => {
			const w = state.workspace.widgets.find(w => w.id === state.workspace.activeWidgetId)
			if (w && !w.isClosable) return
			console.log('removeWidget', action)
			if (state.workspace.activeWidgetId === action.payload) {
				const index = state.workspace.widgets.findIndex(w => w.id === state.workspace.activeWidgetId)
				state.workspace.activeWidgetId = state.workspace.widgets[Math.max(0, index - 1)].id
				console.log('removeWidget', state.workspace.activeWidgetId)
			}
			state.workspace.widgets = state.workspace.widgets.filter(w => w.id !== action.payload)
		},
		activateTab: (state, action: PayloadAction<string>) => {
			console.log(
				'activateTab',
				action,
				state.workspace.widgets.map(w => w.id),
			)
			if (state.workspace.widgets.find(w => w.id === action.payload)) {
				state.workspace.activeWidgetId = action.payload
			} else if (action.payload === null) {
				state.workspace.activeWidgetId = null
			}
		},
		loadWidgetsForUser: (state, action: PayloadAction<Workspace>) => {
			const payload = action.payload || {widgets: null, activeWidgetId: null}
			const savedWidget = payload.widgets || []
			const permissionedRest = manifest.widgets.filter(w => w.isPermissoned() && !w.required).map(w => w.id)
			const startingWidgets = manifest.widgets
				.filter(w => w.isPermissoned() && w.required)
				.map(w => savedWidget.find(s => s.id === w.id) || ({id: w.id, title: w.name, type: w.id} as Widget))
			const rest = savedWidget
				.filter(w => permissionedRest.indexOf(w.type) >= 0)
				.map(w => ({isClosable: true, ...w}))
			state.workspace.widgets = [...startingWidgets, ...rest].filter(w =>
				state.type == 'merchant' ? w.type.startsWith('m_') : w.type.startsWith('p_'),
			)
			if (state.workspace.widgets.find(w => w.id === payload.activeWidgetId)) {
				state.workspace.activeWidgetId = payload.activeWidgetId
			}
			state.workspace.activeWidgetId = state.workspace.activeWidgetId || state.workspace.widgets[0].id
		},
	},
})

export const selectCurrentUser = (state: RootState) => state.App && state.App.currentUser

export const selectCurrentType = (state: RootState) => state.App && state.App.type

export const selectWorkspace = (state: RootState) => state.App && state.App.workspace

export const selectActiveRequests = (state: RootState) => state.App && state.App.activeRequests

export const actions = appSlice.actions
export const {addWidget, staticDataLoad, loadWidgetsForUser, removeWidget, activateTab, zendeskUserLoad} = actions
export default appSlice.reducer
