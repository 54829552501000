import React, {Suspense, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {selectWorkspace, activateTab, actions} from '../reducer'
import manifest from '../../manifest'
import Loading from '../components/Loading'

import {Tabs, HBox, VBox} from '@bakkt/components'

export default () => {
	const dispatch = useDispatch()
	const workspace = useSelector(selectWorkspace)
	const activeWidget = workspace.widgets.find(w => w.id === workspace.activeWidgetId)
	const activeComponent = activeWidget && manifest.widgets.find(w => w.id === activeWidget?.type)
	const [Widget, setWidget] = useState(null)

	const data = workspace.widgets.map(w => {
		return {label: w.title, id: w.id, isClosable: w.isClosable}
	})
	const selectedTab = Math.max(
		data.findIndex(v => v.id === workspace.activeWidgetId),
		0,
	)

	const setActiveTab = (e: any, selectedIndex: number) => {
		const tab = data[selectedIndex].id
		dispatch(activateTab(tab))
	}
	const handleTabClose = item => {
		dispatch(actions.removeWidget(item.id || item))
	}
	useEffect(() => {
		window.dispatchEvent(new CustomEvent('resize'))
	})

	useEffect(() => {
		if (activeComponent) {
			setWidget(React.lazy(activeComponent.component as any))
		}
	}, [workspace])

	return (
		<VBox style={{flexGrow: 1}}>
			<HBox style={{paddingLeft: 105}}>
				<Tabs data={data} value={selectedTab} onChange={setActiveTab} onTabClosed={handleTabClose} />
			</HBox>

			{Widget && activeWidget && (
				<Suspense fallback={<Loading />}>
					<Widget {...activeWidget.options} />
				</Suspense>
			)}
		</VBox>
	)
}
