export const DISPUTE_DETAILS = {
	caseId: 'Case ID',
	disputeId: 'Dispute ID',
	disputeType: 'Dispute Type',
	reasonCode: 'Reason Code',
	replyBy: 'Reply By',
	disputeDate: 'Dispute Created',
}

export const buttonList = {
	approve: {
		displayText: 'Approve',
		title: 'Approve Dispute',
	},
	reject: {
		displayText: 'Reject',
		title: 'Reject Dispute',
	},
	addFile: {
		displayText: 'Add File',
		title: 'Add File to Dispute',
	},
	addComment: {
		displayText: 'Add Comment',
		title: 'Add Comment to Dispute',
	},
}
