// tslint:disable
/**
 * Combine Swagger Endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiAttachment
 */
export interface ApiAttachment {
    /**
     * 
     * @type {string}
     * @memberof ApiAttachment
     */
    attachmentName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachment
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachment
     */
    tokenRef?: string;
}
/**
 * 
 * @export
 * @interface ApiAttachmentRequestReq
 */
export interface ApiAttachmentRequestReq {
    /**
     * 
     * @type {string}
     * @memberof ApiAttachmentRequestReq
     */
    attachmentName?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAttachmentRequestReq
     */
    attachmentRef?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachmentRequestReq
     */
    tokenRef?: string;
}
/**
 * 
 * @export
 * @interface ApiAttachmentRequestReq1
 */
export interface ApiAttachmentRequestReq1 {
    /**
     * 
     * @type {string}
     * @memberof ApiAttachmentRequestReq1
     */
    attachmentName?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAttachmentRequestReq1
     */
    attachmentRef?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAttachmentRequestReq1
     */
    tokenRef?: string;
}
/**
 * 
 * @export
 * @interface ApiComment
 */
export interface ApiComment {
    /**
     * 
     * @type {Array<ApiAttachment>}
     * @memberof ApiComment
     */
    attachment?: Array<ApiAttachment>;
    /**
     * 
     * @type {string}
     * @memberof ApiComment
     */
    commentedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiComment
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiComment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiComment
     */
    owner?: ApiCommentOwnerEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiComment
     */
    ticketId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiComment
     */
    value?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiCommentOwnerEnum {
    BAKKT = 'BAKKT',
    MERCHANT = 'MERCHANT'
}

/**
 * 
 * @export
 * @interface ApiCommentRequest
 */
export interface ApiCommentRequest {
    /**
     * 
     * @type {Array<ApiAttachmentRequestReq>}
     * @memberof ApiCommentRequest
     */
    attachments?: Array<ApiAttachmentRequestReq>;
    /**
     * 
     * @type {string}
     * @memberof ApiCommentRequest
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface ApiCommentRequest1
 */
export interface ApiCommentRequest1 {
    /**
     * 
     * @type {Array<ApiAttachmentRequestReq>}
     * @memberof ApiCommentRequest1
     */
    attachments?: Array<ApiAttachmentRequestReq>;
    /**
     * 
     * @type {string}
     * @memberof ApiCommentRequest1
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface ApiCommentRequest2
 */
export interface ApiCommentRequest2 {
    /**
     * 
     * @type {Array<ApiAttachmentRequestReq>}
     * @memberof ApiCommentRequest2
     */
    attachments?: Array<ApiAttachmentRequestReq>;
    /**
     * 
     * @type {string}
     * @memberof ApiCommentRequest2
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface ApiCommentRequest3
 */
export interface ApiCommentRequest3 {
    /**
     * 
     * @type {Array<ApiAttachmentRequestReq>}
     * @memberof ApiCommentRequest3
     */
    attachments?: Array<ApiAttachmentRequestReq>;
    /**
     * 
     * @type {string}
     * @memberof ApiCommentRequest3
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface ApiCommentRequest4
 */
export interface ApiCommentRequest4 {
    /**
     * 
     * @type {Array<ApiAttachmentRequestReq>}
     * @memberof ApiCommentRequest4
     */
    attachments?: Array<ApiAttachmentRequestReq>;
    /**
     * 
     * @type {string}
     * @memberof ApiCommentRequest4
     */
    comment?: string;
}
/**
 * 
 * @export
 * @interface ApiContact
 */
export interface ApiContact {
    /**
     * 
     * @type {string}
     * @memberof ApiContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContact
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiContact
     */
    phone?: string;
}
/**
 * 
 * @export
 * @interface ApiCreateMerchantRole
 */
export interface ApiCreateMerchantRole {
    /**
     * 
     * @type {string}
     * @memberof ApiCreateMerchantRole
     */
    merchantId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiCreateMerchantRole
     */
    permissions?: Array<ApiCreateMerchantRolePermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateMerchantRole
     */
    roleDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCreateMerchantRole
     */
    roleName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiCreateMerchantRolePermissionsEnum {
    ADMINCREATE = 'ADMIN_CREATE',
    ADMINUPDATE = 'ADMIN_UPDATE',
    DISPUTEACCEPT = 'DISPUTE_ACCEPT',
    DISPUTEADDCOMMENT = 'DISPUTE_ADD_COMMENT',
    DISPUTEDOWNLOAD = 'DISPUTE_DOWNLOAD',
    DISPUTEDOWNLOADATTACHMENT = 'DISPUTE_DOWNLOAD_ATTACHMENT',
    DISPUTEFILEUPLOAD = 'DISPUTE_FILE_UPLOAD',
    DISPUTEREJECT = 'DISPUTE_REJECT',
    DISPUTEVIEW = 'DISPUTE_VIEW',
    DISPUTEVIEWCOMMENT = 'DISPUTE_VIEW_COMMENT',
    PROFILEUPDATE = 'PROFILE_UPDATE',
    PROFILEVIEW = 'PROFILE_VIEW',
    SETTLEMENTREPORTVIEW = 'SETTLEMENT_REPORT_VIEW',
    TRANSACTIONCANCEL = 'TRANSACTION_CANCEL',
    TRANSACTIONDOWNLOAD = 'TRANSACTION_DOWNLOAD',
    TRANSACTIONREVERSE = 'TRANSACTION_REVERSE',
    TRANSACTIONVIEW = 'TRANSACTION_VIEW',
    USERSCREATE = 'USERS_CREATE',
    USERSUPDATE = 'USERS_UPDATE',
    USERSUPDATESYNC = 'USERS_UPDATE_SYNC',
    USERSVIEW = 'USERS_VIEW',
    USERSVIEWDETAIL = 'USERS_VIEW_DETAIL'
}

/**
 * 
 * @export
 * @interface ApiDashBoardMetricsDetails
 */
export interface ApiDashBoardMetricsDetails {
    /**
     * 
     * @type {number}
     * @memberof ApiDashBoardMetricsDetails
     */
    disputeCreatedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDashBoardMetricsDetails
     */
    partyOnBoardedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiDashBoardMetricsDetails
     */
    txnCreatedCount?: number;
}
/**
 * 
 * @export
 * @interface ApiDisputeChargebackRequest
 */
export interface ApiDisputeChargebackRequest {
    /**
     * 
     * @type {ApiCommentRequest}
     * @memberof ApiDisputeChargebackRequest
     */
    comments?: ApiCommentRequest;
}
/**
 * 
 * @export
 * @interface ApiDisputeChargebackRequest1
 */
export interface ApiDisputeChargebackRequest1 {
    /**
     * 
     * @type {ApiCommentRequest}
     * @memberof ApiDisputeChargebackRequest1
     */
    comments?: ApiCommentRequest;
}
/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    code?: string;
    /**
     * 
     * @type {Array<ApiErrorDetail>}
     * @memberof ApiError
     */
    details?: Array<ApiErrorDetail>;
    /**
     * Human readable error message
     * @type {string}
     * @memberof ApiError
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    trace?: string;
}
/**
 * 
 * @export
 * @interface ApiErrorDetail
 */
export interface ApiErrorDetail {
    /**
     * 
     * @type {string}
     * @memberof ApiErrorDetail
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiErrorDetail
     */
    field?: string;
    /**
     * Human readable error message
     * @type {string}
     * @memberof ApiErrorDetail
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiErrorDetail
     */
    rejectedValue?: object;
}
/**
 * 
 * @export
 * @interface ApiMerchant
 */
export interface ApiMerchant {
    /**
     * 
     * @type {string}
     * @memberof ApiMerchant
     */
    account?: string;
    /**
     * Only mandatory for dynamic redirect validation. Checks if redirect url starts with one of the prefixes.
     * @type {Array<string>}
     * @memberof ApiMerchant
     */
    allowedRedirectPrefixes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchant
     */
    bankName?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchant
     */
    businessPartnerRef?: number;
    /**
     * Url used to notify the merchant that a party has unlinked a payment account to merchant. This mean merchant won\'t be able to authorize new payments.
     * @type {string}
     * @memberof ApiMerchant
     */
    callbackNotificationAccountUnlinked?: string;
    /**
     * Url used to notify merchant party approved account payment integration
     * @type {string}
     * @memberof ApiMerchant
     */
    callbackNotificationUrl?: string;
    /**
     * Version of the notification API that is used to inform a merchant that the party approved account payment integration
     * @type {string}
     * @memberof ApiMerchant
     */
    callbackNotificationVersion?: ApiMerchantCallbackNotificationVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchant
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchant
     */
    disputeSupportEmail?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchant
     */
    excludedCurrencies?: Array<ApiMerchantExcludedCurrenciesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchant
     */
    excludedPayoutCurrencies?: Array<ApiMerchantExcludedPayoutCurrenciesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchant
     */
    fax?: string;
    /**
     * 
     * @type {ApiMerchantFees}
     * @memberof ApiMerchant
     */
    fees?: ApiMerchantFees;
    /**
     * The unique identifier for the Merchant. Primary key.
     * @type {string}
     * @memberof ApiMerchant
     */
    id?: string;
    /**
     * Merchant logo that is displayed on Merchant Payment preference screens in the App
     * @type {string}
     * @memberof ApiMerchant
     */
    logoUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchant
     */
    mcc?: Array<ApiMerchantMccEnum>;
    /**
     * The Krusty Krab
     * @type {string}
     * @memberof ApiMerchant
     */
    name?: string;
    /**
     * The Partner that this merchant is associated with
     * @type {string}
     * @memberof ApiMerchant
     */
    partnerId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchant
     */
    paymentAssetClasses?: Array<ApiMerchantPaymentAssetClassesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchant
     */
    payoutAssetClasses?: Array<ApiMerchantPayoutAssetClassesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchant
     */
    phone?: string;
    /**
     * Url that should be open in the bakkt App to return to merchant App after the account linking process finishes
     * @type {string}
     * @memberof ApiMerchant
     */
    redirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchant
     */
    routing?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchant
     */
    serviceTypes?: Array<ApiMerchantServiceTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchant
     */
    settlementCurrencies?: Array<ApiMerchantSettlementCurrenciesEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchant
     */
    settlementType?: ApiMerchantSettlementTypeEnum;
    /**
     * krusty_krab
     * @type {string}
     * @memberof ApiMerchant
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchant
     */
    subjectRef?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantCallbackNotificationVersionEnum {
    STARBUCKS = 'STARBUCKS',
    V1 = 'V1'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantExcludedCurrenciesEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantExcludedPayoutCurrenciesEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantMccEnum {
    CARWASHES = 'CAR_WASHES',
    FASTFOODRESTAURANTS = 'FAST_FOOD_RESTAURANTS',
    INTRACOMPANYPURCHASES = 'INTRA_COMPANY_PURCHASES',
    LAUNDRYFAMILYANDCOMMERCIAL = 'LAUNDRY_FAMILY_AND_COMMERCIAL',
    MISCELLANEOUSANDSPECIALTYRETAILSTORES = 'MISCELLANEOUS_AND_SPECIALTY_RETAIL_STORES',
    MISCFOODSTORESCONVENIENCESTORESANDSPECIALTYMARKETS = 'MISC_FOOD_STORES_CONVENIENCE_STORES_AND_SPECIALTY_MARKETS',
    TAXICABSANDLIMOUSINES = 'TAXICABS_AND_LIMOUSINES',
    VIDEOGAMEARCADESESTABLISHMENTS = 'VIDEO_GAME_ARCADES_ESTABLISHMENTS'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPaymentAssetClassesEnum {
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPayoutAssetClassesEnum {
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantServiceTypesEnum {
    PAYMENT = 'PAYMENT',
    PAYOUT = 'PAYOUT'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantSettlementCurrenciesEnum {
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantSettlementTypeEnum {
    GROSS = 'GROSS',
    NET = 'NET'
}

/**
 * 
 * @export
 * @interface ApiMerchantDispute
 */
export interface ApiMerchantDispute {
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantDispute
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    bakktTransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    comments?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    consumerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    consumerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    currency?: ApiMerchantDisputeCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    decisionBy?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    decisionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    disputeAction?: ApiMerchantDisputeDisputeActionEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    disputeCreateDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    disputeId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    disputeStatus?: ApiMerchantDisputeDisputeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    disputeType?: ApiMerchantDisputeDisputeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    disputeUpdateDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantDispute
     */
    fee?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    merchantConsumerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    merchantTransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    reasonCode?: ApiMerchantDisputeReasonCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    reasonCodeDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    replyByDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    resolutionType?: ApiMerchantDisputeResolutionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    settlementDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    status?: ApiMerchantDisputeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    ticketCategory?: ApiMerchantDisputeTicketCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    ticketCreateDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    ticketRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    ticketStatus?: ApiMerchantDisputeTicketStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    ticketUpdateDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantDispute
     */
    transactionDate?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantDisputeCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantDisputeDisputeActionEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELED = 'CANCELED',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantDisputeDisputeStatusEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED',
    ESCALATED = 'ESCALATED',
    MERCHANTREJECTED = 'MERCHANT_REJECTED',
    NEW = 'NEW',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantDisputeDisputeTypeEnum {
    CHARGEBACK = 'CHARGEBACK',
    RETRIEVAL = 'RETRIEVAL',
    REVERSAL = 'REVERSAL'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantDisputeReasonCodeEnum {
    DAC01 = 'DAC01',
    DAC02 = 'DAC02',
    DAC03 = 'DAC03',
    DAC04 = 'DAC04',
    DAC05 = 'DAC05',
    DAC06 = 'DAC06',
    DAC07 = 'DAC07',
    DAC08 = 'DAC08',
    DAC09 = 'DAC09',
    DAC10 = 'DAC10'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantDisputeResolutionTypeEnum {
    CHARGEBACK = 'CHARGEBACK',
    CHARGEBACKBAKKT = 'CHARGEBACK_BAKKT',
    CREDIT = 'CREDIT',
    REFUND = 'REFUND'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantDisputeStatusEnum {
    CLOSED = 'CLOSED',
    NEW = 'NEW',
    OPEN = 'OPEN',
    PENDING = 'PENDING',
    SOLVED = 'SOLVED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantDisputeTicketCategoryEnum {
    BILLING = 'BILLING',
    CHARGEBACKS = 'CHARGEBACKS',
    OTHERS = 'OTHERS',
    SETTLEMENT = 'SETTLEMENT',
    TRANSACTIONS = 'TRANSACTIONS'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantDisputeTicketStatusEnum {
    CLOSED = 'CLOSED',
    NEW = 'NEW',
    OPEN = 'OPEN',
    PENDING = 'PENDING',
    SOLVED = 'SOLVED'
}

/**
 * 
 * @export
 * @interface ApiMerchantFees
 */
export interface ApiMerchantFees {
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantFees
     */
    cancellation: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantFees
     */
    chargeback: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantFees
     */
    payment: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantFees
     */
    payout: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantFees
     */
    payoutReversal: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantFees
     */
    reversal: number;
}
/**
 * 
 * @export
 * @interface ApiMerchantPortalRole
 */
export interface ApiMerchantPortalRole {
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalRole
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalRole
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiMerchantPortalRole
     */
    isSuperRole?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalRole
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchantPortalRole
     */
    permissions?: Array<ApiMerchantPortalRolePermissionsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalRolePermissionsEnum {
    ADMINCREATE = 'ADMIN_CREATE',
    ADMINUPDATE = 'ADMIN_UPDATE',
    DISPUTEACCEPT = 'DISPUTE_ACCEPT',
    DISPUTEADDCOMMENT = 'DISPUTE_ADD_COMMENT',
    DISPUTEDOWNLOAD = 'DISPUTE_DOWNLOAD',
    DISPUTEDOWNLOADATTACHMENT = 'DISPUTE_DOWNLOAD_ATTACHMENT',
    DISPUTEFILEUPLOAD = 'DISPUTE_FILE_UPLOAD',
    DISPUTEREJECT = 'DISPUTE_REJECT',
    DISPUTEVIEW = 'DISPUTE_VIEW',
    DISPUTEVIEWCOMMENT = 'DISPUTE_VIEW_COMMENT',
    PROFILEUPDATE = 'PROFILE_UPDATE',
    PROFILEVIEW = 'PROFILE_VIEW',
    SETTLEMENTREPORTVIEW = 'SETTLEMENT_REPORT_VIEW',
    TRANSACTIONCANCEL = 'TRANSACTION_CANCEL',
    TRANSACTIONDOWNLOAD = 'TRANSACTION_DOWNLOAD',
    TRANSACTIONREVERSE = 'TRANSACTION_REVERSE',
    TRANSACTIONVIEW = 'TRANSACTION_VIEW',
    USERSCREATE = 'USERS_CREATE',
    USERSUPDATE = 'USERS_UPDATE',
    USERSUPDATESYNC = 'USERS_UPDATE_SYNC',
    USERSVIEW = 'USERS_VIEW',
    USERSVIEWDETAIL = 'USERS_VIEW_DETAIL'
}

/**
 * 
 * @export
 * @interface ApiMerchantPortalTransaction
 */
export interface ApiMerchantPortalTransaction {
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantPortalTransaction
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    bakktTransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    consumerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    consumerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    consumerLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    currency?: ApiMerchantPortalTransactionCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    disputeAction?: ApiMerchantPortalTransactionDisputeActionEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    disputeId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    disputeReasonCode?: ApiMerchantPortalTransactionDisputeReasonCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    disputeStatus?: ApiMerchantPortalTransactionDisputeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    disputeType?: ApiMerchantPortalTransactionDisputeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantPortalTransaction
     */
    fees?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    merchantCustomerRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    merchantDisputeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    merchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    merchantTransactionRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    replyByDate?: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof ApiMerchantPortalTransaction
     */
    reversalAgent?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    ticketRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    transactionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    transactionNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    transactionStatus?: ApiMerchantPortalTransactionTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransaction
     */
    transactionType?: ApiMerchantPortalTransactionTransactionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDisputeActionEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELED = 'CANCELED',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDisputeReasonCodeEnum {
    DAC01 = 'DAC01',
    DAC02 = 'DAC02',
    DAC03 = 'DAC03',
    DAC04 = 'DAC04',
    DAC05 = 'DAC05',
    DAC06 = 'DAC06',
    DAC07 = 'DAC07',
    DAC08 = 'DAC08',
    DAC09 = 'DAC09',
    DAC10 = 'DAC10'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDisputeStatusEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED',
    ESCALATED = 'ESCALATED',
    MERCHANTREJECTED = 'MERCHANT_REJECTED',
    NEW = 'NEW',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDisputeTypeEnum {
    CHARGEBACK = 'CHARGEBACK',
    RETRIEVAL = 'RETRIEVAL',
    REVERSAL = 'REVERSAL'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionTransactionStatusEnum {
    AUTHORIZED = 'AUTHORIZED',
    CANCELED = 'CANCELED',
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED',
    DISPUTED = 'DISPUTED',
    DISPUTECLOSED = 'DISPUTE_CLOSED',
    DISPUTEOPEN = 'DISPUTE_OPEN',
    PARTIALCAPTURED = 'PARTIAL_CAPTURED',
    PARTIALREVERSED = 'PARTIAL_REVERSED',
    PENDINGSETTLED = 'PENDING_SETTLED',
    REVERSED = 'REVERSED',
    SETTLED = 'SETTLED',
    VOIDED = 'VOIDED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionTransactionTypeEnum {
    CHARGEBACK = 'CHARGEBACK',
    PAYMENT = 'PAYMENT',
    REFUND = 'REFUND',
    VOID = 'VOID'
}

/**
 * 
 * @export
 * @interface ApiMerchantPortalTransactionActivity
 */
export interface ApiMerchantPortalTransactionActivity {
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionActivity
     */
    activityType?: ApiMerchantPortalTransactionActivityActivityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionActivity
     */
    agent?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchantPortalTransactionActivity
     */
    attachments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionActivity
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionActivity
     */
    notes?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionActivityActivityTypeEnum {
    ACCEPTED = 'ACCEPTED',
    AUTHORIZED = 'AUTHORIZED',
    AUTHDECLINED = 'AUTH_DECLINED',
    CANCELED = 'CANCELED',
    CANCELLED = 'CANCELLED',
    CAPTURED = 'CAPTURED',
    CHARGEBACK = 'CHARGEBACK',
    COMPLETE = 'COMPLETE',
    CONVERTCANCEL = 'CONVERT_CANCEL',
    CREDITED = 'CREDITED',
    DISPUTED = 'DISPUTED',
    DISPUTECANCELED = 'DISPUTE_CANCELED',
    ESCALATED = 'ESCALATED',
    EXECUTED = 'EXECUTED',
    EXPIRED = 'EXPIRED',
    FORCECHARGEBACK = 'FORCE_CHARGEBACK',
    INITIATED = 'INITIATED',
    MERCHANTACCEPTED = 'MERCHANT_ACCEPTED',
    MERCHANTESCALATED = 'MERCHANT_ESCALATED',
    MERCHANTREJECTED = 'MERCHANT_REJECTED',
    MERCHANTREESCALATED = 'MERCHANT_RE_ESCALATED',
    PENDING = 'PENDING',
    PROVISIONALCREDIT = 'PROVISIONAL_CREDIT',
    REAUTHORIZED = 'REAUTHORIZED',
    REFUNDED = 'REFUNDED',
    REGULATIONE = 'REGULATION_E',
    REJECTED = 'REJECTED',
    REPLACED = 'REPLACED',
    REVERSED = 'REVERSED',
    SETTLED = 'SETTLED'
}

/**
 * 
 * @export
 * @interface ApiMerchantPortalTransactionDetail
 */
export interface ApiMerchantPortalTransactionDetail {
    /**
     * 
     * @type {Array<ApiMerchantPortalTransactionActivity>}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    activities?: Array<ApiMerchantPortalTransactionActivity>;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    bakktTransactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    capturedTotal?: number;
    /**
     * 
     * @type {Array<ApiMerchantPortalTransactionSummary>}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    childTransactionDetails?: Array<ApiMerchantPortalTransactionSummary>;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    consumerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    consumerFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    consumerLastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    currency?: ApiMerchantPortalTransactionDetailCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    disputeAction?: ApiMerchantPortalTransactionDetailDisputeActionEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    disputeId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    disputeReasonCode?: ApiMerchantPortalTransactionDetailDisputeReasonCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    disputeStatus?: ApiMerchantPortalTransactionDetailDisputeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    disputeType?: ApiMerchantPortalTransactionDetailDisputeTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    fees?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    merchantCustomerRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    merchantDisputeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    merchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    merchantTransactionRef?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    refundedTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    replyByDate?: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    reversalAgent?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    ticketRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    transactionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    transactionNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    transactionStatus?: ApiMerchantPortalTransactionDetailTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionDetail
     */
    transactionType?: ApiMerchantPortalTransactionDetailTransactionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDetailCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDetailDisputeActionEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELED = 'CANCELED',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDetailDisputeReasonCodeEnum {
    DAC01 = 'DAC01',
    DAC02 = 'DAC02',
    DAC03 = 'DAC03',
    DAC04 = 'DAC04',
    DAC05 = 'DAC05',
    DAC06 = 'DAC06',
    DAC07 = 'DAC07',
    DAC08 = 'DAC08',
    DAC09 = 'DAC09',
    DAC10 = 'DAC10'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDetailDisputeStatusEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED',
    ESCALATED = 'ESCALATED',
    MERCHANTREJECTED = 'MERCHANT_REJECTED',
    NEW = 'NEW',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDetailDisputeTypeEnum {
    CHARGEBACK = 'CHARGEBACK',
    RETRIEVAL = 'RETRIEVAL',
    REVERSAL = 'REVERSAL'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDetailTransactionStatusEnum {
    AUTHORIZED = 'AUTHORIZED',
    CANCELED = 'CANCELED',
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED',
    DISPUTED = 'DISPUTED',
    DISPUTECLOSED = 'DISPUTE_CLOSED',
    DISPUTEOPEN = 'DISPUTE_OPEN',
    PARTIALCAPTURED = 'PARTIAL_CAPTURED',
    PARTIALREVERSED = 'PARTIAL_REVERSED',
    PENDINGSETTLED = 'PENDING_SETTLED',
    REVERSED = 'REVERSED',
    SETTLED = 'SETTLED',
    VOIDED = 'VOIDED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionDetailTransactionTypeEnum {
    CHARGEBACK = 'CHARGEBACK',
    PAYMENT = 'PAYMENT',
    REFUND = 'REFUND',
    VOID = 'VOID'
}

/**
 * 
 * @export
 * @interface ApiMerchantPortalTransactionSummary
 */
export interface ApiMerchantPortalTransactionSummary {
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionSummary
     */
    agent?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantPortalTransactionSummary
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantPortalTransactionSummary
     */
    fees?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionSummary
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionSummary
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionSummary
     */
    transactionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionSummary
     */
    transactionStatus?: ApiMerchantPortalTransactionSummaryTransactionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalTransactionSummary
     */
    transactionType?: ApiMerchantPortalTransactionSummaryTransactionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionSummaryTransactionStatusEnum {
    AUTHORIZED = 'AUTHORIZED',
    CANCELED = 'CANCELED',
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED',
    DISPUTED = 'DISPUTED',
    DISPUTECLOSED = 'DISPUTE_CLOSED',
    DISPUTEOPEN = 'DISPUTE_OPEN',
    PARTIALCAPTURED = 'PARTIAL_CAPTURED',
    PARTIALREVERSED = 'PARTIAL_REVERSED',
    PENDINGSETTLED = 'PENDING_SETTLED',
    REVERSED = 'REVERSED',
    SETTLED = 'SETTLED',
    VOIDED = 'VOIDED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalTransactionSummaryTransactionTypeEnum {
    CHARGEBACK = 'CHARGEBACK',
    PAYMENT = 'PAYMENT',
    REFUND = 'REFUND',
    VOID = 'VOID'
}

/**
 * 
 * @export
 * @interface ApiMerchantPortalUser
 */
export interface ApiMerchantPortalUser {
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalUser
     */
    createdTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalUser
     */
    fusionAuthRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalUser
     */
    lastLoginTime?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalUser
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchantPortalUser
     */
    roles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantPortalUser
     */
    userStatus?: ApiMerchantPortalUserUserStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantPortalUser
     */
    zendeskUserRef?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantPortalUserUserStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    INVITED = 'INVITED'
}

/**
 * 
 * @export
 * @interface ApiMerchantSettlementSummaryResponse
 */
export interface ApiMerchantSettlementSummaryResponse {
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    achAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    achExecutionDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    achReturnDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    chargebacks?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    deposits?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    fees?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    merchantId?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    netFinancialActivity?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    others?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    refunds?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    rejectedTransfers?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantSettlementSummaryResponse
     */
    settlementDate?: string;
}
/**
 * 
 * @export
 * @interface ApiMerchantUpdateRole
 */
export interface ApiMerchantUpdateRole {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchantUpdateRole
     */
    addPermissions?: Array<ApiMerchantUpdateRoleAddPermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantUpdateRole
     */
    merchantId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchantUpdateRole
     */
    removePermissions?: Array<ApiMerchantUpdateRoleRemovePermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantUpdateRole
     */
    roleId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantUpdateRoleAddPermissionsEnum {
    ADMINCREATE = 'ADMIN_CREATE',
    ADMINUPDATE = 'ADMIN_UPDATE',
    DISPUTEACCEPT = 'DISPUTE_ACCEPT',
    DISPUTEADDCOMMENT = 'DISPUTE_ADD_COMMENT',
    DISPUTEDOWNLOAD = 'DISPUTE_DOWNLOAD',
    DISPUTEDOWNLOADATTACHMENT = 'DISPUTE_DOWNLOAD_ATTACHMENT',
    DISPUTEFILEUPLOAD = 'DISPUTE_FILE_UPLOAD',
    DISPUTEREJECT = 'DISPUTE_REJECT',
    DISPUTEVIEW = 'DISPUTE_VIEW',
    DISPUTEVIEWCOMMENT = 'DISPUTE_VIEW_COMMENT',
    PROFILEUPDATE = 'PROFILE_UPDATE',
    PROFILEVIEW = 'PROFILE_VIEW',
    SETTLEMENTREPORTVIEW = 'SETTLEMENT_REPORT_VIEW',
    TRANSACTIONCANCEL = 'TRANSACTION_CANCEL',
    TRANSACTIONDOWNLOAD = 'TRANSACTION_DOWNLOAD',
    TRANSACTIONREVERSE = 'TRANSACTION_REVERSE',
    TRANSACTIONVIEW = 'TRANSACTION_VIEW',
    USERSCREATE = 'USERS_CREATE',
    USERSUPDATE = 'USERS_UPDATE',
    USERSUPDATESYNC = 'USERS_UPDATE_SYNC',
    USERSVIEW = 'USERS_VIEW',
    USERSVIEWDETAIL = 'USERS_VIEW_DETAIL'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiMerchantUpdateRoleRemovePermissionsEnum {
    ADMINCREATE = 'ADMIN_CREATE',
    ADMINUPDATE = 'ADMIN_UPDATE',
    DISPUTEACCEPT = 'DISPUTE_ACCEPT',
    DISPUTEADDCOMMENT = 'DISPUTE_ADD_COMMENT',
    DISPUTEDOWNLOAD = 'DISPUTE_DOWNLOAD',
    DISPUTEDOWNLOADATTACHMENT = 'DISPUTE_DOWNLOAD_ATTACHMENT',
    DISPUTEFILEUPLOAD = 'DISPUTE_FILE_UPLOAD',
    DISPUTEREJECT = 'DISPUTE_REJECT',
    DISPUTEVIEW = 'DISPUTE_VIEW',
    DISPUTEVIEWCOMMENT = 'DISPUTE_VIEW_COMMENT',
    PROFILEUPDATE = 'PROFILE_UPDATE',
    PROFILEVIEW = 'PROFILE_VIEW',
    SETTLEMENTREPORTVIEW = 'SETTLEMENT_REPORT_VIEW',
    TRANSACTIONCANCEL = 'TRANSACTION_CANCEL',
    TRANSACTIONDOWNLOAD = 'TRANSACTION_DOWNLOAD',
    TRANSACTIONREVERSE = 'TRANSACTION_REVERSE',
    TRANSACTIONVIEW = 'TRANSACTION_VIEW',
    USERSCREATE = 'USERS_CREATE',
    USERSUPDATE = 'USERS_UPDATE',
    USERSUPDATESYNC = 'USERS_UPDATE_SYNC',
    USERSVIEW = 'USERS_VIEW',
    USERSVIEWDETAIL = 'USERS_VIEW_DETAIL'
}

/**
 * 
 * @export
 * @interface ApiMerchantUserInfo
 */
export interface ApiMerchantUserInfo {
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantUserInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMerchantUserInfo
     */
    partyId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchantUserInfo
     */
    permissions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiMerchantUserInfo
     */
    roles?: Array<string>;
}
/**
 * The configuration of this partner
 * @export
 * @interface ApiPartnerConfiguration
 */
export interface ApiPartnerConfiguration {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerConfiguration
     */
    cryptoCurrencySource: ApiPartnerConfigurationCryptoCurrencySourceEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPartnerConfiguration
     */
    kycCheckRequired: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerConfiguration
     */
    partyLevel: ApiPartnerConfigurationPartyLevelEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerConfigurationCryptoCurrencySourceEnum {
    PARTNER = 'PARTNER',
    PARTY = 'PARTY'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerConfigurationPartyLevelEnum {
    LEVEL1 = 'LEVEL_1',
    LEVEL1LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL1SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL2 = 'LEVEL_2',
    LEVEL2LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL2SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL3 = 'LEVEL_3',
    LEVEL3LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL3SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL4 = 'LEVEL_4',
    LEVEL4LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL4SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL5 = 'LEVEL_5',
    LEVEL5LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL5SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL99 = 'LEVEL_99',
    LEVEL99LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    LEVEL99SUSPENDED = 'LEVEL_99_SUSPENDED',
    UNKNOWN = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface ApiPartnerTransaction
 */
export interface ApiPartnerTransaction {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    operationType?: ApiPartnerTransactionOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    partnerAccountId?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerTransaction
     */
    partnerId?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    partnerTransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    partnerTransactionRef?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransaction
     */
    sourceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    sourceCurrency?: ApiPartnerTransactionSourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    status?: ApiPartnerTransactionStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransaction
     */
    targetAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    targetCurrency?: ApiPartnerTransactionTargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    transactionType?: ApiPartnerTransactionTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransaction
     */
    updated?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionOperationTypeEnum {
    BUY = 'BUY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD',
    SELL = 'SELL'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSourceCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionStatusEnum {
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionTargetCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionTransactionTypeEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD'
}

/**
 * 
 * @export
 * @interface ApiPartnerTransactionSummary
 */
export interface ApiPartnerTransactionSummary {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    declineReason?: ApiPartnerTransactionSummaryDeclineReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    internalNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    operationType?: ApiPartnerTransactionSummaryOperationTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerTransactionSummary
     */
    partnerId?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    partnerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    partnerPartyLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    partnerPartyRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    partnerTransactionRef?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiPartnerTransactionSummary
     */
    partyId?: object;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionSummary
     */
    sourceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    sourceCurrency?: ApiPartnerTransactionSummarySourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    status?: ApiPartnerTransactionSummaryStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiPartnerTransactionSummary
     */
    targetAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    targetAssetType?: ApiPartnerTransactionSummaryTargetAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    targetCurrency?: ApiPartnerTransactionSummaryTargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerTransactionSummary
     */
    transactionType?: ApiPartnerTransactionSummaryTransactionTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryDeclineReasonEnum {
    ACCOUNTNOTFOUND = 'ACCOUNT_NOT_FOUND',
    DAILYLIMITEXCEEDED = 'DAILY_LIMIT_EXCEEDED',
    MONTHLYLIMITEXCEEDED = 'MONTHLY_LIMIT_EXCEEDED',
    NOTSUFFICIENTFUNDS = 'NOT_SUFFICIENT_FUNDS',
    SUSPECTEDFRAUD = 'SUSPECTED_FRAUD',
    TRANSACTIONFAILED = 'TRANSACTION_FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryOperationTypeEnum {
    BUY = 'BUY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD',
    SELL = 'SELL'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummarySourceCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryStatusEnum {
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryTargetAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryTargetCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiPartnerTransactionSummaryTransactionTypeEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD'
}

/**
 * 
 * @export
 * @interface ApiPartnerUserInfo
 */
export interface ApiPartnerUserInfo {
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerUserInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartnerUserInfo
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartnerUserInfo
     */
    partnerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartnerUserInfo
     */
    permissions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartnerUserInfo
     */
    roles?: Array<string>;
}
/**
 * 
 * @export
 * @interface ApiParty
 */
export interface ApiParty {
    /**
     * bakkt ID
     * @type {string}
     * @memberof ApiParty
     */
    alias?: string;
    /**
     * Datetime the Party was created
     * @type {string}
     * @memberof ApiParty
     */
    created?: string;
    /**
     * Email address, verified by OTP
     * @type {string}
     * @memberof ApiParty
     */
    email?: string;
    /**
     * First name
     * @type {string}
     * @memberof ApiParty
     */
    firstName?: string;
    /**
     * Unique identifier for the Party. Primary key.
     * @type {object}
     * @memberof ApiParty
     */
    id?: object;
    /**
     * Last name
     * @type {string}
     * @memberof ApiParty
     */
    lastName?: string;
    /**
     * Phone number, verified by OTP
     * @type {string}
     * @memberof ApiParty
     */
    phone?: string;
    /**
     * Datetime the Party last updated
     * @type {string}
     * @memberof ApiParty
     */
    updated?: string;
}
/**
 * Party Base Search Result
 * @export
 * @interface ApiPartyBaseSearchResponse
 */
export interface ApiPartyBaseSearchResponse {
    /**
     * Bakkt ID. Customizable alias used to identify a Party.
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    alias?: string;
    /**
     * Datetime the Party was created
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    created?: string;
    /**
     * Email address, verified by OTP
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    email?: string;
    /**
     * First name
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    firstName?: string;
    /**
     * Unique identifier for the Party. Primary key.
     * @type {object}
     * @memberof ApiPartyBaseSearchResponse
     */
    id?: object;
    /**
     * Last name
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    lastName?: string;
    /**
     * id of partner
     * @type {object}
     * @memberof ApiPartyBaseSearchResponse
     */
    partnerId?: object;
    /**
     * name of partner
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    partnerName?: string;
    /**
     * Unique identifier for the Partner link Id.
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    partnerPartyLinkId?: string;
    /**
     * Partner Party reference Id from partner
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    partnerPartyRef?: string;
    /**
     * Phone number, verified by OTP
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    phone?: string;
    /**
     * Partner Party Link Status
     * @type {string}
     * @memberof ApiPartyBaseSearchResponse
     */
    pplStatus?: ApiPartyBaseSearchResponsePplStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartyBaseSearchResponsePplStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    OTPREQUIRED = 'OTP_REQUIRED',
    PENDING = 'PENDING',
    UNDERREVIEW = 'UNDER_REVIEW'
}

/**
 * 
 * @export
 * @interface ApiPartyDashboardSearch
 */
export interface ApiPartyDashboardSearch {
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartyDashboardSearch
     */
    emails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDashboardSearch
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartyDashboardSearch
     */
    partnerIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDashboardSearch
     */
    partnerPartyId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPartyDashboardSearch
     */
    phones?: Array<string>;
}
/**
 * 
 * @export
 * @interface ApiPartyDetails
 */
export interface ApiPartyDetails {
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    alias?: string;
    /**
     * Email address, verified by OTP
     * @type {string}
     * @memberof ApiPartyDetails
     */
    email?: string;
    /**
     * First name
     * @type {string}
     * @memberof ApiPartyDetails
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    fullName?: string;
    /**
     * Unique identifier for the Party. Primary key.
     * @type {object}
     * @memberof ApiPartyDetails
     */
    id?: object;
    /**
     * Last name
     * @type {string}
     * @memberof ApiPartyDetails
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetails
     */
    level?: ApiPartyDetailsLevelEnum;
    /**
     * Unique identifier Partner Party link Id
     * @type {string}
     * @memberof ApiPartyDetails
     */
    partnerPartyLinkId?: string;
    /**
     * Unique identifier for the Partner Party Ref.
     * @type {string}
     * @memberof ApiPartyDetails
     */
    partnerPartyRef?: string;
    /**
     * Phone number, verified by OTP
     * @type {string}
     * @memberof ApiPartyDetails
     */
    phone?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPartyDetailsLevelEnum {
    LEVEL1 = 'LEVEL_1',
    LEVEL1LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL1SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL2 = 'LEVEL_2',
    LEVEL2LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL2SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL3 = 'LEVEL_3',
    LEVEL3LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL3SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL4 = 'LEVEL_4',
    LEVEL4LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL4SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL5 = 'LEVEL_5',
    LEVEL5LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL5SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL99 = 'LEVEL_99',
    LEVEL99LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    LEVEL99SUSPENDED = 'LEVEL_99_SUSPENDED',
    UNKNOWN = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface ApiPartyDetailsRequest
 */
export interface ApiPartyDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetailsRequest
     */
    partnerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetailsRequest
     */
    partnerPartyLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPartyDetailsRequest
     */
    partnerPartyRef?: string;
}
/**
 * 
 * @export
 * @interface ApiPartyPortfolio
 */
export interface ApiPartyPortfolio {
    /**
     * 
     * @type {Array<PortfolioByAsset>}
     * @memberof ApiPartyPortfolio
     */
    portfolioItems?: Array<PortfolioByAsset>;
    /**
     * 
     * @type {ServiceAmount}
     * @memberof ApiPartyPortfolio
     */
    totalPortfolioAmount?: ServiceAmount;
}
/**
 * 
 * @export
 * @interface ApiPortalRole
 */
export interface ApiPortalRole {
    /**
     * 
     * @type {string}
     * @memberof ApiPortalRole
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPortalRole
     */
    fusionAuthRoleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPortalRole
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPortalRole
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPortalRole
     */
    permissions?: Array<ApiPortalRolePermissionsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPortalRole
     */
    superRole?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPortalRole
     */
    systemRole?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiPortalRolePermissionsEnum {
    ADMINCREATE = 'ADMIN_CREATE',
    ADMINUPDATE = 'ADMIN_UPDATE',
    CONSUMERVIEW = 'CONSUMER_VIEW',
    CONSUMERVIEWDETAIL = 'CONSUMER_VIEW_DETAIL',
    PROFILEUPDATE = 'PROFILE_UPDATE',
    PROFILEVIEW = 'PROFILE_VIEW',
    ROLECREATEUPDATE = 'ROLE_CREATE_UPDATE',
    SETTLEMENTREPORTVIEW = 'SETTLEMENT_REPORT_VIEW',
    TRANSACTIONCANCEL = 'TRANSACTION_CANCEL',
    TRANSACTIONREVERSE = 'TRANSACTION_REVERSE',
    TRANSACTIONVIEW = 'TRANSACTION_VIEW',
    TRANSACTIONVIEWDETAIL = 'TRANSACTION_VIEW_DETAIL',
    USERSCREATE = 'USERS_CREATE',
    USERSUPDATE = 'USERS_UPDATE',
    USERSUPDATESYNC = 'USERS_UPDATE_SYNC',
    USERSVIEW = 'USERS_VIEW',
    USERSVIEWDETAIL = 'USERS_VIEW_DETAIL'
}

/**
 * 
 * @export
 * @interface ApiResponseApiAttachmentRequest
 */
export interface ApiResponseApiAttachmentRequest {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiAttachmentRequest
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiAttachmentRequest
     */
    message?: string;
    /**
     * 
     * @type {ApiAttachmentRequestReq}
     * @memberof ApiResponseApiAttachmentRequest
     */
    payload?: ApiAttachmentRequestReq;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiAttachmentRequest
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiDashBoardMetricsDetails
 */
export interface ApiResponseApiDashBoardMetricsDetails {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiDashBoardMetricsDetails
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiDashBoardMetricsDetails
     */
    message?: string;
    /**
     * 
     * @type {ApiDashBoardMetricsDetails}
     * @memberof ApiResponseApiDashBoardMetricsDetails
     */
    payload?: ApiDashBoardMetricsDetails;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiDashBoardMetricsDetails
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiMerchant
 */
export interface ApiResponseApiMerchant {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiMerchant
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiMerchant
     */
    message?: string;
    /**
     * 
     * @type {ApiMerchant}
     * @memberof ApiResponseApiMerchant
     */
    payload?: ApiMerchant;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiMerchant
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiMerchantPortalTransactionDetail
 */
export interface ApiResponseApiMerchantPortalTransactionDetail {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiMerchantPortalTransactionDetail
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiMerchantPortalTransactionDetail
     */
    message?: string;
    /**
     * 
     * @type {ApiMerchantPortalTransactionDetail}
     * @memberof ApiResponseApiMerchantPortalTransactionDetail
     */
    payload?: ApiMerchantPortalTransactionDetail;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiMerchantPortalTransactionDetail
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiMerchantPortalUser
 */
export interface ApiResponseApiMerchantPortalUser {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiMerchantPortalUser
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiMerchantPortalUser
     */
    message?: string;
    /**
     * 
     * @type {ApiMerchantPortalUser}
     * @memberof ApiResponseApiMerchantPortalUser
     */
    payload?: ApiMerchantPortalUser;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiMerchantPortalUser
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiParty
 */
export interface ApiResponseApiParty {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiParty
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiParty
     */
    message?: string;
    /**
     * 
     * @type {ApiParty}
     * @memberof ApiResponseApiParty
     */
    payload?: ApiParty;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiParty
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiPartyDetails
 */
export interface ApiResponseApiPartyDetails {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiPartyDetails
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiPartyDetails
     */
    message?: string;
    /**
     * 
     * @type {ApiPartyDetails}
     * @memberof ApiResponseApiPartyDetails
     */
    payload?: ApiPartyDetails;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiPartyDetails
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiPartyPortfolio
 */
export interface ApiResponseApiPartyPortfolio {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiPartyPortfolio
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiPartyPortfolio
     */
    message?: string;
    /**
     * 
     * @type {ApiPartyPortfolio}
     * @memberof ApiResponseApiPartyPortfolio
     */
    payload?: ApiPartyPortfolio;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiPartyPortfolio
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseApiTransactionDetails
 */
export interface ApiResponseApiTransactionDetails {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseApiTransactionDetails
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseApiTransactionDetails
     */
    message?: string;
    /**
     * 
     * @type {ApiTransactionDetails}
     * @memberof ApiResponseApiTransactionDetails
     */
    payload?: ApiTransactionDetails;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseApiTransactionDetails
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseListApiMerchantPortalRole
 */
export interface ApiResponseListApiMerchantPortalRole {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListApiMerchantPortalRole
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListApiMerchantPortalRole
     */
    message?: string;
    /**
     * 
     * @type {Array<ApiMerchantPortalRole>}
     * @memberof ApiResponseListApiMerchantPortalRole
     */
    payload?: Array<ApiMerchantPortalRole>;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListApiMerchantPortalRole
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseListApiPortalRole
 */
export interface ApiResponseListApiPortalRole {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListApiPortalRole
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListApiPortalRole
     */
    message?: string;
    /**
     * 
     * @type {Array<ApiPortalRole>}
     * @memberof ApiResponseListApiPortalRole
     */
    payload?: Array<ApiPortalRole>;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListApiPortalRole
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseListPartner
 */
export interface ApiResponseListPartner {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseListPartner
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseListPartner
     */
    message?: string;
    /**
     * 
     * @type {Array<Partner>}
     * @memberof ApiResponseListPartner
     */
    payload?: Array<Partner>;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseListPartner
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiComment
 */
export interface ApiResponsePageApiComment {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiComment
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiComment
     */
    message?: string;
    /**
     * 
     * @type {PageApiComment}
     * @memberof ApiResponsePageApiComment
     */
    payload?: PageApiComment;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiComment
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiMerchantDispute
 */
export interface ApiResponsePageApiMerchantDispute {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiMerchantDispute
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiMerchantDispute
     */
    message?: string;
    /**
     * 
     * @type {PageApiMerchantDispute}
     * @memberof ApiResponsePageApiMerchantDispute
     */
    payload?: PageApiMerchantDispute;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiMerchantDispute
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiMerchantPortalTransaction
 */
export interface ApiResponsePageApiMerchantPortalTransaction {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiMerchantPortalTransaction
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiMerchantPortalTransaction
     */
    message?: string;
    /**
     * 
     * @type {PageApiMerchantPortalTransaction}
     * @memberof ApiResponsePageApiMerchantPortalTransaction
     */
    payload?: PageApiMerchantPortalTransaction;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiMerchantPortalTransaction
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiMerchantPortalUser
 */
export interface ApiResponsePageApiMerchantPortalUser {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiMerchantPortalUser
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiMerchantPortalUser
     */
    message?: string;
    /**
     * 
     * @type {PageApiMerchantPortalUser}
     * @memberof ApiResponsePageApiMerchantPortalUser
     */
    payload?: PageApiMerchantPortalUser;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiMerchantPortalUser
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiMerchantSettlementSummaryResponse
 */
export interface ApiResponsePageApiMerchantSettlementSummaryResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiMerchantSettlementSummaryResponse
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiMerchantSettlementSummaryResponse
     */
    message?: string;
    /**
     * 
     * @type {PageApiMerchantSettlementSummaryResponse}
     * @memberof ApiResponsePageApiMerchantSettlementSummaryResponse
     */
    payload?: PageApiMerchantSettlementSummaryResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiMerchantSettlementSummaryResponse
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiPartnerTransaction
 */
export interface ApiResponsePageApiPartnerTransaction {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiPartnerTransaction
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiPartnerTransaction
     */
    message?: string;
    /**
     * 
     * @type {PageApiPartnerTransaction}
     * @memberof ApiResponsePageApiPartnerTransaction
     */
    payload?: PageApiPartnerTransaction;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiPartnerTransaction
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiPartnerTransactionSummary
 */
export interface ApiResponsePageApiPartnerTransactionSummary {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiPartnerTransactionSummary
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiPartnerTransactionSummary
     */
    message?: string;
    /**
     * 
     * @type {PageApiPartnerTransactionSummary}
     * @memberof ApiResponsePageApiPartnerTransactionSummary
     */
    payload?: PageApiPartnerTransactionSummary;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiPartnerTransactionSummary
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiParty
 */
export interface ApiResponsePageApiParty {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiParty
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiParty
     */
    message?: string;
    /**
     * 
     * @type {PageApiParty}
     * @memberof ApiResponsePageApiParty
     */
    payload?: PageApiParty;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiParty
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePageApiPartyBaseSearchResponse
 */
export interface ApiResponsePageApiPartyBaseSearchResponse {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePageApiPartyBaseSearchResponse
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePageApiPartyBaseSearchResponse
     */
    message?: string;
    /**
     * 
     * @type {PageApiPartyBaseSearchResponse}
     * @memberof ApiResponsePageApiPartyBaseSearchResponse
     */
    payload?: PageApiPartyBaseSearchResponse;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePageApiPartyBaseSearchResponse
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponsePartner
 */
export interface ApiResponsePartner {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponsePartner
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsePartner
     */
    message?: string;
    /**
     * 
     * @type {Partner}
     * @memberof ApiResponsePartner
     */
    payload?: Partner;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponsePartner
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiResponseVoid
 */
export interface ApiResponseVoid {
    /**
     * 
     * @type {ApiError}
     * @memberof ApiResponseVoid
     */
    error?: ApiError;
    /**
     * 
     * @type {string}
     * @memberof ApiResponseVoid
     */
    message?: string;
    /**
     * true|false indicating if the request completed successfully
     * @type {boolean}
     * @memberof ApiResponseVoid
     */
    success?: boolean;
}
/**
 * 
 * @export
 * @interface ApiReversalRequest
 */
export interface ApiReversalRequest {
    /**
     * 
     * @type {number}
     * @memberof ApiReversalRequest
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiReversalRequest
     */
    currency?: ApiReversalRequestCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiReversalRequest
     */
    merchantNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiReversalRequest
     */
    merchantTransactionRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiReversalRequest
     */
    reversalReason?: ApiReversalRequestReversalReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiReversalRequest
     */
    reversalType?: ApiReversalRequestReversalTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiReversalRequestCurrencyEnum {
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiReversalRequestReversalReasonEnum {
    AMOUNTEXCEEDSAUTHORIZATIONAMOUNT = 'AMOUNT_EXCEEDS_AUTHORIZATION_AMOUNT',
    AUTHORIZATIONAPPROVALEXPIRED = 'AUTHORIZATION_APPROVAL_EXPIRED',
    CANCELLEDMERCHANDISE = 'CANCELLED_MERCHANDISE',
    CANCELLEDRECURRING = 'CANCELLED_RECURRING',
    DECLINEDAUTHORIZATION = 'DECLINED_AUTHORIZATION',
    DOESNOTRECOGNIZE = 'DOES_NOT_RECOGNIZE',
    DUPLICATECHARGE = 'DUPLICATE_CHARGE',
    GOODSNOTFULLYRECEIVED = 'GOODS_NOT_FULLY_RECEIVED',
    GOODFAITHINVESTIGATION = 'GOOD_FAITH_INVESTIGATION',
    NONRECEIPTOFTRANSACTIONVALUE = 'NONRECEIPT_OF_TRANSACTION_VALUE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiReversalRequestReversalTypeEnum {
    REFUND = 'REFUND',
    VOID = 'VOID'
}

/**
 * 
 * @export
 * @interface ApiReversalRequest1
 */
export interface ApiReversalRequest1 {
    /**
     * 
     * @type {number}
     * @memberof ApiReversalRequest1
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiReversalRequest1
     */
    currency?: ApiReversalRequest1CurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiReversalRequest1
     */
    merchantNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiReversalRequest1
     */
    merchantTransactionRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiReversalRequest1
     */
    reversalReason?: ApiReversalRequest1ReversalReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiReversalRequest1
     */
    reversalType?: ApiReversalRequest1ReversalTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiReversalRequest1CurrencyEnum {
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiReversalRequest1ReversalReasonEnum {
    AMOUNTEXCEEDSAUTHORIZATIONAMOUNT = 'AMOUNT_EXCEEDS_AUTHORIZATION_AMOUNT',
    AUTHORIZATIONAPPROVALEXPIRED = 'AUTHORIZATION_APPROVAL_EXPIRED',
    CANCELLEDMERCHANDISE = 'CANCELLED_MERCHANDISE',
    CANCELLEDRECURRING = 'CANCELLED_RECURRING',
    DECLINEDAUTHORIZATION = 'DECLINED_AUTHORIZATION',
    DOESNOTRECOGNIZE = 'DOES_NOT_RECOGNIZE',
    DUPLICATECHARGE = 'DUPLICATE_CHARGE',
    GOODSNOTFULLYRECEIVED = 'GOODS_NOT_FULLY_RECEIVED',
    GOODFAITHINVESTIGATION = 'GOOD_FAITH_INVESTIGATION',
    NONRECEIPTOFTRANSACTIONVALUE = 'NONRECEIPT_OF_TRANSACTION_VALUE'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiReversalRequest1ReversalTypeEnum {
    REFUND = 'REFUND',
    VOID = 'VOID'
}

/**
 * 
 * @export
 * @interface ApiTimeRange
 */
export interface ApiTimeRange {
    /**
     * 
     * @type {string}
     * @memberof ApiTimeRange
     */
    fromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTimeRange
     */
    toDate?: string;
}
/**
 * 
 * @export
 * @interface ApiTransactionDetails
 */
export interface ApiTransactionDetails {
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    cryptoCurrencySource?: ApiTransactionDetailsCryptoCurrencySourceEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    externalAccountRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiTransactionDetails
     */
    fee?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    formattedFundingAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    fundingSource?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    operationType?: ApiTransactionDetailsOperationTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof ApiTransactionDetails
     */
    partnerId?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    partnerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    partnerPartyLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    partnerPartyRef?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    partnerTransactionRef?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiTransactionDetails
     */
    partyId?: object;
    /**
     * 
     * @type {number}
     * @memberof ApiTransactionDetails
     */
    sourceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    sourceCurrency?: ApiTransactionDetailsSourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    status?: ApiTransactionDetailsStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiTransactionDetails
     */
    targetAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    targetCurrency?: ApiTransactionDetailsTargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiTransactionDetails
     */
    transactionType?: ApiTransactionDetailsTransactionTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiTransactionDetails
     */
    unitPrice?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsCryptoCurrencySourceEnum {
    PARTNER = 'PARTNER',
    PARTY = 'PARTY'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsOperationTypeEnum {
    BUY = 'BUY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD',
    SELL = 'SELL'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsSourceCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsStatusEnum {
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsTargetCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum ApiTransactionDetailsTransactionTypeEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD'
}

/**
 * 
 * @export
 * @interface AssetValueByCategory
 */
export interface AssetValueByCategory {
    /**
     * 
     * @type {string}
     * @memberof AssetValueByCategory
     */
    category?: string;
    /**
     * 
     * @type {ServiceAmount}
     * @memberof AssetValueByCategory
     */
    value?: ServiceAmount;
}
/**
 * Resources of Metrics
 * @export
 * @interface CollectionModelMetrics
 */
export interface CollectionModelMetrics {
    /**
     * 
     * @type {EmbeddedCollectionMetrics}
     * @memberof CollectionModelMetrics
     */
    _embedded: EmbeddedCollectionMetrics;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelMetrics
     */
    _links: { [key: string]: Link; };
}
/**
 * Resources of Partner
 * @export
 * @interface CollectionModelPartner
 */
export interface CollectionModelPartner {
    /**
     * 
     * @type {EmbeddedCollectionPartner}
     * @memberof CollectionModelPartner
     */
    _embedded: EmbeddedCollectionPartner;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelPartner
     */
    _links: { [key: string]: Link; };
}
/**
 * Resources of PartnerPartyDetailProjection
 * @export
 * @interface CollectionModelPartnerPartyDetailProjection
 */
export interface CollectionModelPartnerPartyDetailProjection {
    /**
     * 
     * @type {EmbeddedCollectionPartnerPartyDetailProjection}
     * @memberof CollectionModelPartnerPartyDetailProjection
     */
    _embedded: EmbeddedCollectionPartnerPartyDetailProjection;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelPartnerPartyDetailProjection
     */
    _links: { [key: string]: Link; };
}
/**
 * Resources of PartnerPartyLink
 * @export
 * @interface CollectionModelPartnerPartyLink
 */
export interface CollectionModelPartnerPartyLink {
    /**
     * 
     * @type {EmbeddedCollectionPartnerPartyLink}
     * @memberof CollectionModelPartnerPartyLink
     */
    _embedded: EmbeddedCollectionPartnerPartyLink;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelPartnerPartyLink
     */
    _links: { [key: string]: Link; };
}
/**
 * Resources of PartnerTransaction
 * @export
 * @interface CollectionModelPartnerTransaction
 */
export interface CollectionModelPartnerTransaction {
    /**
     * 
     * @type {EmbeddedCollectionPartnerTransaction}
     * @memberof CollectionModelPartnerTransaction
     */
    _embedded: EmbeddedCollectionPartnerTransaction;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelPartnerTransaction
     */
    _links: { [key: string]: Link; };
}
/**
 * Resources of Party
 * @export
 * @interface CollectionModelParty
 */
export interface CollectionModelParty {
    /**
     * 
     * @type {EmbeddedCollectionParty}
     * @memberof CollectionModelParty
     */
    _embedded: EmbeddedCollectionParty;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelParty
     */
    _links: { [key: string]: Link; };
}
/**
 * Resources of RolePermission
 * @export
 * @interface CollectionModelRolePermission
 */
export interface CollectionModelRolePermission {
    /**
     * 
     * @type {EmbeddedCollectionRolePermission}
     * @memberof CollectionModelRolePermission
     */
    _embedded: EmbeddedCollectionRolePermission;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelRolePermission
     */
    _links: { [key: string]: Link; };
}
/**
 * Resources of SMSHistory
 * @export
 * @interface CollectionModelSMSHistory
 */
export interface CollectionModelSMSHistory {
    /**
     * 
     * @type {EmbeddedCollectionSMSHistory}
     * @memberof CollectionModelSMSHistory
     */
    _embedded: EmbeddedCollectionSMSHistory;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelSMSHistory
     */
    _links: { [key: string]: Link; };
}
/**
 * Resources of UUID
 * @export
 * @interface CollectionModelUuid
 */
export interface CollectionModelUuid {
    /**
     * 
     * @type {EmbeddedCollectionUuid}
     * @memberof CollectionModelUuid
     */
    _embedded: EmbeddedCollectionUuid;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelUuid
     */
    _links: { [key: string]: Link; };
}
/**
 * 
 * @export
 * @interface CreateMerchantOwner
 */
export interface CreateMerchantOwner {
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantOwner
     */
    businessPartnerRef?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantOwner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantOwner
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantOwner
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantOwner
     */
    merchantId?: string;
}
/**
 * 
 * @export
 * @interface CreateMerchantUserRequest
 */
export interface CreateMerchantUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantUserRequest
     */
    fusionAuthRef?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMerchantUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMerchantUserRequest
     */
    roles?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreatePartnerOwnerRequest
 */
export interface CreatePartnerOwnerRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerOwnerRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerOwnerRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerOwnerRequest
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerOwnerRequest
     */
    partnerId?: string;
}
/**
 * 
 * @export
 * @interface CreateRoleRequest
 */
export interface CreateRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequest
     */
    partnerId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRoleRequest
     */
    permissions?: Array<CreateRoleRequestPermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequest
     */
    roleDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRoleRequest
     */
    roleName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateRoleRequestPermissionsEnum {
    ADMINCREATE = 'ADMIN_CREATE',
    ADMINUPDATE = 'ADMIN_UPDATE',
    CONSUMERVIEW = 'CONSUMER_VIEW',
    CONSUMERVIEWDETAIL = 'CONSUMER_VIEW_DETAIL',
    PROFILEUPDATE = 'PROFILE_UPDATE',
    PROFILEVIEW = 'PROFILE_VIEW',
    ROLECREATEUPDATE = 'ROLE_CREATE_UPDATE',
    SETTLEMENTREPORTVIEW = 'SETTLEMENT_REPORT_VIEW',
    TRANSACTIONCANCEL = 'TRANSACTION_CANCEL',
    TRANSACTIONREVERSE = 'TRANSACTION_REVERSE',
    TRANSACTIONVIEW = 'TRANSACTION_VIEW',
    TRANSACTIONVIEWDETAIL = 'TRANSACTION_VIEW_DETAIL',
    USERSCREATE = 'USERS_CREATE',
    USERSUPDATE = 'USERS_UPDATE',
    USERSUPDATESYNC = 'USERS_UPDATE_SYNC',
    USERSVIEW = 'USERS_VIEW',
    USERSVIEWDETAIL = 'USERS_VIEW_DETAIL'
}

/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequest
     */
    partnerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequest
     */
    roles?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateUserRequest1
 */
export interface CreateUserRequest1 {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest1
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest1
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest1
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest1
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequest1
     */
    partnerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserRequest1
     */
    roles?: Array<string>;
}
/**
 * Embedded collection of Metrics
 * @export
 * @interface EmbeddedCollectionMetrics
 */
export interface EmbeddedCollectionMetrics {
    /**
     * Resource collection
     * @type {Array<Metrics>}
     * @memberof EmbeddedCollectionMetrics
     */
    metricses: Array<Metrics>;
}
/**
 * Embedded collection of Partner
 * @export
 * @interface EmbeddedCollectionPartner
 */
export interface EmbeddedCollectionPartner {
    /**
     * Resource collection
     * @type {Array<Partner>}
     * @memberof EmbeddedCollectionPartner
     */
    partners: Array<Partner>;
}
/**
 * Embedded collection of PartnerPartyDetailProjection
 * @export
 * @interface EmbeddedCollectionPartnerPartyDetailProjection
 */
export interface EmbeddedCollectionPartnerPartyDetailProjection {
    /**
     * Resource collection
     * @type {Array<PartnerPartyDetailProjection>}
     * @memberof EmbeddedCollectionPartnerPartyDetailProjection
     */
    partnerPartyDetailProjections: Array<PartnerPartyDetailProjection>;
}
/**
 * Embedded collection of PartnerPartyLink
 * @export
 * @interface EmbeddedCollectionPartnerPartyLink
 */
export interface EmbeddedCollectionPartnerPartyLink {
    /**
     * Resource collection
     * @type {Array<PartnerPartyLink>}
     * @memberof EmbeddedCollectionPartnerPartyLink
     */
    partnerPartyLinks: Array<PartnerPartyLink>;
}
/**
 * Embedded collection of PartnerTransaction
 * @export
 * @interface EmbeddedCollectionPartnerTransaction
 */
export interface EmbeddedCollectionPartnerTransaction {
    /**
     * Resource collection
     * @type {Array<PartnerTransaction>}
     * @memberof EmbeddedCollectionPartnerTransaction
     */
    partnerTransactions: Array<PartnerTransaction>;
}
/**
 * Embedded collection of Party
 * @export
 * @interface EmbeddedCollectionParty
 */
export interface EmbeddedCollectionParty {
    /**
     * Resource collection
     * @type {Array<Party>}
     * @memberof EmbeddedCollectionParty
     */
    parties: Array<Party>;
}
/**
 * Embedded collection of RolePermission
 * @export
 * @interface EmbeddedCollectionRolePermission
 */
export interface EmbeddedCollectionRolePermission {
    /**
     * Resource collection
     * @type {Array<RolePermission>}
     * @memberof EmbeddedCollectionRolePermission
     */
    rolePermissions: Array<RolePermission>;
}
/**
 * Embedded collection of SMSHistory
 * @export
 * @interface EmbeddedCollectionSMSHistory
 */
export interface EmbeddedCollectionSMSHistory {
    /**
     * Resource collection
     * @type {Array<SMSHistory>}
     * @memberof EmbeddedCollectionSMSHistory
     */
    sMSHistories: Array<SMSHistory>;
}
/**
 * Embedded collection of UUID
 * @export
 * @interface EmbeddedCollectionUuid
 */
export interface EmbeddedCollectionUuid {
    /**
     * Resource collection
     * @type {Array<string>}
     * @memberof EmbeddedCollectionUuid
     */
    uUIDs: Array<string>;
}
/**
 * 
 * @export
 * @interface EncryptedName
 */
export interface EncryptedName {
    /**
     * 
     * @type {string}
     * @memberof EncryptedName
     */
    encrypted?: string;
    /**
     * 
     * @type {object}
     * @memberof EncryptedName
     */
    normalizedHash?: object;
}
/**
 * 
 * @export
 * @interface EncryptedString
 */
export interface EncryptedString {
    /**
     * 
     * @type {string}
     * @memberof EncryptedString
     */
    encrypted?: string;
}
/**
 * 
 * @export
 * @interface EncryptedStringWithHash
 */
export interface EncryptedStringWithHash {
    /**
     * 
     * @type {string}
     * @memberof EncryptedStringWithHash
     */
    encrypted?: string;
    /**
     * 
     * @type {string}
     * @memberof EncryptedStringWithHash
     */
    hash?: string;
}
/**
 * 
 * @export
 * @interface EntityModelMetrics
 */
export interface EntityModelMetrics {
    /**
     * 
     * @type {number}
     * @memberof EntityModelMetrics
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelMetrics
     */
    date?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelMetrics
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelMetrics
     */
    partnerId: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelMetrics
     */
    type: EntityModelMetricsTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelMetricsTypeEnum {
    DISPUTE = 'DISPUTE',
    PARTY = 'PARTY',
    TRANSACTION = 'TRANSACTION'
}

/**
 * 
 * @export
 * @interface EntityModelPartner
 */
export interface EntityModelPartner {
    /**
     * 
     * @type {EncryptedString}
     * @memberof EntityModelPartner
     */
    account?: EncryptedString;
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityModelPartner
     */
    ancestorPartnerIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartner
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartner
     */
    clientId?: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof EntityModelPartner
     */
    contactName?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartner
     */
    created?: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof EntityModelPartner
     */
    email?: EncryptedString;
    /**
     * 
     * @type {EncryptedString}
     * @memberof EntityModelPartner
     */
    fax?: EncryptedString;
    /**
     * 
     * @type {object}
     * @memberof EntityModelPartner
     */
    id?: object;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelPartner
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartner
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof EntityModelPartner
     */
    parentPartnerId?: object;
    /**
     * 
     * @type {EncryptedString}
     * @memberof EntityModelPartner
     */
    phone?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartner
     */
    primaryCurrency: EntityModelPartnerPrimaryCurrencyEnum;
    /**
     * 
     * @type {EncryptedString}
     * @memberof EntityModelPartner
     */
    routing?: EncryptedString;
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityModelPartner
     */
    serviceTypes?: Array<EntityModelPartnerServiceTypesEnum>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartner
     */
    status: EntityModelPartnerStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelPartner
     */
    trusted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartner
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelPartner
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerPrimaryCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerServiceTypesEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    PARTY = 'PARTY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerStatusEnum {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface EntityModelPartnerPartyLink
 */
export interface EntityModelPartnerPartyLink {
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerPartyLink
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerPartyLink
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelPartnerPartyLink
     */
    links?: Links;
    /**
     * 
     * @type {object}
     * @memberof EntityModelPartnerPartyLink
     */
    partnerId: object;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerPartyLink
     */
    partnerPartyRef: string;
    /**
     * 
     * @type {object}
     * @memberof EntityModelPartnerPartyLink
     */
    partyId: object;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerPartyLink
     */
    status: EntityModelPartnerPartyLinkStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerPartyLink
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelPartnerPartyLink
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerPartyLinkStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    OTPREQUIRED = 'OTP_REQUIRED',
    PENDING = 'PENDING',
    UNDERREVIEW = 'UNDER_REVIEW'
}

/**
 * 
 * @export
 * @interface EntityModelPartnerTransaction
 */
export interface EntityModelPartnerTransaction {
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    achFileId?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    declineReason?: EntityModelPartnerTransactionDeclineReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    disputeId?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    disputeStatus?: EntityModelPartnerTransactionDisputeStatusEnum;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof EntityModelPartnerTransaction
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {EncryptedName}
     * @memberof EntityModelPartnerTransaction
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    id?: string;
    /**
     * 
     * @type {EncryptedName}
     * @memberof EntityModelPartnerTransaction
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelPartnerTransaction
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    operationType?: EntityModelPartnerTransactionOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    partnerAccountId?: string;
    /**
     * 
     * @type {object}
     * @memberof EntityModelPartnerTransaction
     */
    partnerId: object;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    partnerName?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    partnerPartyLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    partnerPartyRef?: string;
    /**
     * 
     * @type {PartnerTransactionDetails}
     * @memberof EntityModelPartnerTransaction
     */
    partnerTransactionDetails?: PartnerTransactionDetails;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    partnerTransactionRef: string;
    /**
     * 
     * @type {object}
     * @memberof EntityModelPartnerTransaction
     */
    partyId?: object;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    reversalAchFileId?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelPartnerTransaction
     */
    sourceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    sourceAssetType?: EntityModelPartnerTransactionSourceAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    sourceCurrency?: EntityModelPartnerTransactionSourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    status: EntityModelPartnerTransactionStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof EntityModelPartnerTransaction
     */
    targetAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    targetAssetType?: EntityModelPartnerTransactionTargetAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    targetCurrency?: EntityModelPartnerTransactionTargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    transactionType: EntityModelPartnerTransactionTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPartnerTransaction
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelPartnerTransaction
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerTransactionDeclineReasonEnum {
    ACCOUNTNOTFOUND = 'ACCOUNT_NOT_FOUND',
    DAILYLIMITEXCEEDED = 'DAILY_LIMIT_EXCEEDED',
    MONTHLYLIMITEXCEEDED = 'MONTHLY_LIMIT_EXCEEDED',
    NOTSUFFICIENTFUNDS = 'NOT_SUFFICIENT_FUNDS',
    SUSPECTEDFRAUD = 'SUSPECTED_FRAUD',
    TRANSACTIONFAILED = 'TRANSACTION_FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerTransactionDisputeStatusEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED',
    ESCALATED = 'ESCALATED',
    MERCHANTREJECTED = 'MERCHANT_REJECTED',
    NEW = 'NEW',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerTransactionOperationTypeEnum {
    BUY = 'BUY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD',
    SELL = 'SELL'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerTransactionSourceAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerTransactionSourceCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerTransactionStatusEnum {
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerTransactionTargetAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerTransactionTargetCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartnerTransactionTransactionTypeEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD'
}

/**
 * 
 * @export
 * @interface EntityModelParty
 */
export interface EntityModelParty {
    /**
     * 
     * @type {string}
     * @memberof EntityModelParty
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelParty
     */
    created?: string;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof EntityModelParty
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {EncryptedName}
     * @memberof EntityModelParty
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {object}
     * @memberof EntityModelParty
     */
    id?: object;
    /**
     * 
     * @type {EncryptedName}
     * @memberof EntityModelParty
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof EntityModelParty
     */
    level: EntityModelPartyLevelEnum;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelParty
     */
    links?: Links;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof EntityModelParty
     */
    phone?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof EntityModelParty
     */
    status: EntityModelPartyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelParty
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelParty
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartyLevelEnum {
    LEVEL1 = 'LEVEL_1',
    LEVEL1LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL1SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL2 = 'LEVEL_2',
    LEVEL2LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL2SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL3 = 'LEVEL_3',
    LEVEL3LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL3SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL4 = 'LEVEL_4',
    LEVEL4LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL4SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL5 = 'LEVEL_5',
    LEVEL5LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL5SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL99 = 'LEVEL_99',
    LEVEL99LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    LEVEL99SUSPENDED = 'LEVEL_99_SUSPENDED',
    UNKNOWN = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum EntityModelPartyStatusEnum {
    CLOSED = 'CLOSED',
    LIQUIDATED = 'LIQUIDATED',
    OPEN = 'OPEN',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface EntityModelRolePermission
 */
export interface EntityModelRolePermission {
    /**
     * 
     * @type {string}
     * @memberof EntityModelRolePermission
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelRolePermission
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelRolePermission
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelRolePermission
     */
    partnerId: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelRolePermission
     */
    permission?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelRolePermission
     */
    roleName: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelRolePermission
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelRolePermission
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface EntityModelSMSHistory
 */
export interface EntityModelSMSHistory {
    /**
     * 
     * @type {EncryptedString}
     * @memberof EntityModelSMSHistory
     */
    body?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSMSHistory
     */
    created?: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof EntityModelSMSHistory
     */
    fromNumber?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSMSHistory
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelSMSHistory
     */
    links?: Links;
    /**
     * 
     * @type {object}
     * @memberof EntityModelSMSHistory
     */
    partyId?: object;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSMSHistory
     */
    templateName: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof EntityModelSMSHistory
     */
    toNumber?: EncryptedString;
    /**
     * 
     * @type {number}
     * @memberof EntityModelSMSHistory
     */
    twilioErrorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSMSHistory
     */
    twilioErrorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSMSHistory
     */
    twilioStatus: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelSMSHistory
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelSMSHistory
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    fieldName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    fieldValues?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    searchCriteria?: FilterSearchCriteriaEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FilterSearchCriteriaEnum {
    AFTER = 'AFTER',
    AFTERORNULL = 'AFTER_OR_NULL',
    BEFORE = 'BEFORE',
    BEFOREORNULL = 'BEFORE_OR_NULL',
    BETWEEN = 'BETWEEN',
    CONTAINS = 'CONTAINS',
    ENDSWITH = 'ENDS_WITH',
    EQUALS = 'EQUALS',
    GREATERTHAN = 'GREATER_THAN',
    GREATERTHANOREQUAL = 'GREATER_THAN_OR_EQUAL',
    GREATERTHANOREQUALORNULL = 'GREATER_THAN_OR_EQUAL_OR_NULL',
    IN = 'IN',
    INRANGE = 'IN_RANGE',
    LESSTHAN = 'LESS_THAN',
    LESSTHANOREQUAL = 'LESS_THAN_OR_EQUAL',
    LESSTHANOREQUALORNULL = 'LESS_THAN_OR_EQUAL_OR_NULL',
    NOTCONTAINS = 'NOT_CONTAINS',
    NOTEQUALS = 'NOT_EQUALS',
    NOTIN = 'NOT_IN',
    NOTNULL = 'NOT_NULL',
    RELATIVETIME = 'RELATIVE_TIME',
    STARTSWITH = 'STARTS_WITH'
}

/**
 * 
 * @export
 * @interface InvitePortalUserRequest
 */
export interface InvitePortalUserRequest {
    /**
     * 
     * @type {string}
     * @memberof InvitePortalUserRequest
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface InviteUserRequest
 */
export interface InviteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof InviteUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserRequest
     */
    partnerId?: string;
}
/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    deprecation?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    href?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    hreflang?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    media?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    profile?: string;
    /**
     * 
     * @type {object}
     * @memberof Link
     */
    rel?: object;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface Links
 */
export interface Links {
    /**
     * 
     * @type {boolean}
     * @memberof Links
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface Metrics
 */
export interface Metrics {
    /**
     * 
     * @type {number}
     * @memberof Metrics
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof Metrics
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof Metrics
     */
    partnerId: string;
    /**
     * 
     * @type {string}
     * @memberof Metrics
     */
    type: MetricsTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MetricsTypeEnum {
    DISPUTE = 'DISPUTE',
    PARTY = 'PARTY',
    TRANSACTION = 'TRANSACTION'
}

/**
 * 
 * @export
 * @interface Metrics1
 */
export interface Metrics1 {
    /**
     * 
     * @type {number}
     * @memberof Metrics1
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof Metrics1
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof Metrics1
     */
    partnerId: string;
    /**
     * 
     * @type {string}
     * @memberof Metrics1
     */
    type: Metrics1TypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum Metrics1TypeEnum {
    DISPUTE = 'DISPUTE',
    PARTY = 'PARTY',
    TRANSACTION = 'TRANSACTION'
}

/**
 * 
 * @export
 * @interface Metrics2
 */
export interface Metrics2 {
    /**
     * 
     * @type {number}
     * @memberof Metrics2
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof Metrics2
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof Metrics2
     */
    partnerId: string;
    /**
     * 
     * @type {string}
     * @memberof Metrics2
     */
    type: Metrics2TypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum Metrics2TypeEnum {
    DISPUTE = 'DISPUTE',
    PARTY = 'PARTY',
    TRANSACTION = 'TRANSACTION'
}

/**
 * 
 * @export
 * @interface Metrics3
 */
export interface Metrics3 {
    /**
     * 
     * @type {number}
     * @memberof Metrics3
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof Metrics3
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof Metrics3
     */
    partnerId: string;
    /**
     * 
     * @type {string}
     * @memberof Metrics3
     */
    type: Metrics3TypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum Metrics3TypeEnum {
    DISPUTE = 'DISPUTE',
    PARTY = 'PARTY',
    TRANSACTION = 'TRANSACTION'
}

/**
 * 
 * @export
 * @interface ModelAndView
 */
export interface ModelAndView {
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    empty?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ModelAndView
     */
    model?: object;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ModelAndView
     */
    modelMap?: { [key: string]: object; };
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    reference?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    status?: ModelAndViewStatusEnum;
    /**
     * 
     * @type {View}
     * @memberof ModelAndView
     */
    view?: View;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    viewName?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ModelAndViewStatusEnum {
    ACCEPTED = 'ACCEPTED',
    ALREADYREPORTED = 'ALREADY_REPORTED',
    BADGATEWAY = 'BAD_GATEWAY',
    BADREQUEST = 'BAD_REQUEST',
    BANDWIDTHLIMITEXCEEDED = 'BANDWIDTH_LIMIT_EXCEEDED',
    CHECKPOINT = 'CHECKPOINT',
    CONFLICT = 'CONFLICT',
    CONTINUE = 'CONTINUE',
    CREATED = 'CREATED',
    DESTINATIONLOCKED = 'DESTINATION_LOCKED',
    EXPECTATIONFAILED = 'EXPECTATION_FAILED',
    FAILEDDEPENDENCY = 'FAILED_DEPENDENCY',
    FORBIDDEN = 'FORBIDDEN',
    FOUND = 'FOUND',
    GATEWAYTIMEOUT = 'GATEWAY_TIMEOUT',
    GONE = 'GONE',
    HTTPVERSIONNOTSUPPORTED = 'HTTP_VERSION_NOT_SUPPORTED',
    IMUSED = 'IM_USED',
    INSUFFICIENTSPACEONRESOURCE = 'INSUFFICIENT_SPACE_ON_RESOURCE',
    INSUFFICIENTSTORAGE = 'INSUFFICIENT_STORAGE',
    INTERNALSERVERERROR = 'INTERNAL_SERVER_ERROR',
    IAMATEAPOT = 'I_AM_A_TEAPOT',
    LENGTHREQUIRED = 'LENGTH_REQUIRED',
    LOCKED = 'LOCKED',
    LOOPDETECTED = 'LOOP_DETECTED',
    METHODFAILURE = 'METHOD_FAILURE',
    METHODNOTALLOWED = 'METHOD_NOT_ALLOWED',
    MOVEDPERMANENTLY = 'MOVED_PERMANENTLY',
    MOVEDTEMPORARILY = 'MOVED_TEMPORARILY',
    MULTIPLECHOICES = 'MULTIPLE_CHOICES',
    MULTISTATUS = 'MULTI_STATUS',
    NETWORKAUTHENTICATIONREQUIRED = 'NETWORK_AUTHENTICATION_REQUIRED',
    NONAUTHORITATIVEINFORMATION = 'NON_AUTHORITATIVE_INFORMATION',
    NOTACCEPTABLE = 'NOT_ACCEPTABLE',
    NOTEXTENDED = 'NOT_EXTENDED',
    NOTFOUND = 'NOT_FOUND',
    NOTIMPLEMENTED = 'NOT_IMPLEMENTED',
    NOTMODIFIED = 'NOT_MODIFIED',
    NOCONTENT = 'NO_CONTENT',
    OK = 'OK',
    PARTIALCONTENT = 'PARTIAL_CONTENT',
    PAYLOADTOOLARGE = 'PAYLOAD_TOO_LARGE',
    PAYMENTREQUIRED = 'PAYMENT_REQUIRED',
    PERMANENTREDIRECT = 'PERMANENT_REDIRECT',
    PRECONDITIONFAILED = 'PRECONDITION_FAILED',
    PRECONDITIONREQUIRED = 'PRECONDITION_REQUIRED',
    PROCESSING = 'PROCESSING',
    PROXYAUTHENTICATIONREQUIRED = 'PROXY_AUTHENTICATION_REQUIRED',
    REQUESTEDRANGENOTSATISFIABLE = 'REQUESTED_RANGE_NOT_SATISFIABLE',
    REQUESTENTITYTOOLARGE = 'REQUEST_ENTITY_TOO_LARGE',
    REQUESTHEADERFIELDSTOOLARGE = 'REQUEST_HEADER_FIELDS_TOO_LARGE',
    REQUESTTIMEOUT = 'REQUEST_TIMEOUT',
    REQUESTURITOOLONG = 'REQUEST_URI_TOO_LONG',
    RESETCONTENT = 'RESET_CONTENT',
    SEEOTHER = 'SEE_OTHER',
    SERVICEUNAVAILABLE = 'SERVICE_UNAVAILABLE',
    SWITCHINGPROTOCOLS = 'SWITCHING_PROTOCOLS',
    TEMPORARYREDIRECT = 'TEMPORARY_REDIRECT',
    TOOEARLY = 'TOO_EARLY',
    TOOMANYREQUESTS = 'TOO_MANY_REQUESTS',
    UNAUTHORIZED = 'UNAUTHORIZED',
    UNAVAILABLEFORLEGALREASONS = 'UNAVAILABLE_FOR_LEGAL_REASONS',
    UNPROCESSABLEENTITY = 'UNPROCESSABLE_ENTITY',
    UNSUPPORTEDMEDIATYPE = 'UNSUPPORTED_MEDIA_TYPE',
    UPGRADEREQUIRED = 'UPGRADE_REQUIRED',
    URITOOLONG = 'URI_TOO_LONG',
    USEPROXY = 'USE_PROXY',
    VARIANTALSONEGOTIATES = 'VARIANT_ALSO_NEGOTIATES'
}

/**
 * 
 * @export
 * @interface PageApiComment
 */
export interface PageApiComment {
    /**
     * 
     * @type {Array<ApiComment>}
     * @memberof PageApiComment
     */
    content?: Array<ApiComment>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiComment
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiComment
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiComment
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiComment
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiComment
     */
    numberOfElements?: number;
    /**
     * 
     * @type {SwaggerPagable}
     * @memberof PageApiComment
     */
    pageable?: SwaggerPagable;
    /**
     * 
     * @type {number}
     * @memberof PageApiComment
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiComment
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiComment
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiComment
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageApiMerchantDispute
 */
export interface PageApiMerchantDispute {
    /**
     * 
     * @type {Array<ApiMerchantDispute>}
     * @memberof PageApiMerchantDispute
     */
    content?: Array<ApiMerchantDispute>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantDispute
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantDispute
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantDispute
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantDispute
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantDispute
     */
    numberOfElements?: number;
    /**
     * 
     * @type {SwaggerPagable}
     * @memberof PageApiMerchantDispute
     */
    pageable?: SwaggerPagable;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantDispute
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiMerchantDispute
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantDispute
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantDispute
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageApiMerchantPortalTransaction
 */
export interface PageApiMerchantPortalTransaction {
    /**
     * 
     * @type {Array<ApiMerchantPortalTransaction>}
     * @memberof PageApiMerchantPortalTransaction
     */
    content?: Array<ApiMerchantPortalTransaction>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantPortalTransaction
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantPortalTransaction
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantPortalTransaction
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantPortalTransaction
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantPortalTransaction
     */
    numberOfElements?: number;
    /**
     * 
     * @type {SwaggerPagable}
     * @memberof PageApiMerchantPortalTransaction
     */
    pageable?: SwaggerPagable;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantPortalTransaction
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiMerchantPortalTransaction
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantPortalTransaction
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantPortalTransaction
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageApiMerchantPortalUser
 */
export interface PageApiMerchantPortalUser {
    /**
     * 
     * @type {Array<ApiMerchantPortalUser>}
     * @memberof PageApiMerchantPortalUser
     */
    content?: Array<ApiMerchantPortalUser>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantPortalUser
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantPortalUser
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantPortalUser
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantPortalUser
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantPortalUser
     */
    numberOfElements?: number;
    /**
     * 
     * @type {SwaggerPagable}
     * @memberof PageApiMerchantPortalUser
     */
    pageable?: SwaggerPagable;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantPortalUser
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiMerchantPortalUser
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantPortalUser
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantPortalUser
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageApiMerchantSettlementSummaryResponse
 */
export interface PageApiMerchantSettlementSummaryResponse {
    /**
     * 
     * @type {Array<ApiMerchantSettlementSummaryResponse>}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    content?: Array<ApiMerchantSettlementSummaryResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    numberOfElements?: number;
    /**
     * 
     * @type {SwaggerPagable}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    pageable?: SwaggerPagable;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiMerchantSettlementSummaryResponse
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageApiPartnerTransaction
 */
export interface PageApiPartnerTransaction {
    /**
     * 
     * @type {Array<ApiPartnerTransaction>}
     * @memberof PageApiPartnerTransaction
     */
    content?: Array<ApiPartnerTransaction>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransaction
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransaction
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransaction
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransaction
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransaction
     */
    numberOfElements?: number;
    /**
     * 
     * @type {SwaggerPagable}
     * @memberof PageApiPartnerTransaction
     */
    pageable?: SwaggerPagable;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransaction
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiPartnerTransaction
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransaction
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransaction
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageApiPartnerTransactionSummary
 */
export interface PageApiPartnerTransactionSummary {
    /**
     * 
     * @type {Array<ApiPartnerTransactionSummary>}
     * @memberof PageApiPartnerTransactionSummary
     */
    content?: Array<ApiPartnerTransactionSummary>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransactionSummary
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransactionSummary
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartnerTransactionSummary
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransactionSummary
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransactionSummary
     */
    numberOfElements?: number;
    /**
     * 
     * @type {SwaggerPagable}
     * @memberof PageApiPartnerTransactionSummary
     */
    pageable?: SwaggerPagable;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransactionSummary
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiPartnerTransactionSummary
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransactionSummary
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartnerTransactionSummary
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageApiParty
 */
export interface PageApiParty {
    /**
     * 
     * @type {Array<ApiParty>}
     * @memberof PageApiParty
     */
    content?: Array<ApiParty>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiParty
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiParty
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiParty
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiParty
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiParty
     */
    numberOfElements?: number;
    /**
     * 
     * @type {SwaggerPagable}
     * @memberof PageApiParty
     */
    pageable?: SwaggerPagable;
    /**
     * 
     * @type {number}
     * @memberof PageApiParty
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiParty
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiParty
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiParty
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface PageApiPartyBaseSearchResponse
 */
export interface PageApiPartyBaseSearchResponse {
    /**
     * 
     * @type {Array<ApiPartyBaseSearchResponse>}
     * @memberof PageApiPartyBaseSearchResponse
     */
    content?: Array<ApiPartyBaseSearchResponse>;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartyBaseSearchResponse
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartyBaseSearchResponse
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageApiPartyBaseSearchResponse
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartyBaseSearchResponse
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartyBaseSearchResponse
     */
    numberOfElements?: number;
    /**
     * 
     * @type {SwaggerPagable}
     * @memberof PageApiPartyBaseSearchResponse
     */
    pageable?: SwaggerPagable;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartyBaseSearchResponse
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageApiPartyBaseSearchResponse
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartyBaseSearchResponse
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageApiPartyBaseSearchResponse
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface Partner
 */
export interface Partner {
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    account?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Partner
     */
    ancestorPartnerIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    bankName?: string;
    /**
     * 
     * @type {ApiPartnerConfiguration}
     * @memberof Partner
     */
    config: ApiPartnerConfiguration;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner
     */
    customerSupport?: ApiContact;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner
     */
    disputeSupport?: ApiContact;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    glCode?: string;
    /**
     * 
     * @type {object}
     * @memberof Partner
     */
    id: object;
    /**
     * The name of the partner
     * @type {string}
     * @memberof Partner
     */
    name: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner
     */
    operations?: ApiContact;
    /**
     * 
     * @type {object}
     * @memberof Partner
     */
    parentPartnerId: object;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    phone?: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner
     */
    primary?: ApiContact;
    /**
     * Primary currency of Partner
     * @type {string}
     * @memberof Partner
     */
    primaryCurrency: PartnerPrimaryCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    routing?: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner
     */
    secondary?: ApiContact;
    /**
     * The set of service types that are supported for this Partner
     * @type {Array<string>}
     * @memberof Partner
     */
    serviceTypes: Array<PartnerServiceTypesEnum>;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner
     */
    settlementSupport?: ApiContact;
    /**
     * The status of the partner
     * @type {string}
     * @memberof Partner
     */
    status: PartnerStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Partner
     */
    trusted?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerPrimaryCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerServiceTypesEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    PARTY = 'PARTY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerStatusEnum {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface Partner1
 */
export interface Partner1 {
    /**
     * 
     * @type {string}
     * @memberof Partner1
     */
    account?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Partner1
     */
    ancestorPartnerIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Partner1
     */
    bankName?: string;
    /**
     * 
     * @type {ApiPartnerConfiguration}
     * @memberof Partner1
     */
    config: ApiPartnerConfiguration;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner1
     */
    customerSupport?: ApiContact;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner1
     */
    disputeSupport?: ApiContact;
    /**
     * 
     * @type {string}
     * @memberof Partner1
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Partner1
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Partner1
     */
    glCode?: string;
    /**
     * 
     * @type {object}
     * @memberof Partner1
     */
    id: object;
    /**
     * The name of the partner
     * @type {string}
     * @memberof Partner1
     */
    name: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner1
     */
    operations?: ApiContact;
    /**
     * 
     * @type {object}
     * @memberof Partner1
     */
    parentPartnerId: object;
    /**
     * 
     * @type {string}
     * @memberof Partner1
     */
    phone?: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner1
     */
    primary?: ApiContact;
    /**
     * Primary currency of Partner
     * @type {string}
     * @memberof Partner1
     */
    primaryCurrency: Partner1PrimaryCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof Partner1
     */
    routing?: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner1
     */
    secondary?: ApiContact;
    /**
     * The set of service types that are supported for this Partner
     * @type {Array<string>}
     * @memberof Partner1
     */
    serviceTypes: Array<Partner1ServiceTypesEnum>;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner1
     */
    settlementSupport?: ApiContact;
    /**
     * The status of the partner
     * @type {string}
     * @memberof Partner1
     */
    status: Partner1StatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Partner1
     */
    trusted?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum Partner1PrimaryCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum Partner1ServiceTypesEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    PARTY = 'PARTY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD'
}
/**
    * @export
    * @enum {string}
    */
export enum Partner1StatusEnum {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface Partner2
 */
export interface Partner2 {
    /**
     * 
     * @type {string}
     * @memberof Partner2
     */
    account?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Partner2
     */
    ancestorPartnerIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Partner2
     */
    bankName?: string;
    /**
     * 
     * @type {ApiPartnerConfiguration}
     * @memberof Partner2
     */
    config: ApiPartnerConfiguration;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner2
     */
    customerSupport?: ApiContact;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner2
     */
    disputeSupport?: ApiContact;
    /**
     * 
     * @type {string}
     * @memberof Partner2
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Partner2
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Partner2
     */
    glCode?: string;
    /**
     * 
     * @type {object}
     * @memberof Partner2
     */
    id: object;
    /**
     * The name of the partner
     * @type {string}
     * @memberof Partner2
     */
    name: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner2
     */
    operations?: ApiContact;
    /**
     * 
     * @type {object}
     * @memberof Partner2
     */
    parentPartnerId: object;
    /**
     * 
     * @type {string}
     * @memberof Partner2
     */
    phone?: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner2
     */
    primary?: ApiContact;
    /**
     * Primary currency of Partner
     * @type {string}
     * @memberof Partner2
     */
    primaryCurrency: Partner2PrimaryCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof Partner2
     */
    routing?: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner2
     */
    secondary?: ApiContact;
    /**
     * The set of service types that are supported for this Partner
     * @type {Array<string>}
     * @memberof Partner2
     */
    serviceTypes: Array<Partner2ServiceTypesEnum>;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner2
     */
    settlementSupport?: ApiContact;
    /**
     * The status of the partner
     * @type {string}
     * @memberof Partner2
     */
    status: Partner2StatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Partner2
     */
    trusted?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum Partner2PrimaryCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum Partner2ServiceTypesEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    PARTY = 'PARTY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD'
}
/**
    * @export
    * @enum {string}
    */
export enum Partner2StatusEnum {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface Partner3
 */
export interface Partner3 {
    /**
     * 
     * @type {string}
     * @memberof Partner3
     */
    account?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Partner3
     */
    ancestorPartnerIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Partner3
     */
    bankName?: string;
    /**
     * 
     * @type {ApiPartnerConfiguration}
     * @memberof Partner3
     */
    config: ApiPartnerConfiguration;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner3
     */
    customerSupport?: ApiContact;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner3
     */
    disputeSupport?: ApiContact;
    /**
     * 
     * @type {string}
     * @memberof Partner3
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Partner3
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof Partner3
     */
    glCode?: string;
    /**
     * 
     * @type {object}
     * @memberof Partner3
     */
    id: object;
    /**
     * The name of the partner
     * @type {string}
     * @memberof Partner3
     */
    name: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner3
     */
    operations?: ApiContact;
    /**
     * 
     * @type {object}
     * @memberof Partner3
     */
    parentPartnerId: object;
    /**
     * 
     * @type {string}
     * @memberof Partner3
     */
    phone?: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner3
     */
    primary?: ApiContact;
    /**
     * Primary currency of Partner
     * @type {string}
     * @memberof Partner3
     */
    primaryCurrency: Partner3PrimaryCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof Partner3
     */
    routing?: string;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner3
     */
    secondary?: ApiContact;
    /**
     * The set of service types that are supported for this Partner
     * @type {Array<string>}
     * @memberof Partner3
     */
    serviceTypes: Array<Partner3ServiceTypesEnum>;
    /**
     * 
     * @type {ApiContact}
     * @memberof Partner3
     */
    settlementSupport?: ApiContact;
    /**
     * The status of the partner
     * @type {string}
     * @memberof Partner3
     */
    status: Partner3StatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Partner3
     */
    trusted?: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum Partner3PrimaryCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum Partner3ServiceTypesEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    PARTY = 'PARTY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD'
}
/**
    * @export
    * @enum {string}
    */
export enum Partner3StatusEnum {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface PartnerPartyDetailProjection
 */
export interface PartnerPartyDetailProjection {
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyDetailProjection
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyDetailProjection
     */
    created?: string;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof PartnerPartyDetailProjection
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {EncryptedName}
     * @memberof PartnerPartyDetailProjection
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {object}
     * @memberof PartnerPartyDetailProjection
     */
    id?: object;
    /**
     * 
     * @type {EncryptedName}
     * @memberof PartnerPartyDetailProjection
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyDetailProjection
     */
    level?: PartnerPartyDetailProjectionLevelEnum;
    /**
     * 
     * @type {object}
     * @memberof PartnerPartyDetailProjection
     */
    partnerId?: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyDetailProjection
     */
    partnerName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyDetailProjection
     */
    partnerPartyLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyDetailProjection
     */
    partnerPartyRef?: string;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof PartnerPartyDetailProjection
     */
    phone?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyDetailProjection
     */
    pplStatus?: PartnerPartyDetailProjectionPplStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyDetailProjection
     */
    status?: PartnerPartyDetailProjectionStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerPartyDetailProjectionLevelEnum {
    LEVEL1 = 'LEVEL_1',
    LEVEL1LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL1SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL2 = 'LEVEL_2',
    LEVEL2LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL2SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL3 = 'LEVEL_3',
    LEVEL3LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL3SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL4 = 'LEVEL_4',
    LEVEL4LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL4SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL5 = 'LEVEL_5',
    LEVEL5LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL5SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL99 = 'LEVEL_99',
    LEVEL99LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    LEVEL99SUSPENDED = 'LEVEL_99_SUSPENDED',
    UNKNOWN = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerPartyDetailProjectionPplStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    OTPREQUIRED = 'OTP_REQUIRED',
    PENDING = 'PENDING',
    UNDERREVIEW = 'UNDER_REVIEW'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerPartyDetailProjectionStatusEnum {
    CLOSED = 'CLOSED',
    LIQUIDATED = 'LIQUIDATED',
    OPEN = 'OPEN',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface PartnerPartyLink
 */
export interface PartnerPartyLink {
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerPartyLink
     */
    partnerId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink
     */
    partnerPartyRef: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerPartyLink
     */
    partyId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink
     */
    status: PartnerPartyLinkStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPartyLink
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerPartyLinkStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    OTPREQUIRED = 'OTP_REQUIRED',
    PENDING = 'PENDING',
    UNDERREVIEW = 'UNDER_REVIEW'
}

/**
 * 
 * @export
 * @interface PartnerPartyLink1
 */
export interface PartnerPartyLink1 {
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink1
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink1
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerPartyLink1
     */
    partnerId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink1
     */
    partnerPartyRef: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerPartyLink1
     */
    partyId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink1
     */
    status: PartnerPartyLink1StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink1
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPartyLink1
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerPartyLink1StatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    OTPREQUIRED = 'OTP_REQUIRED',
    PENDING = 'PENDING',
    UNDERREVIEW = 'UNDER_REVIEW'
}

/**
 * 
 * @export
 * @interface PartnerPartyLink2
 */
export interface PartnerPartyLink2 {
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink2
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink2
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerPartyLink2
     */
    partnerId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink2
     */
    partnerPartyRef: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerPartyLink2
     */
    partyId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink2
     */
    status: PartnerPartyLink2StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink2
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPartyLink2
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerPartyLink2StatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    OTPREQUIRED = 'OTP_REQUIRED',
    PENDING = 'PENDING',
    UNDERREVIEW = 'UNDER_REVIEW'
}

/**
 * 
 * @export
 * @interface PartnerPartyLink3
 */
export interface PartnerPartyLink3 {
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink3
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink3
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerPartyLink3
     */
    partnerId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink3
     */
    partnerPartyRef: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerPartyLink3
     */
    partyId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink3
     */
    status: PartnerPartyLink3StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerPartyLink3
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerPartyLink3
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerPartyLink3StatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    OTPREQUIRED = 'OTP_REQUIRED',
    PENDING = 'PENDING',
    UNDERREVIEW = 'UNDER_REVIEW'
}

/**
 * 
 * @export
 * @interface PartnerRequest
 */
export interface PartnerRequest {
    /**
     * 
     * @type {string}
     * @memberof PartnerRequest
     */
    partnerId?: string;
}
/**
 * 
 * @export
 * @interface PartnerRequest1
 */
export interface PartnerRequest1 {
    /**
     * 
     * @type {string}
     * @memberof PartnerRequest1
     */
    partnerId?: string;
}
/**
 * 
 * @export
 * @interface PartnerRequest2
 */
export interface PartnerRequest2 {
    /**
     * 
     * @type {string}
     * @memberof PartnerRequest2
     */
    partnerId?: string;
}
/**
 * 
 * @export
 * @interface PartnerTransaction
 */
export interface PartnerTransaction {
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    achFileId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    declineReason?: PartnerTransactionDeclineReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    disputeId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    disputeStatus?: PartnerTransactionDisputeStatusEnum;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof PartnerTransaction
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {EncryptedName}
     * @memberof PartnerTransaction
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    id?: string;
    /**
     * 
     * @type {EncryptedName}
     * @memberof PartnerTransaction
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    operationType?: PartnerTransactionOperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    partnerAccountId?: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerTransaction
     */
    partnerId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    partnerName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    partnerPartyLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    partnerPartyRef?: string;
    /**
     * 
     * @type {PartnerTransactionDetails}
     * @memberof PartnerTransaction
     */
    partnerTransactionDetails?: PartnerTransactionDetails;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    partnerTransactionRef: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerTransaction
     */
    partyId?: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    reversalAchFileId?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction
     */
    sourceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    sourceAssetType?: PartnerTransactionSourceAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    sourceCurrency?: PartnerTransactionSourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    status: PartnerTransactionStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction
     */
    targetAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    targetAssetType?: PartnerTransactionTargetAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    targetCurrency?: PartnerTransactionTargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    transactionType: PartnerTransactionTransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerTransactionDeclineReasonEnum {
    ACCOUNTNOTFOUND = 'ACCOUNT_NOT_FOUND',
    DAILYLIMITEXCEEDED = 'DAILY_LIMIT_EXCEEDED',
    MONTHLYLIMITEXCEEDED = 'MONTHLY_LIMIT_EXCEEDED',
    NOTSUFFICIENTFUNDS = 'NOT_SUFFICIENT_FUNDS',
    SUSPECTEDFRAUD = 'SUSPECTED_FRAUD',
    TRANSACTIONFAILED = 'TRANSACTION_FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransactionDisputeStatusEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED',
    ESCALATED = 'ESCALATED',
    MERCHANTREJECTED = 'MERCHANT_REJECTED',
    NEW = 'NEW',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransactionOperationTypeEnum {
    BUY = 'BUY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD',
    SELL = 'SELL'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransactionSourceAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransactionSourceCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransactionStatusEnum {
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransactionTargetAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransactionTargetCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransactionTransactionTypeEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD'
}

/**
 * 
 * @export
 * @interface PartnerTransaction1
 */
export interface PartnerTransaction1 {
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    achFileId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    declineReason?: PartnerTransaction1DeclineReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    disputeId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    disputeStatus?: PartnerTransaction1DisputeStatusEnum;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof PartnerTransaction1
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {EncryptedName}
     * @memberof PartnerTransaction1
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    id?: string;
    /**
     * 
     * @type {EncryptedName}
     * @memberof PartnerTransaction1
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    operationType?: PartnerTransaction1OperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    partnerAccountId?: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerTransaction1
     */
    partnerId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    partnerName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    partnerPartyLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    partnerPartyRef?: string;
    /**
     * 
     * @type {PartnerTransactionDetails}
     * @memberof PartnerTransaction1
     */
    partnerTransactionDetails?: PartnerTransactionDetails;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    partnerTransactionRef: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerTransaction1
     */
    partyId?: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    reversalAchFileId?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction1
     */
    sourceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    sourceAssetType?: PartnerTransaction1SourceAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    sourceCurrency?: PartnerTransaction1SourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    status: PartnerTransaction1StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction1
     */
    targetAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    targetAssetType?: PartnerTransaction1TargetAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    targetCurrency?: PartnerTransaction1TargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    transactionType: PartnerTransaction1TransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction1
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction1
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction1DeclineReasonEnum {
    ACCOUNTNOTFOUND = 'ACCOUNT_NOT_FOUND',
    DAILYLIMITEXCEEDED = 'DAILY_LIMIT_EXCEEDED',
    MONTHLYLIMITEXCEEDED = 'MONTHLY_LIMIT_EXCEEDED',
    NOTSUFFICIENTFUNDS = 'NOT_SUFFICIENT_FUNDS',
    SUSPECTEDFRAUD = 'SUSPECTED_FRAUD',
    TRANSACTIONFAILED = 'TRANSACTION_FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction1DisputeStatusEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED',
    ESCALATED = 'ESCALATED',
    MERCHANTREJECTED = 'MERCHANT_REJECTED',
    NEW = 'NEW',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction1OperationTypeEnum {
    BUY = 'BUY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD',
    SELL = 'SELL'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction1SourceAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction1SourceCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction1StatusEnum {
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction1TargetAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction1TargetCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction1TransactionTypeEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD'
}

/**
 * 
 * @export
 * @interface PartnerTransaction2
 */
export interface PartnerTransaction2 {
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    achFileId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    declineReason?: PartnerTransaction2DeclineReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    disputeId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    disputeStatus?: PartnerTransaction2DisputeStatusEnum;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof PartnerTransaction2
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {EncryptedName}
     * @memberof PartnerTransaction2
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    id?: string;
    /**
     * 
     * @type {EncryptedName}
     * @memberof PartnerTransaction2
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    operationType?: PartnerTransaction2OperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    partnerAccountId?: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerTransaction2
     */
    partnerId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    partnerName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    partnerPartyLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    partnerPartyRef?: string;
    /**
     * 
     * @type {PartnerTransactionDetails}
     * @memberof PartnerTransaction2
     */
    partnerTransactionDetails?: PartnerTransactionDetails;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    partnerTransactionRef: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerTransaction2
     */
    partyId?: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    reversalAchFileId?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction2
     */
    sourceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    sourceAssetType?: PartnerTransaction2SourceAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    sourceCurrency?: PartnerTransaction2SourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    status: PartnerTransaction2StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction2
     */
    targetAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    targetAssetType?: PartnerTransaction2TargetAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    targetCurrency?: PartnerTransaction2TargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    transactionType: PartnerTransaction2TransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction2
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction2
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction2DeclineReasonEnum {
    ACCOUNTNOTFOUND = 'ACCOUNT_NOT_FOUND',
    DAILYLIMITEXCEEDED = 'DAILY_LIMIT_EXCEEDED',
    MONTHLYLIMITEXCEEDED = 'MONTHLY_LIMIT_EXCEEDED',
    NOTSUFFICIENTFUNDS = 'NOT_SUFFICIENT_FUNDS',
    SUSPECTEDFRAUD = 'SUSPECTED_FRAUD',
    TRANSACTIONFAILED = 'TRANSACTION_FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction2DisputeStatusEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED',
    ESCALATED = 'ESCALATED',
    MERCHANTREJECTED = 'MERCHANT_REJECTED',
    NEW = 'NEW',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction2OperationTypeEnum {
    BUY = 'BUY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD',
    SELL = 'SELL'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction2SourceAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction2SourceCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction2StatusEnum {
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction2TargetAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction2TargetCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction2TransactionTypeEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD'
}

/**
 * 
 * @export
 * @interface PartnerTransaction3
 */
export interface PartnerTransaction3 {
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    achFileId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    declineReason?: PartnerTransaction3DeclineReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    disputeId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    disputeStatus?: PartnerTransaction3DisputeStatusEnum;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof PartnerTransaction3
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    externalTransactionId?: string;
    /**
     * 
     * @type {EncryptedName}
     * @memberof PartnerTransaction3
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    id?: string;
    /**
     * 
     * @type {EncryptedName}
     * @memberof PartnerTransaction3
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    operationType?: PartnerTransaction3OperationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    partnerAccountId?: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerTransaction3
     */
    partnerId: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    partnerName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    partnerPartyLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    partnerPartyRef?: string;
    /**
     * 
     * @type {PartnerTransactionDetails}
     * @memberof PartnerTransaction3
     */
    partnerTransactionDetails?: PartnerTransactionDetails;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    partnerTransactionRef: string;
    /**
     * 
     * @type {object}
     * @memberof PartnerTransaction3
     */
    partyId?: object;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    reversalAchFileId?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction3
     */
    sourceAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    sourceAssetType?: PartnerTransaction3SourceAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    sourceCurrency?: PartnerTransaction3SourceCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    status: PartnerTransaction3StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction3
     */
    targetAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    targetAssetType?: PartnerTransaction3TargetAssetTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    targetCurrency?: PartnerTransaction3TargetCurrencyEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    transactionType: PartnerTransaction3TransactionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransaction3
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransaction3
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction3DeclineReasonEnum {
    ACCOUNTNOTFOUND = 'ACCOUNT_NOT_FOUND',
    DAILYLIMITEXCEEDED = 'DAILY_LIMIT_EXCEEDED',
    MONTHLYLIMITEXCEEDED = 'MONTHLY_LIMIT_EXCEEDED',
    NOTSUFFICIENTFUNDS = 'NOT_SUFFICIENT_FUNDS',
    SUSPECTEDFRAUD = 'SUSPECTED_FRAUD',
    TRANSACTIONFAILED = 'TRANSACTION_FAILED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction3DisputeStatusEnum {
    ACCEPTED = 'ACCEPTED',
    CANCELLED = 'CANCELLED',
    ESCALATED = 'ESCALATED',
    MERCHANTREJECTED = 'MERCHANT_REJECTED',
    NEW = 'NEW',
    REJECTED = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction3OperationTypeEnum {
    BUY = 'BUY',
    PAYOUT = 'PAYOUT',
    REWARD = 'REWARD',
    SELL = 'SELL'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction3SourceAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction3SourceCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction3StatusEnum {
    CAPTURED = 'CAPTURED',
    DECLINED = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction3TargetAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction3TargetCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}
/**
    * @export
    * @enum {string}
    */
export enum PartnerTransaction3TransactionTypeEnum {
    CRYPTOCURRENCY = 'CRYPTO_CURRENCY',
    REWARD = 'REWARD'
}

/**
 * 
 * @export
 * @interface PartnerTransactionDetails
 */
export interface PartnerTransactionDetails {
    /**
     * 
     * @type {string}
     * @memberof PartnerTransactionDetails
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransactionDetails
     */
    cryptoCurrencySource?: PartnerTransactionDetailsCryptoCurrencySourceEnum;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransactionDetails
     */
    externalAccountRef?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransactionDetails
     */
    formattedFundingAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerTransactionDetails
     */
    fundingSource?: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof PartnerTransactionDetails
     */
    internalNotes?: EncryptedString;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransactionDetails
     */
    partnerAmount?: number;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof PartnerTransactionDetails
     */
    phone?: EncryptedStringWithHash;
    /**
     * 
     * @type {number}
     * @memberof PartnerTransactionDetails
     */
    unitPrice?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartnerTransactionDetailsCryptoCurrencySourceEnum {
    PARTNER = 'PARTNER',
    PARTY = 'PARTY'
}

/**
 * 
 * @export
 * @interface Party
 */
export interface Party {
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    created?: string;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof Party
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {EncryptedName}
     * @memberof Party
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {object}
     * @memberof Party
     */
    id?: object;
    /**
     * 
     * @type {EncryptedName}
     * @memberof Party
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    level: PartyLevelEnum;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof Party
     */
    phone?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    status: PartyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof Party
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PartyLevelEnum {
    LEVEL1 = 'LEVEL_1',
    LEVEL1LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL1SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL2 = 'LEVEL_2',
    LEVEL2LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL2SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL3 = 'LEVEL_3',
    LEVEL3LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL3SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL4 = 'LEVEL_4',
    LEVEL4LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL4SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL5 = 'LEVEL_5',
    LEVEL5LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL5SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL99 = 'LEVEL_99',
    LEVEL99LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    LEVEL99SUSPENDED = 'LEVEL_99_SUSPENDED',
    UNKNOWN = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum PartyStatusEnum {
    CLOSED = 'CLOSED',
    LIQUIDATED = 'LIQUIDATED',
    OPEN = 'OPEN',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface Party1
 */
export interface Party1 {
    /**
     * 
     * @type {string}
     * @memberof Party1
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof Party1
     */
    created?: string;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof Party1
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {EncryptedName}
     * @memberof Party1
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {object}
     * @memberof Party1
     */
    id?: object;
    /**
     * 
     * @type {EncryptedName}
     * @memberof Party1
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof Party1
     */
    level: Party1LevelEnum;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof Party1
     */
    phone?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof Party1
     */
    status: Party1StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Party1
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof Party1
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum Party1LevelEnum {
    LEVEL1 = 'LEVEL_1',
    LEVEL1LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL1SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL2 = 'LEVEL_2',
    LEVEL2LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL2SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL3 = 'LEVEL_3',
    LEVEL3LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL3SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL4 = 'LEVEL_4',
    LEVEL4LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL4SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL5 = 'LEVEL_5',
    LEVEL5LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL5SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL99 = 'LEVEL_99',
    LEVEL99LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    LEVEL99SUSPENDED = 'LEVEL_99_SUSPENDED',
    UNKNOWN = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum Party1StatusEnum {
    CLOSED = 'CLOSED',
    LIQUIDATED = 'LIQUIDATED',
    OPEN = 'OPEN',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface Party2
 */
export interface Party2 {
    /**
     * 
     * @type {string}
     * @memberof Party2
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof Party2
     */
    created?: string;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof Party2
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {EncryptedName}
     * @memberof Party2
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {object}
     * @memberof Party2
     */
    id?: object;
    /**
     * 
     * @type {EncryptedName}
     * @memberof Party2
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof Party2
     */
    level: Party2LevelEnum;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof Party2
     */
    phone?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof Party2
     */
    status: Party2StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Party2
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof Party2
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum Party2LevelEnum {
    LEVEL1 = 'LEVEL_1',
    LEVEL1LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL1SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL2 = 'LEVEL_2',
    LEVEL2LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL2SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL3 = 'LEVEL_3',
    LEVEL3LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL3SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL4 = 'LEVEL_4',
    LEVEL4LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL4SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL5 = 'LEVEL_5',
    LEVEL5LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL5SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL99 = 'LEVEL_99',
    LEVEL99LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    LEVEL99SUSPENDED = 'LEVEL_99_SUSPENDED',
    UNKNOWN = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum Party2StatusEnum {
    CLOSED = 'CLOSED',
    LIQUIDATED = 'LIQUIDATED',
    OPEN = 'OPEN',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface Party3
 */
export interface Party3 {
    /**
     * 
     * @type {string}
     * @memberof Party3
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof Party3
     */
    created?: string;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof Party3
     */
    email?: EncryptedStringWithHash;
    /**
     * 
     * @type {EncryptedName}
     * @memberof Party3
     */
    firstName?: EncryptedName;
    /**
     * 
     * @type {object}
     * @memberof Party3
     */
    id?: object;
    /**
     * 
     * @type {EncryptedName}
     * @memberof Party3
     */
    lastName?: EncryptedName;
    /**
     * 
     * @type {string}
     * @memberof Party3
     */
    level: Party3LevelEnum;
    /**
     * 
     * @type {EncryptedStringWithHash}
     * @memberof Party3
     */
    phone?: EncryptedStringWithHash;
    /**
     * 
     * @type {string}
     * @memberof Party3
     */
    status: Party3StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Party3
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof Party3
     */
    version?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum Party3LevelEnum {
    LEVEL1 = 'LEVEL_1',
    LEVEL1LIQUIDATED = 'LEVEL_1_LIQUIDATED',
    LEVEL1SUSPENDED = 'LEVEL_1_SUSPENDED',
    LEVEL2 = 'LEVEL_2',
    LEVEL2LIQUIDATED = 'LEVEL_2_LIQUIDATED',
    LEVEL2SUSPENDED = 'LEVEL_2_SUSPENDED',
    LEVEL3 = 'LEVEL_3',
    LEVEL3LIQUIDATED = 'LEVEL_3_LIQUIDATED',
    LEVEL3SUSPENDED = 'LEVEL_3_SUSPENDED',
    LEVEL4 = 'LEVEL_4',
    LEVEL4LIQUIDATED = 'LEVEL_4_LIQUIDATED',
    LEVEL4SUSPENDED = 'LEVEL_4_SUSPENDED',
    LEVEL5 = 'LEVEL_5',
    LEVEL5LIQUIDATED = 'LEVEL_5_LIQUIDATED',
    LEVEL5SUSPENDED = 'LEVEL_5_SUSPENDED',
    LEVEL99 = 'LEVEL_99',
    LEVEL99LIQUIDATED = 'LEVEL_99_LIQUIDATED',
    LEVEL99SUSPENDED = 'LEVEL_99_SUSPENDED',
    UNKNOWN = 'UNKNOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum Party3StatusEnum {
    CLOSED = 'CLOSED',
    LIQUIDATED = 'LIQUIDATED',
    OPEN = 'OPEN',
    SUSPENDED = 'SUSPENDED'
}

/**
 * 
 * @export
 * @interface PortfolioByAsset
 */
export interface PortfolioByAsset {
    /**
     * 
     * @type {string}
     * @memberof PortfolioByAsset
     */
    assetType?: PortfolioByAssetAssetTypeEnum;
    /**
     * 
     * @type {Array<AssetValueByCategory>}
     * @memberof PortfolioByAsset
     */
    assetsByCategories?: Array<AssetValueByCategory>;
    /**
     * 
     * @type {ServiceAmount}
     * @memberof PortfolioByAsset
     */
    availableValue?: ServiceAmount;
    /**
     * 
     * @type {ServiceAmount}
     * @memberof PortfolioByAsset
     */
    value?: ServiceAmount;
}

/**
    * @export
    * @enum {string}
    */
export enum PortfolioByAssetAssetTypeEnum {
    BAKKTCARD = 'BAKKT_CARD',
    CASH = 'CASH',
    CRYPTO = 'CRYPTO',
    DEBITCARD = 'DEBIT_CARD',
    GIFTCARD = 'GIFT_CARD',
    REWARDS = 'REWARDS'
}

/**
 * 
 * @export
 * @interface RepresentationModelObject
 */
export interface RepresentationModelObject {
    /**
     * 
     * @type {Links}
     * @memberof RepresentationModelObject
     */
    links?: Links;
}
/**
 * 
 * @export
 * @interface RolePermission
 */
export interface RolePermission {
    /**
     * 
     * @type {string}
     * @memberof RolePermission
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission
     */
    partnerId: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission
     */
    permission?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission
     */
    roleName: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof RolePermission
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface RolePermission1
 */
export interface RolePermission1 {
    /**
     * 
     * @type {string}
     * @memberof RolePermission1
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission1
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission1
     */
    partnerId: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission1
     */
    permission?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission1
     */
    roleName: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission1
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof RolePermission1
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface RolePermission2
 */
export interface RolePermission2 {
    /**
     * 
     * @type {string}
     * @memberof RolePermission2
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission2
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission2
     */
    partnerId: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission2
     */
    permission?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission2
     */
    roleName: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission2
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof RolePermission2
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface RolePermission3
 */
export interface RolePermission3 {
    /**
     * 
     * @type {string}
     * @memberof RolePermission3
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission3
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission3
     */
    partnerId: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission3
     */
    permission?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission3
     */
    roleName: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission3
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof RolePermission3
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface SMSHistory
 */
export interface SMSHistory {
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory
     */
    body?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory
     */
    created?: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory
     */
    fromNumber?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof SMSHistory
     */
    partyId?: object;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory
     */
    templateName: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory
     */
    toNumber?: EncryptedString;
    /**
     * 
     * @type {number}
     * @memberof SMSHistory
     */
    twilioErrorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory
     */
    twilioErrorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory
     */
    twilioStatus: string;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof SMSHistory
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface SMSHistory1
 */
export interface SMSHistory1 {
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory1
     */
    body?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory1
     */
    created?: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory1
     */
    fromNumber?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory1
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof SMSHistory1
     */
    partyId?: object;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory1
     */
    templateName: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory1
     */
    toNumber?: EncryptedString;
    /**
     * 
     * @type {number}
     * @memberof SMSHistory1
     */
    twilioErrorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory1
     */
    twilioErrorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory1
     */
    twilioStatus: string;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory1
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof SMSHistory1
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface SMSHistory2
 */
export interface SMSHistory2 {
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory2
     */
    body?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory2
     */
    created?: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory2
     */
    fromNumber?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory2
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof SMSHistory2
     */
    partyId?: object;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory2
     */
    templateName: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory2
     */
    toNumber?: EncryptedString;
    /**
     * 
     * @type {number}
     * @memberof SMSHistory2
     */
    twilioErrorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory2
     */
    twilioErrorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory2
     */
    twilioStatus: string;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory2
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof SMSHistory2
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface SMSHistory3
 */
export interface SMSHistory3 {
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory3
     */
    body?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory3
     */
    created?: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory3
     */
    fromNumber?: EncryptedString;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory3
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof SMSHistory3
     */
    partyId?: object;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory3
     */
    templateName: string;
    /**
     * 
     * @type {EncryptedString}
     * @memberof SMSHistory3
     */
    toNumber?: EncryptedString;
    /**
     * 
     * @type {number}
     * @memberof SMSHistory3
     */
    twilioErrorCode?: number;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory3
     */
    twilioErrorMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory3
     */
    twilioStatus: string;
    /**
     * 
     * @type {string}
     * @memberof SMSHistory3
     */
    updated?: string;
    /**
     * 
     * @type {number}
     * @memberof SMSHistory3
     */
    version?: number;
}
/**
 * 
 * @export
 * @interface SearchParameters
 */
export interface SearchParameters {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchParameters
     */
    excludeFromSearchText?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters
     */
    filter?: Array<Filter>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    fromDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters
     */
    searchText?: string;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters
     */
    sort?: Array<SortBy>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters
     */
    toDate?: number;
}
/**
 * 
 * @export
 * @interface SearchParameters1
 */
export interface SearchParameters1 {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchParameters1
     */
    excludeFromSearchText?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters1
     */
    filter?: Array<Filter>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters1
     */
    fromDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters1
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters1
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters1
     */
    searchText?: string;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters1
     */
    sort?: Array<SortBy>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters1
     */
    toDate?: number;
}
/**
 * 
 * @export
 * @interface SearchParameters2
 */
export interface SearchParameters2 {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchParameters2
     */
    excludeFromSearchText?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters2
     */
    filter?: Array<Filter>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters2
     */
    fromDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters2
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters2
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters2
     */
    searchText?: string;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters2
     */
    sort?: Array<SortBy>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters2
     */
    toDate?: number;
}
/**
 * 
 * @export
 * @interface SearchParameters3
 */
export interface SearchParameters3 {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchParameters3
     */
    excludeFromSearchText?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters3
     */
    filter?: Array<Filter>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters3
     */
    fromDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters3
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters3
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters3
     */
    searchText?: string;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters3
     */
    sort?: Array<SortBy>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters3
     */
    toDate?: number;
}
/**
 * 
 * @export
 * @interface SearchParameters4
 */
export interface SearchParameters4 {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchParameters4
     */
    excludeFromSearchText?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters4
     */
    filter?: Array<Filter>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters4
     */
    fromDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters4
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters4
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters4
     */
    searchText?: string;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters4
     */
    sort?: Array<SortBy>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters4
     */
    toDate?: number;
}
/**
 * 
 * @export
 * @interface SearchParameters5
 */
export interface SearchParameters5 {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchParameters5
     */
    excludeFromSearchText?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters5
     */
    filter?: Array<Filter>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters5
     */
    fromDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters5
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters5
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters5
     */
    searchText?: string;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters5
     */
    sort?: Array<SortBy>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters5
     */
    toDate?: number;
}
/**
 * 
 * @export
 * @interface SearchParameters6
 */
export interface SearchParameters6 {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchParameters6
     */
    excludeFromSearchText?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters6
     */
    filter?: Array<Filter>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters6
     */
    fromDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters6
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters6
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters6
     */
    searchText?: string;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters6
     */
    sort?: Array<SortBy>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters6
     */
    toDate?: number;
}
/**
 * 
 * @export
 * @interface SearchParameters7
 */
export interface SearchParameters7 {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchParameters7
     */
    excludeFromSearchText?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters7
     */
    filter?: Array<Filter>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters7
     */
    fromDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters7
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters7
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters7
     */
    searchText?: string;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters7
     */
    sort?: Array<SortBy>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters7
     */
    toDate?: number;
}
/**
 * 
 * @export
 * @interface SearchParameters8
 */
export interface SearchParameters8 {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchParameters8
     */
    excludeFromSearchText?: Array<string>;
    /**
     * 
     * @type {Array<Filter>}
     * @memberof SearchParameters8
     */
    filter?: Array<Filter>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters8
     */
    fromDate?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters8
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters8
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchParameters8
     */
    searchText?: string;
    /**
     * 
     * @type {Array<SortBy>}
     * @memberof SearchParameters8
     */
    sort?: Array<SortBy>;
    /**
     * 
     * @type {number}
     * @memberof SearchParameters8
     */
    toDate?: number;
}
/**
 * 
 * @export
 * @interface ServiceAmount
 */
export interface ServiceAmount {
    /**
     * 
     * @type {number}
     * @memberof ServiceAmount
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceAmount
     */
    currency?: ServiceAmountCurrencyEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ServiceAmountCurrencyEnum {
    BCH = 'BCH',
    BTC = 'BTC',
    ETH = 'ETH',
    LCP = 'LCP',
    LKO = 'LKO',
    LMO = 'LMO',
    LTC = 'LTC',
    LWR = 'LWR',
    LXX = 'LXX',
    USD = 'USD'
}

/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface SortBy
 */
export interface SortBy {
    /**
     * 
     * @type {string}
     * @memberof SortBy
     */
    fieldName?: string;
    /**
     * 
     * @type {string}
     * @memberof SortBy
     */
    sortOrder?: SortBySortOrderEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SortBySortOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}

/**
 * 
 * @export
 * @interface SwaggerPagable
 */
export interface SwaggerPagable {
    /**
     * Results page you want to retrieve (0..N)
     * @type {object}
     * @memberof SwaggerPagable
     */
    page?: object;
    /**
     * Number of records per page.
     * @type {object}
     * @memberof SwaggerPagable
     */
    size?: object;
    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {object}
     * @memberof SwaggerPagable
     */
    sort?: object;
}
/**
 * 
 * @export
 * @interface UpdateRoleRequest
 */
export interface UpdateRoleRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRoleRequest
     */
    addPermissions?: Array<UpdateRoleRequestAddPermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleRequest
     */
    partnerId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRoleRequest
     */
    removePermissions?: Array<UpdateRoleRequestRemovePermissionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRoleRequest
     */
    roleId?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateRoleRequestAddPermissionsEnum {
    ADMINCREATE = 'ADMIN_CREATE',
    ADMINUPDATE = 'ADMIN_UPDATE',
    CONSUMERVIEW = 'CONSUMER_VIEW',
    CONSUMERVIEWDETAIL = 'CONSUMER_VIEW_DETAIL',
    PROFILEUPDATE = 'PROFILE_UPDATE',
    PROFILEVIEW = 'PROFILE_VIEW',
    ROLECREATEUPDATE = 'ROLE_CREATE_UPDATE',
    SETTLEMENTREPORTVIEW = 'SETTLEMENT_REPORT_VIEW',
    TRANSACTIONCANCEL = 'TRANSACTION_CANCEL',
    TRANSACTIONREVERSE = 'TRANSACTION_REVERSE',
    TRANSACTIONVIEW = 'TRANSACTION_VIEW',
    TRANSACTIONVIEWDETAIL = 'TRANSACTION_VIEW_DETAIL',
    USERSCREATE = 'USERS_CREATE',
    USERSUPDATE = 'USERS_UPDATE',
    USERSUPDATESYNC = 'USERS_UPDATE_SYNC',
    USERSVIEW = 'USERS_VIEW',
    USERSVIEWDETAIL = 'USERS_VIEW_DETAIL'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateRoleRequestRemovePermissionsEnum {
    ADMINCREATE = 'ADMIN_CREATE',
    ADMINUPDATE = 'ADMIN_UPDATE',
    CONSUMERVIEW = 'CONSUMER_VIEW',
    CONSUMERVIEWDETAIL = 'CONSUMER_VIEW_DETAIL',
    PROFILEUPDATE = 'PROFILE_UPDATE',
    PROFILEVIEW = 'PROFILE_VIEW',
    ROLECREATEUPDATE = 'ROLE_CREATE_UPDATE',
    SETTLEMENTREPORTVIEW = 'SETTLEMENT_REPORT_VIEW',
    TRANSACTIONCANCEL = 'TRANSACTION_CANCEL',
    TRANSACTIONREVERSE = 'TRANSACTION_REVERSE',
    TRANSACTIONVIEW = 'TRANSACTION_VIEW',
    TRANSACTIONVIEWDETAIL = 'TRANSACTION_VIEW_DETAIL',
    USERSCREATE = 'USERS_CREATE',
    USERSUPDATE = 'USERS_UPDATE',
    USERSUPDATESYNC = 'USERS_UPDATE_SYNC',
    USERSVIEW = 'USERS_VIEW',
    USERSVIEWDETAIL = 'USERS_VIEW_DETAIL'
}

/**
 * 
 * @export
 * @interface UpdateUserPartnerRerquest
 */
export interface UpdateUserPartnerRerquest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserPartnerRerquest
     */
    addPartnerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserPartnerRerquest
     */
    removePartnerIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequest
     */
    addRoles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequest
     */
    removeRoles?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest1
 */
export interface UpdateUserRequest1 {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequest1
     */
    addRoles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRequest1
     */
    removeRoles?: Array<string>;
}
/**
 * 
 * @export
 * @interface View
 */
export interface View {
    /**
     * 
     * @type {string}
     * @memberof View
     */
    contentType?: string;
}

/**
 * BasicErrorControllerApi - axios parameter creator
 * @export
 */
export const BasicErrorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/error`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BasicErrorControllerApi - functional programming interface
 * @export
 */
export const BasicErrorControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingDELETE(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await BasicErrorControllerApiAxiosParamCreator(configuration).errorHtmlUsingDELETE(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await BasicErrorControllerApiAxiosParamCreator(configuration).errorHtmlUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingHEAD(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await BasicErrorControllerApiAxiosParamCreator(configuration).errorHtmlUsingHEAD(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingOPTIONS(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await BasicErrorControllerApiAxiosParamCreator(configuration).errorHtmlUsingOPTIONS(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingPATCH(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await BasicErrorControllerApiAxiosParamCreator(configuration).errorHtmlUsingPATCH(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingPOST(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await BasicErrorControllerApiAxiosParamCreator(configuration).errorHtmlUsingPOST(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorHtmlUsingPUT(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelAndView>> {
            const localVarAxiosArgs = await BasicErrorControllerApiAxiosParamCreator(configuration).errorHtmlUsingPUT(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BasicErrorControllerApi - factory interface
 * @export
 */
export const BasicErrorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options?: any): AxiosPromise<ModelAndView> {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingDELETE(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options?: any): AxiosPromise<ModelAndView> {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options?: any): AxiosPromise<ModelAndView> {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingHEAD(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options?: any): AxiosPromise<ModelAndView> {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingOPTIONS(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options?: any): AxiosPromise<ModelAndView> {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPATCH(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options?: any): AxiosPromise<ModelAndView> {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPOST(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options?: any): AxiosPromise<ModelAndView> {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPUT(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BasicErrorControllerApi - object-oriented interface
 * @export
 * @class BasicErrorControllerApi
 * @extends {BaseAPI}
 */
export class BasicErrorControllerApi extends BaseAPI {
    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingDELETE(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingDELETE(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingGET(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingHEAD(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingHEAD(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingOPTIONS(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingOPTIONS(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPATCH(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPATCH(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPOST(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPOST(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPUT(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPUT(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * DashBoardMetricsControllerApi - axios parameter creator
 * @export
 */
export const DashBoardMetricsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetchDashboardMetrics
         * @param {SearchParameters5} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDashboardMetricsUsingPOST: async (searchParameters: SearchParameters5, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            if (searchParameters === null || searchParameters === undefined) {
                throw new RequiredError('searchParameters','Required parameter searchParameters was null or undefined when calling fetchDashboardMetricsUsingPOST.');
            }
            const localVarPath = `/partner-portal/metrics`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchParameters !== undefined ? searchParameters : {}) : (searchParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashBoardMetricsControllerApi - functional programming interface
 * @export
 */
export const DashBoardMetricsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetchDashboardMetrics
         * @param {SearchParameters5} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchDashboardMetricsUsingPOST(searchParameters: SearchParameters5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiDashBoardMetricsDetails>> {
            const localVarAxiosArgs = await DashBoardMetricsControllerApiAxiosParamCreator(configuration).fetchDashboardMetricsUsingPOST(searchParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DashBoardMetricsControllerApi - factory interface
 * @export
 */
export const DashBoardMetricsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary fetchDashboardMetrics
         * @param {SearchParameters5} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDashboardMetricsUsingPOST(searchParameters: SearchParameters5, options?: any): AxiosPromise<ApiResponseApiDashBoardMetricsDetails> {
            return DashBoardMetricsControllerApiFp(configuration).fetchDashboardMetricsUsingPOST(searchParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashBoardMetricsControllerApi - object-oriented interface
 * @export
 * @class DashBoardMetricsControllerApi
 * @extends {BaseAPI}
 */
export class DashBoardMetricsControllerApi extends BaseAPI {
    /**
     * 
     * @summary fetchDashboardMetrics
     * @param {SearchParameters5} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashBoardMetricsControllerApi
     */
    public fetchDashboardMetricsUsingPOST(searchParameters: SearchParameters5, options?: any) {
        return DashBoardMetricsControllerApiFp(this.configuration).fetchDashboardMetricsUsingPOST(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MerchantDisputeControllerApi - axios parameter creator
 * @export
 */
export const MerchantDisputeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addComment
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentUsingPOST2: async (disputeId: string, request: ApiCommentRequest1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            if (disputeId === null || disputeId === undefined) {
                throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling addCommentUsingPOST2.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling addCommentUsingPOST2.');
            }
            const localVarPath = `/merchant-portal/dispute/{disputeId}/comment`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary approveChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiDisputeChargebackRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBackUsingPUT1: async (disputeId: string, request: ApiDisputeChargebackRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            if (disputeId === null || disputeId === undefined) {
                throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling approveChargeBackUsingPUT1.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling approveChargeBackUsingPUT1.');
            }
            const localVarPath = `/merchant-portal/dispute/{disputeId}/accept`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getComments
         * @param {string} disputeId disputeId
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsUsingPOST2: async (disputeId: string, searchParameters: SearchParameters, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            if (disputeId === null || disputeId === undefined) {
                throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling getCommentsUsingPOST2.');
            }
            // verify required parameter 'searchParameters' is not null or undefined
            if (searchParameters === null || searchParameters === undefined) {
                throw new RequiredError('searchParameters','Required parameter searchParameters was null or undefined when calling getCommentsUsingPOST2.');
            }
            const localVarPath = `/merchant-portal/dispute/{disputeId}/comments`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchParameters !== undefined ? searchParameters : {}) : (searchParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rejectChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiDisputeChargebackRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectChargeBackUsingPUT1: async (disputeId: string, request: ApiDisputeChargebackRequest1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            if (disputeId === null || disputeId === undefined) {
                throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling rejectChargeBackUsingPUT1.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling rejectChargeBackUsingPUT1.');
            }
            const localVarPath = `/merchant-portal/dispute/{disputeId}/reject`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantDisputeControllerApi - functional programming interface
 * @export
 */
export const MerchantDisputeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addComment
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCommentUsingPOST2(disputeId: string, request: ApiCommentRequest1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantDisputeControllerApiAxiosParamCreator(configuration).addCommentUsingPOST2(disputeId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary approveChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiDisputeChargebackRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveChargeBackUsingPUT1(disputeId: string, request: ApiDisputeChargebackRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantDisputeControllerApiAxiosParamCreator(configuration).approveChargeBackUsingPUT1(disputeId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getComments
         * @param {string} disputeId disputeId
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsUsingPOST2(disputeId: string, searchParameters: SearchParameters, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiComment>> {
            const localVarAxiosArgs = await MerchantDisputeControllerApiAxiosParamCreator(configuration).getCommentsUsingPOST2(disputeId, searchParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary rejectChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiDisputeChargebackRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectChargeBackUsingPUT1(disputeId: string, request: ApiDisputeChargebackRequest1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantDisputeControllerApiAxiosParamCreator(configuration).rejectChargeBackUsingPUT1(disputeId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MerchantDisputeControllerApi - factory interface
 * @export
 */
export const MerchantDisputeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary addComment
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentUsingPOST2(disputeId: string, request: ApiCommentRequest1, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantDisputeControllerApiFp(configuration).addCommentUsingPOST2(disputeId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary approveChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiDisputeChargebackRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBackUsingPUT1(disputeId: string, request: ApiDisputeChargebackRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantDisputeControllerApiFp(configuration).approveChargeBackUsingPUT1(disputeId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getComments
         * @param {string} disputeId disputeId
         * @param {SearchParameters} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsUsingPOST2(disputeId: string, searchParameters: SearchParameters, options?: any): AxiosPromise<ApiResponsePageApiComment> {
            return MerchantDisputeControllerApiFp(configuration).getCommentsUsingPOST2(disputeId, searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary rejectChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiDisputeChargebackRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectChargeBackUsingPUT1(disputeId: string, request: ApiDisputeChargebackRequest1, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantDisputeControllerApiFp(configuration).rejectChargeBackUsingPUT1(disputeId, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantDisputeControllerApi - object-oriented interface
 * @export
 * @class MerchantDisputeControllerApi
 * @extends {BaseAPI}
 */
export class MerchantDisputeControllerApi extends BaseAPI {
    /**
     * 
     * @summary addComment
     * @param {string} disputeId disputeId
     * @param {ApiCommentRequest1} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantDisputeControllerApi
     */
    public addCommentUsingPOST2(disputeId: string, request: ApiCommentRequest1, options?: any) {
        return MerchantDisputeControllerApiFp(this.configuration).addCommentUsingPOST2(disputeId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary approveChargeBack
     * @param {string} disputeId disputeId
     * @param {ApiDisputeChargebackRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantDisputeControllerApi
     */
    public approveChargeBackUsingPUT1(disputeId: string, request: ApiDisputeChargebackRequest, options?: any) {
        return MerchantDisputeControllerApiFp(this.configuration).approveChargeBackUsingPUT1(disputeId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getComments
     * @param {string} disputeId disputeId
     * @param {SearchParameters} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantDisputeControllerApi
     */
    public getCommentsUsingPOST2(disputeId: string, searchParameters: SearchParameters, options?: any) {
        return MerchantDisputeControllerApiFp(this.configuration).getCommentsUsingPOST2(disputeId, searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary rejectChargeBack
     * @param {string} disputeId disputeId
     * @param {ApiDisputeChargebackRequest1} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantDisputeControllerApi
     */
    public rejectChargeBackUsingPUT1(disputeId: string, request: ApiDisputeChargebackRequest1, options?: any) {
        return MerchantDisputeControllerApiFp(this.configuration).rejectChargeBackUsingPUT1(disputeId, request, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MerchantManagementControllerApi - axios parameter creator
 * @export
 */
export const MerchantManagementControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getMerchantProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantProfileUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant-portal/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantManagementControllerApi - functional programming interface
 * @export
 */
export const MerchantManagementControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getMerchantProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMerchantProfileUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiMerchant>> {
            const localVarAxiosArgs = await MerchantManagementControllerApiAxiosParamCreator(configuration).getMerchantProfileUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MerchantManagementControllerApi - factory interface
 * @export
 */
export const MerchantManagementControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getMerchantProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantProfileUsingGET(options?: any): AxiosPromise<ApiResponseApiMerchant> {
            return MerchantManagementControllerApiFp(configuration).getMerchantProfileUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantManagementControllerApi - object-oriented interface
 * @export
 * @class MerchantManagementControllerApi
 * @extends {BaseAPI}
 */
export class MerchantManagementControllerApi extends BaseAPI {
    /**
     * 
     * @summary getMerchantProfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantManagementControllerApi
     */
    public getMerchantProfileUsingGET(options?: any) {
        return MerchantManagementControllerApiFp(this.configuration).getMerchantProfileUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MerchantPortalDisputeControllerApi - axios parameter creator
 * @export
 */
export const MerchantPortalDisputeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addComment
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest3} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentUsingPOST3: async (disputeId: string, request: ApiCommentRequest3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            if (disputeId === null || disputeId === undefined) {
                throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling addCommentUsingPOST3.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling addCommentUsingPOST3.');
            }
            const localVarPath = `/merchant-portal/disputes/{disputeId}/comment`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary approveChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBackUsingPUT2: async (disputeId: string, request: ApiCommentRequest2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            if (disputeId === null || disputeId === undefined) {
                throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling approveChargeBackUsingPUT2.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling approveChargeBackUsingPUT2.');
            }
            const localVarPath = `/merchant-portal/disputes/{disputeId}/accept`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getComments
         * @param {string} disputeId disputeId
         * @param {SearchParameters2} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsUsingPOST3: async (disputeId: string, searchParameters: SearchParameters2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            if (disputeId === null || disputeId === undefined) {
                throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling getCommentsUsingPOST3.');
            }
            // verify required parameter 'searchParameters' is not null or undefined
            if (searchParameters === null || searchParameters === undefined) {
                throw new RequiredError('searchParameters','Required parameter searchParameters was null or undefined when calling getCommentsUsingPOST3.');
            }
            const localVarPath = `/merchant-portal/disputes/{disputeId}/comments`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchParameters !== undefined ? searchParameters : {}) : (searchParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rejectChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest4} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectChargeBackUsingPUT2: async (disputeId: string, request: ApiCommentRequest4, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'disputeId' is not null or undefined
            if (disputeId === null || disputeId === undefined) {
                throw new RequiredError('disputeId','Required parameter disputeId was null or undefined when calling rejectChargeBackUsingPUT2.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling rejectChargeBackUsingPUT2.');
            }
            const localVarPath = `/merchant-portal/disputes/{disputeId}/reject`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveAttachment
         * @param {ApiAttachmentRequestReq1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAttachmentUsingPOST2: async (request: ApiAttachmentRequestReq1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling retrieveAttachmentUsingPOST2.');
            }
            const localVarPath = `/merchant-portal/disputes/retrieve-attachment`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchMerchantDisputes
         * @param {SearchParameters1} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMerchantDisputesUsingPOST1: async (searchParameters: SearchParameters1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            if (searchParameters === null || searchParameters === undefined) {
                throw new RequiredError('searchParameters','Required parameter searchParameters was null or undefined when calling searchMerchantDisputesUsingPOST1.');
            }
            const localVarPath = `/merchant-portal/disputes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchParameters !== undefined ? searchParameters : {}) : (searchParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadAttachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAttachmentUsingPOST2: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant-portal/disputes/upload-attachment`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantPortalDisputeControllerApi - functional programming interface
 * @export
 */
export const MerchantPortalDisputeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addComment
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest3} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCommentUsingPOST3(disputeId: string, request: ApiCommentRequest3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantPortalDisputeControllerApiAxiosParamCreator(configuration).addCommentUsingPOST3(disputeId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary approveChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveChargeBackUsingPUT2(disputeId: string, request: ApiCommentRequest2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantPortalDisputeControllerApiAxiosParamCreator(configuration).approveChargeBackUsingPUT2(disputeId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getComments
         * @param {string} disputeId disputeId
         * @param {SearchParameters2} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsUsingPOST3(disputeId: string, searchParameters: SearchParameters2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiComment>> {
            const localVarAxiosArgs = await MerchantPortalDisputeControllerApiAxiosParamCreator(configuration).getCommentsUsingPOST3(disputeId, searchParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary rejectChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest4} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectChargeBackUsingPUT2(disputeId: string, request: ApiCommentRequest4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantPortalDisputeControllerApiAxiosParamCreator(configuration).rejectChargeBackUsingPUT2(disputeId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary retrieveAttachment
         * @param {ApiAttachmentRequestReq1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveAttachmentUsingPOST2(request: ApiAttachmentRequestReq1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await MerchantPortalDisputeControllerApiAxiosParamCreator(configuration).retrieveAttachmentUsingPOST2(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchMerchantDisputes
         * @param {SearchParameters1} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchMerchantDisputesUsingPOST1(searchParameters: SearchParameters1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiMerchantDispute>> {
            const localVarAxiosArgs = await MerchantPortalDisputeControllerApiAxiosParamCreator(configuration).searchMerchantDisputesUsingPOST1(searchParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary uploadAttachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAttachmentUsingPOST2(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiAttachmentRequest>> {
            const localVarAxiosArgs = await MerchantPortalDisputeControllerApiAxiosParamCreator(configuration).uploadAttachmentUsingPOST2(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MerchantPortalDisputeControllerApi - factory interface
 * @export
 */
export const MerchantPortalDisputeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary addComment
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest3} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCommentUsingPOST3(disputeId: string, request: ApiCommentRequest3, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantPortalDisputeControllerApiFp(configuration).addCommentUsingPOST3(disputeId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary approveChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBackUsingPUT2(disputeId: string, request: ApiCommentRequest2, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantPortalDisputeControllerApiFp(configuration).approveChargeBackUsingPUT2(disputeId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getComments
         * @param {string} disputeId disputeId
         * @param {SearchParameters2} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsUsingPOST3(disputeId: string, searchParameters: SearchParameters2, options?: any): AxiosPromise<ApiResponsePageApiComment> {
            return MerchantPortalDisputeControllerApiFp(configuration).getCommentsUsingPOST3(disputeId, searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary rejectChargeBack
         * @param {string} disputeId disputeId
         * @param {ApiCommentRequest4} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectChargeBackUsingPUT2(disputeId: string, request: ApiCommentRequest4, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantPortalDisputeControllerApiFp(configuration).rejectChargeBackUsingPUT2(disputeId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieveAttachment
         * @param {ApiAttachmentRequestReq1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAttachmentUsingPOST2(request: ApiAttachmentRequestReq1, options?: any): AxiosPromise<string> {
            return MerchantPortalDisputeControllerApiFp(configuration).retrieveAttachmentUsingPOST2(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchMerchantDisputes
         * @param {SearchParameters1} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMerchantDisputesUsingPOST1(searchParameters: SearchParameters1, options?: any): AxiosPromise<ApiResponsePageApiMerchantDispute> {
            return MerchantPortalDisputeControllerApiFp(configuration).searchMerchantDisputesUsingPOST1(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary uploadAttachment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAttachmentUsingPOST2(options?: any): AxiosPromise<ApiResponseApiAttachmentRequest> {
            return MerchantPortalDisputeControllerApiFp(configuration).uploadAttachmentUsingPOST2(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantPortalDisputeControllerApi - object-oriented interface
 * @export
 * @class MerchantPortalDisputeControllerApi
 * @extends {BaseAPI}
 */
export class MerchantPortalDisputeControllerApi extends BaseAPI {
    /**
     * 
     * @summary addComment
     * @param {string} disputeId disputeId
     * @param {ApiCommentRequest3} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalDisputeControllerApi
     */
    public addCommentUsingPOST3(disputeId: string, request: ApiCommentRequest3, options?: any) {
        return MerchantPortalDisputeControllerApiFp(this.configuration).addCommentUsingPOST3(disputeId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary approveChargeBack
     * @param {string} disputeId disputeId
     * @param {ApiCommentRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalDisputeControllerApi
     */
    public approveChargeBackUsingPUT2(disputeId: string, request: ApiCommentRequest2, options?: any) {
        return MerchantPortalDisputeControllerApiFp(this.configuration).approveChargeBackUsingPUT2(disputeId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getComments
     * @param {string} disputeId disputeId
     * @param {SearchParameters2} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalDisputeControllerApi
     */
    public getCommentsUsingPOST3(disputeId: string, searchParameters: SearchParameters2, options?: any) {
        return MerchantPortalDisputeControllerApiFp(this.configuration).getCommentsUsingPOST3(disputeId, searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary rejectChargeBack
     * @param {string} disputeId disputeId
     * @param {ApiCommentRequest4} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalDisputeControllerApi
     */
    public rejectChargeBackUsingPUT2(disputeId: string, request: ApiCommentRequest4, options?: any) {
        return MerchantPortalDisputeControllerApiFp(this.configuration).rejectChargeBackUsingPUT2(disputeId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieveAttachment
     * @param {ApiAttachmentRequestReq1} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalDisputeControllerApi
     */
    public retrieveAttachmentUsingPOST2(request: ApiAttachmentRequestReq1, options?: any) {
        return MerchantPortalDisputeControllerApiFp(this.configuration).retrieveAttachmentUsingPOST2(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchMerchantDisputes
     * @param {SearchParameters1} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalDisputeControllerApi
     */
    public searchMerchantDisputesUsingPOST1(searchParameters: SearchParameters1, options?: any) {
        return MerchantPortalDisputeControllerApiFp(this.configuration).searchMerchantDisputesUsingPOST1(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary uploadAttachment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalDisputeControllerApi
     */
    public uploadAttachmentUsingPOST2(options?: any) {
        return MerchantPortalDisputeControllerApiFp(this.configuration).uploadAttachmentUsingPOST2(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MerchantPortalReportControllerApi - axios parameter creator
 * @export
 */
export const MerchantPortalReportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getSettlementSummaryList
         * @param {ApiTimeRange} request 
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettlementSummaryListUsingPOST1: async (request: ApiTimeRange, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling getSettlementSummaryListUsingPOST1.');
            }
            const localVarPath = `/merchant-portal/settlement-summary`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantPortalReportControllerApi - functional programming interface
 * @export
 */
export const MerchantPortalReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getSettlementSummaryList
         * @param {ApiTimeRange} request 
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettlementSummaryListUsingPOST1(request: ApiTimeRange, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiMerchantSettlementSummaryResponse>> {
            const localVarAxiosArgs = await MerchantPortalReportControllerApiAxiosParamCreator(configuration).getSettlementSummaryListUsingPOST1(request, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MerchantPortalReportControllerApi - factory interface
 * @export
 */
export const MerchantPortalReportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary getSettlementSummaryList
         * @param {ApiTimeRange} request 
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettlementSummaryListUsingPOST1(request: ApiTimeRange, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<ApiResponsePageApiMerchantSettlementSummaryResponse> {
            return MerchantPortalReportControllerApiFp(configuration).getSettlementSummaryListUsingPOST1(request, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantPortalReportControllerApi - object-oriented interface
 * @export
 * @class MerchantPortalReportControllerApi
 * @extends {BaseAPI}
 */
export class MerchantPortalReportControllerApi extends BaseAPI {
    /**
     * 
     * @summary getSettlementSummaryList
     * @param {ApiTimeRange} request 
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalReportControllerApi
     */
    public getSettlementSummaryListUsingPOST1(request: ApiTimeRange, page?: number, size?: number, sort?: string, options?: any) {
        return MerchantPortalReportControllerApiFp(this.configuration).getSettlementSummaryListUsingPOST1(request, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MerchantPortalTransactionControllerApi - axios parameter creator
 * @export
 */
export const MerchantPortalTransactionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cancelMerchantTransaction
         * @param {string} paymentId paymentId
         * @param {ApiReversalRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMerchantTransactionUsingPOST: async (paymentId: string, request: ApiReversalRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling cancelMerchantTransactionUsingPOST.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling cancelMerchantTransactionUsingPOST.');
            }
            const localVarPath = `/merchant-portal/transactions/{paymentId}/cancel`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMerchantTransactionDetail
         * @param {string} bakktTransactionId bakktTransactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantTransactionDetailUsingGET: async (bakktTransactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bakktTransactionId' is not null or undefined
            if (bakktTransactionId === null || bakktTransactionId === undefined) {
                throw new RequiredError('bakktTransactionId','Required parameter bakktTransactionId was null or undefined when calling getMerchantTransactionDetailUsingGET.');
            }
            const localVarPath = `/merchant-portal/transactions/{bakktTransactionId}`
                .replace(`{${"bakktTransactionId"}}`, encodeURIComponent(String(bakktTransactionId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMerchantTransactions
         * @param {SearchParameters3} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantTransactionsUsingPOST: async (searchParameters: SearchParameters3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            if (searchParameters === null || searchParameters === undefined) {
                throw new RequiredError('searchParameters','Required parameter searchParameters was null or undefined when calling getMerchantTransactionsUsingPOST.');
            }
            const localVarPath = `/merchant-portal/transactions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchParameters !== undefined ? searchParameters : {}) : (searchParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reverseMerchantTransaction
         * @param {string} paymentId paymentId
         * @param {ApiReversalRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reverseMerchantTransactionUsingPOST: async (paymentId: string, request: ApiReversalRequest1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentId' is not null or undefined
            if (paymentId === null || paymentId === undefined) {
                throw new RequiredError('paymentId','Required parameter paymentId was null or undefined when calling reverseMerchantTransactionUsingPOST.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling reverseMerchantTransactionUsingPOST.');
            }
            const localVarPath = `/merchant-portal/transactions/{paymentId}/reverse`
                .replace(`{${"paymentId"}}`, encodeURIComponent(String(paymentId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantPortalTransactionControllerApi - functional programming interface
 * @export
 */
export const MerchantPortalTransactionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cancelMerchantTransaction
         * @param {string} paymentId paymentId
         * @param {ApiReversalRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelMerchantTransactionUsingPOST(paymentId: string, request: ApiReversalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MerchantPortalTransactionControllerApiAxiosParamCreator(configuration).cancelMerchantTransactionUsingPOST(paymentId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getMerchantTransactionDetail
         * @param {string} bakktTransactionId bakktTransactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMerchantTransactionDetailUsingGET(bakktTransactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiMerchantPortalTransactionDetail>> {
            const localVarAxiosArgs = await MerchantPortalTransactionControllerApiAxiosParamCreator(configuration).getMerchantTransactionDetailUsingGET(bakktTransactionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getMerchantTransactions
         * @param {SearchParameters3} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMerchantTransactionsUsingPOST(searchParameters: SearchParameters3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiMerchantPortalTransaction>> {
            const localVarAxiosArgs = await MerchantPortalTransactionControllerApiAxiosParamCreator(configuration).getMerchantTransactionsUsingPOST(searchParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary reverseMerchantTransaction
         * @param {string} paymentId paymentId
         * @param {ApiReversalRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reverseMerchantTransactionUsingPOST(paymentId: string, request: ApiReversalRequest1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MerchantPortalTransactionControllerApiAxiosParamCreator(configuration).reverseMerchantTransactionUsingPOST(paymentId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MerchantPortalTransactionControllerApi - factory interface
 * @export
 */
export const MerchantPortalTransactionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary cancelMerchantTransaction
         * @param {string} paymentId paymentId
         * @param {ApiReversalRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMerchantTransactionUsingPOST(paymentId: string, request: ApiReversalRequest, options?: any): AxiosPromise<void> {
            return MerchantPortalTransactionControllerApiFp(configuration).cancelMerchantTransactionUsingPOST(paymentId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getMerchantTransactionDetail
         * @param {string} bakktTransactionId bakktTransactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantTransactionDetailUsingGET(bakktTransactionId: string, options?: any): AxiosPromise<ApiResponseApiMerchantPortalTransactionDetail> {
            return MerchantPortalTransactionControllerApiFp(configuration).getMerchantTransactionDetailUsingGET(bakktTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getMerchantTransactions
         * @param {SearchParameters3} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMerchantTransactionsUsingPOST(searchParameters: SearchParameters3, options?: any): AxiosPromise<ApiResponsePageApiMerchantPortalTransaction> {
            return MerchantPortalTransactionControllerApiFp(configuration).getMerchantTransactionsUsingPOST(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reverseMerchantTransaction
         * @param {string} paymentId paymentId
         * @param {ApiReversalRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reverseMerchantTransactionUsingPOST(paymentId: string, request: ApiReversalRequest1, options?: any): AxiosPromise<void> {
            return MerchantPortalTransactionControllerApiFp(configuration).reverseMerchantTransactionUsingPOST(paymentId, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantPortalTransactionControllerApi - object-oriented interface
 * @export
 * @class MerchantPortalTransactionControllerApi
 * @extends {BaseAPI}
 */
export class MerchantPortalTransactionControllerApi extends BaseAPI {
    /**
     * 
     * @summary cancelMerchantTransaction
     * @param {string} paymentId paymentId
     * @param {ApiReversalRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalTransactionControllerApi
     */
    public cancelMerchantTransactionUsingPOST(paymentId: string, request: ApiReversalRequest, options?: any) {
        return MerchantPortalTransactionControllerApiFp(this.configuration).cancelMerchantTransactionUsingPOST(paymentId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getMerchantTransactionDetail
     * @param {string} bakktTransactionId bakktTransactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalTransactionControllerApi
     */
    public getMerchantTransactionDetailUsingGET(bakktTransactionId: string, options?: any) {
        return MerchantPortalTransactionControllerApiFp(this.configuration).getMerchantTransactionDetailUsingGET(bakktTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getMerchantTransactions
     * @param {SearchParameters3} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalTransactionControllerApi
     */
    public getMerchantTransactionsUsingPOST(searchParameters: SearchParameters3, options?: any) {
        return MerchantPortalTransactionControllerApiFp(this.configuration).getMerchantTransactionsUsingPOST(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reverseMerchantTransaction
     * @param {string} paymentId paymentId
     * @param {ApiReversalRequest1} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantPortalTransactionControllerApi
     */
    public reverseMerchantTransactionUsingPOST(paymentId: string, request: ApiReversalRequest1, options?: any) {
        return MerchantPortalTransactionControllerApiFp(this.configuration).reverseMerchantTransactionUsingPOST(paymentId, request, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MerchantUserRoleManagementControllerApi - axios parameter creator
 * @export
 */
export const MerchantUserRoleManagementControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addMerchantOwner
         * @param {CreateMerchantOwner} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMerchantOwnerUsingPOST: async (user: CreateMerchantOwner, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling addMerchantOwnerUsingPOST.');
            }
            const localVarPath = `/merchant-portal/users/owner`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addOrUpdateUser
         * @param {CreateMerchantUserRequest} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUserUsingPOST1: async (user: CreateMerchantUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling addOrUpdateUserUsingPOST1.');
            }
            const localVarPath = `/merchant-portal/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createRoleWithPermissions
         * @param {ApiCreateMerchantRole} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleWithPermissionsUsingPOST1: async (createRoleRequest: ApiCreateMerchantRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleRequest' is not null or undefined
            if (createRoleRequest === null || createRoleRequest === undefined) {
                throw new RequiredError('createRoleRequest','Required parameter createRoleRequest was null or undefined when calling createRoleWithPermissionsUsingPOST1.');
            }
            const localVarPath = `/merchant-portal/role`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createRoleRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createRoleRequest !== undefined ? createRoleRequest : {}) : (createRoleRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deactivateUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUserUsingPOST: async (fusionAuthRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            if (fusionAuthRef === null || fusionAuthRef === undefined) {
                throw new RequiredError('fusionAuthRef','Required parameter fusionAuthRef was null or undefined when calling deactivateUserUsingPOST.');
            }
            const localVarPath = `/merchant-portal/users/{fusionAuthRef}/deactivate`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPortalUserDetail
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUserDetailUsingGET1: async (fusionAuthRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            if (fusionAuthRef === null || fusionAuthRef === undefined) {
                throw new RequiredError('fusionAuthRef','Required parameter fusionAuthRef was null or undefined when calling getPortalUserDetailUsingGET1.');
            }
            const localVarPath = `/merchant-portal/users/{fusionAuthRef}`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPortalUsers
         * @param {SearchParameters4} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUsersUsingPOST1: async (searchParameters: SearchParameters4, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            if (searchParameters === null || searchParameters === undefined) {
                throw new RequiredError('searchParameters','Required parameter searchParameters was null or undefined when calling getPortalUsersUsingPOST1.');
            }
            const localVarPath = `/merchant-portal/users/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchParameters !== undefined ? searchParameters : {}) : (searchParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRoleList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleListUsingGET1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant-portal/role`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getUserSessionInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSessionInfoUsingGET1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/merchant-portal/users/current`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modifyUserRoles
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserRolesUsingPOST1: async (fusionAuthRef: string, updateUserRequest: UpdateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            if (fusionAuthRef === null || fusionAuthRef === undefined) {
                throw new RequiredError('fusionAuthRef','Required parameter fusionAuthRef was null or undefined when calling modifyUserRolesUsingPOST1.');
            }
            // verify required parameter 'updateUserRequest' is not null or undefined
            if (updateUserRequest === null || updateUserRequest === undefined) {
                throw new RequiredError('updateUserRequest','Required parameter updateUserRequest was null or undefined when calling modifyUserRolesUsingPOST1.');
            }
            const localVarPath = `/merchant-portal/users/{fusionAuthRef}`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateUserRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUserRequest !== undefined ? updateUserRequest : {}) : (updateUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reactivateUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateUserUsingPOST1: async (fusionAuthRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            if (fusionAuthRef === null || fusionAuthRef === undefined) {
                throw new RequiredError('fusionAuthRef','Required parameter fusionAuthRef was null or undefined when calling reactivateUserUsingPOST1.');
            }
            const localVarPath = `/merchant-portal/users/{fusionAuthRef}/reactivate`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sendInvite
         * @param {InvitePortalUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteUsingPOST1: async (request: InvitePortalUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling sendInviteUsingPOST1.');
            }
            const localVarPath = `/merchant-portal/users/invite`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateRoleWithPermissions
         * @param {ApiMerchantUpdateRole} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleWithPermissionsUsingPUT1: async (updateRoleRequest: ApiMerchantUpdateRole, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRoleRequest' is not null or undefined
            if (updateRoleRequest === null || updateRoleRequest === undefined) {
                throw new RequiredError('updateRoleRequest','Required parameter updateRoleRequest was null or undefined when calling updateRoleWithPermissionsUsingPUT1.');
            }
            const localVarPath = `/merchant-portal/role`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateRoleRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateRoleRequest !== undefined ? updateRoleRequest : {}) : (updateRoleRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MerchantUserRoleManagementControllerApi - functional programming interface
 * @export
 */
export const MerchantUserRoleManagementControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addMerchantOwner
         * @param {CreateMerchantOwner} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMerchantOwnerUsingPOST(user: CreateMerchantOwner, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).addMerchantOwnerUsingPOST(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary addOrUpdateUser
         * @param {CreateMerchantUserRequest} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateUserUsingPOST1(user: CreateMerchantUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).addOrUpdateUserUsingPOST1(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary createRoleWithPermissions
         * @param {ApiCreateMerchantRole} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoleWithPermissionsUsingPOST1(createRoleRequest: ApiCreateMerchantRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).createRoleWithPermissionsUsingPOST1(createRoleRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deactivateUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateUserUsingPOST(fusionAuthRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).deactivateUserUsingPOST(fusionAuthRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getPortalUserDetail
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortalUserDetailUsingGET1(fusionAuthRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiMerchantPortalUser>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).getPortalUserDetailUsingGET1(fusionAuthRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getPortalUsers
         * @param {SearchParameters4} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortalUsersUsingPOST1(searchParameters: SearchParameters4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiMerchantPortalUser>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).getPortalUsersUsingPOST1(searchParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getRoleList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleListUsingGET1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiMerchantPortalRole>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).getRoleListUsingGET1(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getUserSessionInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSessionInfoUsingGET1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMerchantUserInfo>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).getUserSessionInfoUsingGET1(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary modifyUserRoles
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyUserRolesUsingPOST1(fusionAuthRef: string, updateUserRequest: UpdateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).modifyUserRolesUsingPOST1(fusionAuthRef, updateUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary reactivateUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactivateUserUsingPOST1(fusionAuthRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).reactivateUserUsingPOST1(fusionAuthRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sendInvite
         * @param {InvitePortalUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInviteUsingPOST1(request: InvitePortalUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).sendInviteUsingPOST1(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateRoleWithPermissions
         * @param {ApiMerchantUpdateRole} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoleWithPermissionsUsingPUT1(updateRoleRequest: ApiMerchantUpdateRole, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await MerchantUserRoleManagementControllerApiAxiosParamCreator(configuration).updateRoleWithPermissionsUsingPUT1(updateRoleRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MerchantUserRoleManagementControllerApi - factory interface
 * @export
 */
export const MerchantUserRoleManagementControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary addMerchantOwner
         * @param {CreateMerchantOwner} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMerchantOwnerUsingPOST(user: CreateMerchantOwner, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantUserRoleManagementControllerApiFp(configuration).addMerchantOwnerUsingPOST(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary addOrUpdateUser
         * @param {CreateMerchantUserRequest} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUserUsingPOST1(user: CreateMerchantUserRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantUserRoleManagementControllerApiFp(configuration).addOrUpdateUserUsingPOST1(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createRoleWithPermissions
         * @param {ApiCreateMerchantRole} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleWithPermissionsUsingPOST1(createRoleRequest: ApiCreateMerchantRole, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantUserRoleManagementControllerApiFp(configuration).createRoleWithPermissionsUsingPOST1(createRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deactivateUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateUserUsingPOST(fusionAuthRef: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantUserRoleManagementControllerApiFp(configuration).deactivateUserUsingPOST(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPortalUserDetail
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUserDetailUsingGET1(fusionAuthRef: string, options?: any): AxiosPromise<ApiResponseApiMerchantPortalUser> {
            return MerchantUserRoleManagementControllerApiFp(configuration).getPortalUserDetailUsingGET1(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPortalUsers
         * @param {SearchParameters4} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUsersUsingPOST1(searchParameters: SearchParameters4, options?: any): AxiosPromise<ApiResponsePageApiMerchantPortalUser> {
            return MerchantUserRoleManagementControllerApiFp(configuration).getPortalUsersUsingPOST1(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getRoleList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleListUsingGET1(options?: any): AxiosPromise<ApiResponseListApiMerchantPortalRole> {
            return MerchantUserRoleManagementControllerApiFp(configuration).getRoleListUsingGET1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getUserSessionInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSessionInfoUsingGET1(options?: any): AxiosPromise<ApiMerchantUserInfo> {
            return MerchantUserRoleManagementControllerApiFp(configuration).getUserSessionInfoUsingGET1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary modifyUserRoles
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {UpdateUserRequest} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserRolesUsingPOST1(fusionAuthRef: string, updateUserRequest: UpdateUserRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantUserRoleManagementControllerApiFp(configuration).modifyUserRolesUsingPOST1(fusionAuthRef, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reactivateUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateUserUsingPOST1(fusionAuthRef: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantUserRoleManagementControllerApiFp(configuration).reactivateUserUsingPOST1(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sendInvite
         * @param {InvitePortalUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteUsingPOST1(request: InvitePortalUserRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantUserRoleManagementControllerApiFp(configuration).sendInviteUsingPOST1(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateRoleWithPermissions
         * @param {ApiMerchantUpdateRole} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleWithPermissionsUsingPUT1(updateRoleRequest: ApiMerchantUpdateRole, options?: any): AxiosPromise<ApiResponseVoid> {
            return MerchantUserRoleManagementControllerApiFp(configuration).updateRoleWithPermissionsUsingPUT1(updateRoleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MerchantUserRoleManagementControllerApi - object-oriented interface
 * @export
 * @class MerchantUserRoleManagementControllerApi
 * @extends {BaseAPI}
 */
export class MerchantUserRoleManagementControllerApi extends BaseAPI {
    /**
     * 
     * @summary addMerchantOwner
     * @param {CreateMerchantOwner} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public addMerchantOwnerUsingPOST(user: CreateMerchantOwner, options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).addMerchantOwnerUsingPOST(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary addOrUpdateUser
     * @param {CreateMerchantUserRequest} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public addOrUpdateUserUsingPOST1(user: CreateMerchantUserRequest, options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).addOrUpdateUserUsingPOST1(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createRoleWithPermissions
     * @param {ApiCreateMerchantRole} createRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public createRoleWithPermissionsUsingPOST1(createRoleRequest: ApiCreateMerchantRole, options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).createRoleWithPermissionsUsingPOST1(createRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deactivateUser
     * @param {string} fusionAuthRef fusionAuthRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public deactivateUserUsingPOST(fusionAuthRef: string, options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).deactivateUserUsingPOST(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPortalUserDetail
     * @param {string} fusionAuthRef fusionAuthRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public getPortalUserDetailUsingGET1(fusionAuthRef: string, options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).getPortalUserDetailUsingGET1(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPortalUsers
     * @param {SearchParameters4} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public getPortalUsersUsingPOST1(searchParameters: SearchParameters4, options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).getPortalUsersUsingPOST1(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getRoleList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public getRoleListUsingGET1(options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).getRoleListUsingGET1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getUserSessionInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public getUserSessionInfoUsingGET1(options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).getUserSessionInfoUsingGET1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary modifyUserRoles
     * @param {string} fusionAuthRef fusionAuthRef
     * @param {UpdateUserRequest} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public modifyUserRolesUsingPOST1(fusionAuthRef: string, updateUserRequest: UpdateUserRequest, options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).modifyUserRolesUsingPOST1(fusionAuthRef, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reactivateUser
     * @param {string} fusionAuthRef fusionAuthRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public reactivateUserUsingPOST1(fusionAuthRef: string, options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).reactivateUserUsingPOST1(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sendInvite
     * @param {InvitePortalUserRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public sendInviteUsingPOST1(request: InvitePortalUserRequest, options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).sendInviteUsingPOST1(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateRoleWithPermissions
     * @param {ApiMerchantUpdateRole} updateRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantUserRoleManagementControllerApi
     */
    public updateRoleWithPermissionsUsingPUT1(updateRoleRequest: ApiMerchantUpdateRole, options?: any) {
        return MerchantUserRoleManagementControllerApiFp(this.configuration).updateRoleWithPermissionsUsingPUT1(updateRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * MetricsEntityApi - axios parameter creator
 * @export
 */
export const MetricsEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteMetrics
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMetricsUsingDELETE: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMetricsUsingDELETE.');
            }
            const localVarPath = `/metricses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllMetrics
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllMetricsUsingGET: async (page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/metricses`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdMetrics
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdMetricsUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdMetricsUsingGET.');
            }
            const localVarPath = `/metricses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveMetrics
         * @param {string} id id
         * @param {Metrics3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMetricsUsingPATCH: async (id: string, body: Metrics3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveMetricsUsingPATCH.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveMetricsUsingPATCH.');
            }
            const localVarPath = `/metricses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveMetrics
         * @param {Metrics1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMetricsUsingPOST: async (body: Metrics1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveMetricsUsingPOST.');
            }
            const localVarPath = `/metricses`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveMetrics
         * @param {string} id id
         * @param {Metrics2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMetricsUsingPUT: async (id: string, body: Metrics2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveMetricsUsingPUT.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveMetricsUsingPUT.');
            }
            const localVarPath = `/metricses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsEntityApi - functional programming interface
 * @export
 */
export const MetricsEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteMetrics
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMetricsUsingDELETE(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MetricsEntityApiAxiosParamCreator(configuration).deleteMetricsUsingDELETE(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findAllMetrics
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllMetricsUsingGET(page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelMetrics>> {
            const localVarAxiosArgs = await MetricsEntityApiAxiosParamCreator(configuration).findAllMetricsUsingGET(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByIdMetrics
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIdMetricsUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelMetrics>> {
            const localVarAxiosArgs = await MetricsEntityApiAxiosParamCreator(configuration).findByIdMetricsUsingGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveMetrics
         * @param {string} id id
         * @param {Metrics3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMetricsUsingPATCH(id: string, body: Metrics3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelMetrics>> {
            const localVarAxiosArgs = await MetricsEntityApiAxiosParamCreator(configuration).saveMetricsUsingPATCH(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveMetrics
         * @param {Metrics1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMetricsUsingPOST(body: Metrics1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelMetrics>> {
            const localVarAxiosArgs = await MetricsEntityApiAxiosParamCreator(configuration).saveMetricsUsingPOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveMetrics
         * @param {string} id id
         * @param {Metrics2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMetricsUsingPUT(id: string, body: Metrics2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelMetrics>> {
            const localVarAxiosArgs = await MetricsEntityApiAxiosParamCreator(configuration).saveMetricsUsingPUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MetricsEntityApi - factory interface
 * @export
 */
export const MetricsEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary deleteMetrics
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMetricsUsingDELETE(id: string, options?: any): AxiosPromise<void> {
            return MetricsEntityApiFp(configuration).deleteMetricsUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findAllMetrics
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllMetricsUsingGET(page?: number, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelMetrics> {
            return MetricsEntityApiFp(configuration).findAllMetricsUsingGET(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByIdMetrics
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdMetricsUsingGET(id: string, options?: any): AxiosPromise<EntityModelMetrics> {
            return MetricsEntityApiFp(configuration).findByIdMetricsUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveMetrics
         * @param {string} id id
         * @param {Metrics3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMetricsUsingPATCH(id: string, body: Metrics3, options?: any): AxiosPromise<EntityModelMetrics> {
            return MetricsEntityApiFp(configuration).saveMetricsUsingPATCH(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveMetrics
         * @param {Metrics1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMetricsUsingPOST(body: Metrics1, options?: any): AxiosPromise<EntityModelMetrics> {
            return MetricsEntityApiFp(configuration).saveMetricsUsingPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveMetrics
         * @param {string} id id
         * @param {Metrics2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMetricsUsingPUT(id: string, body: Metrics2, options?: any): AxiosPromise<EntityModelMetrics> {
            return MetricsEntityApiFp(configuration).saveMetricsUsingPUT(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsEntityApi - object-oriented interface
 * @export
 * @class MetricsEntityApi
 * @extends {BaseAPI}
 */
export class MetricsEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteMetrics
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsEntityApi
     */
    public deleteMetricsUsingDELETE(id: string, options?: any) {
        return MetricsEntityApiFp(this.configuration).deleteMetricsUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findAllMetrics
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsEntityApi
     */
    public findAllMetricsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return MetricsEntityApiFp(this.configuration).findAllMetricsUsingGET(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByIdMetrics
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsEntityApi
     */
    public findByIdMetricsUsingGET(id: string, options?: any) {
        return MetricsEntityApiFp(this.configuration).findByIdMetricsUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveMetrics
     * @param {string} id id
     * @param {Metrics3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsEntityApi
     */
    public saveMetricsUsingPATCH(id: string, body: Metrics3, options?: any) {
        return MetricsEntityApiFp(this.configuration).saveMetricsUsingPATCH(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveMetrics
     * @param {Metrics1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsEntityApi
     */
    public saveMetricsUsingPOST(body: Metrics1, options?: any) {
        return MetricsEntityApiFp(this.configuration).saveMetricsUsingPOST(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveMetrics
     * @param {string} id id
     * @param {Metrics2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsEntityApi
     */
    public saveMetricsUsingPUT(id: string, body: Metrics2, options?: any) {
        return MetricsEntityApiFp(this.configuration).saveMetricsUsingPUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PartnerControllerApi - axios parameter creator
 * @export
 */
export const PartnerControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetchPartnerChildren
         * @param {PartnerRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerChildrenUsingPOST: async (request: PartnerRequest1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling fetchPartnerChildrenUsingPOST.');
            }
            const localVarPath = `/partner-portal/partner/child`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fetchPartnerDescendants
         * @param {PartnerRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerDescendantsUsingPOST: async (request: PartnerRequest2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling fetchPartnerDescendantsUsingPOST.');
            }
            const localVarPath = `/partner-portal/partner/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fetchPartnerDetails
         * @param {PartnerRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerDetailsUsingPOST: async (request: PartnerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling fetchPartnerDetailsUsingPOST.');
            }
            const localVarPath = `/partner-portal/partner`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerControllerApi - functional programming interface
 * @export
 */
export const PartnerControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetchPartnerChildren
         * @param {PartnerRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerChildrenUsingPOST(request: PartnerRequest1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListPartner>> {
            const localVarAxiosArgs = await PartnerControllerApiAxiosParamCreator(configuration).fetchPartnerChildrenUsingPOST(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary fetchPartnerDescendants
         * @param {PartnerRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerDescendantsUsingPOST(request: PartnerRequest2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListPartner>> {
            const localVarAxiosArgs = await PartnerControllerApiAxiosParamCreator(configuration).fetchPartnerDescendantsUsingPOST(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary fetchPartnerDetails
         * @param {PartnerRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartnerDetailsUsingPOST(request: PartnerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePartner>> {
            const localVarAxiosArgs = await PartnerControllerApiAxiosParamCreator(configuration).fetchPartnerDetailsUsingPOST(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PartnerControllerApi - factory interface
 * @export
 */
export const PartnerControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary fetchPartnerChildren
         * @param {PartnerRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerChildrenUsingPOST(request: PartnerRequest1, options?: any): AxiosPromise<ApiResponseListPartner> {
            return PartnerControllerApiFp(configuration).fetchPartnerChildrenUsingPOST(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary fetchPartnerDescendants
         * @param {PartnerRequest2} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerDescendantsUsingPOST(request: PartnerRequest2, options?: any): AxiosPromise<ApiResponseListPartner> {
            return PartnerControllerApiFp(configuration).fetchPartnerDescendantsUsingPOST(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary fetchPartnerDetails
         * @param {PartnerRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartnerDetailsUsingPOST(request: PartnerRequest, options?: any): AxiosPromise<ApiResponsePartner> {
            return PartnerControllerApiFp(configuration).fetchPartnerDetailsUsingPOST(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerControllerApi - object-oriented interface
 * @export
 * @class PartnerControllerApi
 * @extends {BaseAPI}
 */
export class PartnerControllerApi extends BaseAPI {
    /**
     * 
     * @summary fetchPartnerChildren
     * @param {PartnerRequest1} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public fetchPartnerChildrenUsingPOST(request: PartnerRequest1, options?: any) {
        return PartnerControllerApiFp(this.configuration).fetchPartnerChildrenUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary fetchPartnerDescendants
     * @param {PartnerRequest2} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public fetchPartnerDescendantsUsingPOST(request: PartnerRequest2, options?: any) {
        return PartnerControllerApiFp(this.configuration).fetchPartnerDescendantsUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary fetchPartnerDetails
     * @param {PartnerRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerControllerApi
     */
    public fetchPartnerDetailsUsingPOST(request: PartnerRequest, options?: any) {
        return PartnerControllerApiFp(this.configuration).fetchPartnerDetailsUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PartnerEntityApi - axios parameter creator
 * @export
 */
export const PartnerEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deletePartner
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerUsingDELETE: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePartnerUsingDELETE.');
            }
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllPartner
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPartnerUsingGET: async (page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdInPartner
         * @param {string} [partnerIds] partnerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdInPartnerUsingGET: async (partnerIds?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/search/findByIdIn`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerIds !== undefined) {
                localVarQueryParameter['partnerIds'] = partnerIds;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdPartner
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPartnerUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdPartnerUsingGET.');
            }
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByParentPartnerIdInPartner
         * @param {string} [parentPartnerId] parentPartnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByParentPartnerIdInPartnerUsingGET: async (parentPartnerId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/search/findByParentPartnerIdIn`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentPartnerId !== undefined) {
                localVarQueryParameter['parentPartnerId'] = parentPartnerId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findSubPartnersPartner
         * @param {string} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubPartnersPartnerUsingGET: async (partnerId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/search/findSubPartners`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveDescendantsPartner
         * @param {string} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDescendantsPartnerUsingGET: async (partnerId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partners/search/retrieveDescendants`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePartner
         * @param {string} id id
         * @param {Partner3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerUsingPATCH: async (id: string, body: Partner3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePartnerUsingPATCH.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartnerUsingPATCH.');
            }
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePartner
         * @param {Partner1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerUsingPOST: async (body: Partner1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartnerUsingPOST.');
            }
            const localVarPath = `/partners`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePartner
         * @param {string} id id
         * @param {Partner2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerUsingPUT: async (id: string, body: Partner2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePartnerUsingPUT.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartnerUsingPUT.');
            }
            const localVarPath = `/partners/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerEntityApi - functional programming interface
 * @export
 */
export const PartnerEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deletePartner
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartnerUsingDELETE(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PartnerEntityApiAxiosParamCreator(configuration).deletePartnerUsingDELETE(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findAllPartner
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllPartnerUsingGET(page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartner>> {
            const localVarAxiosArgs = await PartnerEntityApiAxiosParamCreator(configuration).findAllPartnerUsingGET(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByIdInPartner
         * @param {string} [partnerIds] partnerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIdInPartnerUsingGET(partnerIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartner>> {
            const localVarAxiosArgs = await PartnerEntityApiAxiosParamCreator(configuration).findByIdInPartnerUsingGET(partnerIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByIdPartner
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIdPartnerUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartner>> {
            const localVarAxiosArgs = await PartnerEntityApiAxiosParamCreator(configuration).findByIdPartnerUsingGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByParentPartnerIdInPartner
         * @param {string} [parentPartnerId] parentPartnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByParentPartnerIdInPartnerUsingGET(parentPartnerId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartner>> {
            const localVarAxiosArgs = await PartnerEntityApiAxiosParamCreator(configuration).findByParentPartnerIdInPartnerUsingGET(parentPartnerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findSubPartnersPartner
         * @param {string} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findSubPartnersPartnerUsingGET(partnerId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartner>> {
            const localVarAxiosArgs = await PartnerEntityApiAxiosParamCreator(configuration).findSubPartnersPartnerUsingGET(partnerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary retrieveDescendantsPartner
         * @param {string} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrieveDescendantsPartnerUsingGET(partnerId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelUuid>> {
            const localVarAxiosArgs = await PartnerEntityApiAxiosParamCreator(configuration).retrieveDescendantsPartnerUsingGET(partnerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary savePartner
         * @param {string} id id
         * @param {Partner3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartnerUsingPATCH(id: string, body: Partner3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartner>> {
            const localVarAxiosArgs = await PartnerEntityApiAxiosParamCreator(configuration).savePartnerUsingPATCH(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary savePartner
         * @param {Partner1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartnerUsingPOST(body: Partner1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartner>> {
            const localVarAxiosArgs = await PartnerEntityApiAxiosParamCreator(configuration).savePartnerUsingPOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary savePartner
         * @param {string} id id
         * @param {Partner2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartnerUsingPUT(id: string, body: Partner2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartner>> {
            const localVarAxiosArgs = await PartnerEntityApiAxiosParamCreator(configuration).savePartnerUsingPUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PartnerEntityApi - factory interface
 * @export
 */
export const PartnerEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary deletePartner
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerUsingDELETE(id: string, options?: any): AxiosPromise<void> {
            return PartnerEntityApiFp(configuration).deletePartnerUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findAllPartner
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPartnerUsingGET(page?: number, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelPartner> {
            return PartnerEntityApiFp(configuration).findAllPartnerUsingGET(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByIdInPartner
         * @param {string} [partnerIds] partnerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdInPartnerUsingGET(partnerIds?: string, options?: any): AxiosPromise<CollectionModelPartner> {
            return PartnerEntityApiFp(configuration).findByIdInPartnerUsingGET(partnerIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByIdPartner
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPartnerUsingGET(id: string, options?: any): AxiosPromise<EntityModelPartner> {
            return PartnerEntityApiFp(configuration).findByIdPartnerUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByParentPartnerIdInPartner
         * @param {string} [parentPartnerId] parentPartnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByParentPartnerIdInPartnerUsingGET(parentPartnerId?: string, options?: any): AxiosPromise<CollectionModelPartner> {
            return PartnerEntityApiFp(configuration).findByParentPartnerIdInPartnerUsingGET(parentPartnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findSubPartnersPartner
         * @param {string} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findSubPartnersPartnerUsingGET(partnerId?: string, options?: any): AxiosPromise<CollectionModelPartner> {
            return PartnerEntityApiFp(configuration).findSubPartnersPartnerUsingGET(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieveDescendantsPartner
         * @param {string} [partnerId] partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDescendantsPartnerUsingGET(partnerId?: string, options?: any): AxiosPromise<CollectionModelUuid> {
            return PartnerEntityApiFp(configuration).retrieveDescendantsPartnerUsingGET(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary savePartner
         * @param {string} id id
         * @param {Partner3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerUsingPATCH(id: string, body: Partner3, options?: any): AxiosPromise<EntityModelPartner> {
            return PartnerEntityApiFp(configuration).savePartnerUsingPATCH(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary savePartner
         * @param {Partner1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerUsingPOST(body: Partner1, options?: any): AxiosPromise<EntityModelPartner> {
            return PartnerEntityApiFp(configuration).savePartnerUsingPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary savePartner
         * @param {string} id id
         * @param {Partner2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerUsingPUT(id: string, body: Partner2, options?: any): AxiosPromise<EntityModelPartner> {
            return PartnerEntityApiFp(configuration).savePartnerUsingPUT(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerEntityApi - object-oriented interface
 * @export
 * @class PartnerEntityApi
 * @extends {BaseAPI}
 */
export class PartnerEntityApi extends BaseAPI {
    /**
     * 
     * @summary deletePartner
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEntityApi
     */
    public deletePartnerUsingDELETE(id: string, options?: any) {
        return PartnerEntityApiFp(this.configuration).deletePartnerUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findAllPartner
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEntityApi
     */
    public findAllPartnerUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return PartnerEntityApiFp(this.configuration).findAllPartnerUsingGET(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByIdInPartner
     * @param {string} [partnerIds] partnerIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEntityApi
     */
    public findByIdInPartnerUsingGET(partnerIds?: string, options?: any) {
        return PartnerEntityApiFp(this.configuration).findByIdInPartnerUsingGET(partnerIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByIdPartner
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEntityApi
     */
    public findByIdPartnerUsingGET(id: string, options?: any) {
        return PartnerEntityApiFp(this.configuration).findByIdPartnerUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByParentPartnerIdInPartner
     * @param {string} [parentPartnerId] parentPartnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEntityApi
     */
    public findByParentPartnerIdInPartnerUsingGET(parentPartnerId?: string, options?: any) {
        return PartnerEntityApiFp(this.configuration).findByParentPartnerIdInPartnerUsingGET(parentPartnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findSubPartnersPartner
     * @param {string} [partnerId] partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEntityApi
     */
    public findSubPartnersPartnerUsingGET(partnerId?: string, options?: any) {
        return PartnerEntityApiFp(this.configuration).findSubPartnersPartnerUsingGET(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieveDescendantsPartner
     * @param {string} [partnerId] partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEntityApi
     */
    public retrieveDescendantsPartnerUsingGET(partnerId?: string, options?: any) {
        return PartnerEntityApiFp(this.configuration).retrieveDescendantsPartnerUsingGET(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary savePartner
     * @param {string} id id
     * @param {Partner3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEntityApi
     */
    public savePartnerUsingPATCH(id: string, body: Partner3, options?: any) {
        return PartnerEntityApiFp(this.configuration).savePartnerUsingPATCH(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary savePartner
     * @param {Partner1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEntityApi
     */
    public savePartnerUsingPOST(body: Partner1, options?: any) {
        return PartnerEntityApiFp(this.configuration).savePartnerUsingPOST(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary savePartner
     * @param {string} id id
     * @param {Partner2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerEntityApi
     */
    public savePartnerUsingPUT(id: string, body: Partner2, options?: any) {
        return PartnerEntityApiFp(this.configuration).savePartnerUsingPUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PartnerPartyLinkEntityApi - axios parameter creator
 * @export
 */
export const PartnerPartyLinkEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deletePartnerPartyLink
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerPartyLinkUsingDELETE: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePartnerPartyLinkUsingDELETE.');
            }
            const localVarPath = `/partnerPartyLinks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllPartnerPartyLink
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPartnerPartyLinkUsingGET: async (page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partnerPartyLinks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdPartnerPartyLink
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPartnerPartyLinkUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdPartnerPartyLinkUsingGET.');
            }
            const localVarPath = `/partnerPartyLinks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByPartnerIdInPartnerPartyLink
         * @param {string} [partnerIds] partnerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPartnerIdInPartnerPartyLinkUsingGET: async (partnerIds?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partnerPartyLinks/search/findByPartnerIdIn`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerIds !== undefined) {
                localVarQueryParameter['partnerIds'] = partnerIds;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByPartyIdPartnerPartyLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPartyIdPartnerPartyLinkUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partnerPartyLinks/search/findByPartyId`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePartnerPartyLink
         * @param {string} id id
         * @param {PartnerPartyLink3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerPartyLinkUsingPATCH: async (id: string, body: PartnerPartyLink3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePartnerPartyLinkUsingPATCH.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartnerPartyLinkUsingPATCH.');
            }
            const localVarPath = `/partnerPartyLinks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePartnerPartyLink
         * @param {PartnerPartyLink1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerPartyLinkUsingPOST: async (body: PartnerPartyLink1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartnerPartyLinkUsingPOST.');
            }
            const localVarPath = `/partnerPartyLinks`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePartnerPartyLink
         * @param {string} id id
         * @param {PartnerPartyLink2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerPartyLinkUsingPUT: async (id: string, body: PartnerPartyLink2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePartnerPartyLinkUsingPUT.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartnerPartyLinkUsingPUT.');
            }
            const localVarPath = `/partnerPartyLinks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerPartyLinkEntityApi - functional programming interface
 * @export
 */
export const PartnerPartyLinkEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deletePartnerPartyLink
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartnerPartyLinkUsingDELETE(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PartnerPartyLinkEntityApiAxiosParamCreator(configuration).deletePartnerPartyLinkUsingDELETE(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findAllPartnerPartyLink
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllPartnerPartyLinkUsingGET(page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartnerPartyLink>> {
            const localVarAxiosArgs = await PartnerPartyLinkEntityApiAxiosParamCreator(configuration).findAllPartnerPartyLinkUsingGET(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByIdPartnerPartyLink
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIdPartnerPartyLinkUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerPartyLink>> {
            const localVarAxiosArgs = await PartnerPartyLinkEntityApiAxiosParamCreator(configuration).findByIdPartnerPartyLinkUsingGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByPartnerIdInPartnerPartyLink
         * @param {string} [partnerIds] partnerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByPartnerIdInPartnerPartyLinkUsingGET(partnerIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerPartyLink>> {
            const localVarAxiosArgs = await PartnerPartyLinkEntityApiAxiosParamCreator(configuration).findByPartnerIdInPartnerPartyLinkUsingGET(partnerIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByPartyIdPartnerPartyLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByPartyIdPartnerPartyLinkUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerPartyLink>> {
            const localVarAxiosArgs = await PartnerPartyLinkEntityApiAxiosParamCreator(configuration).findByPartyIdPartnerPartyLinkUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary savePartnerPartyLink
         * @param {string} id id
         * @param {PartnerPartyLink3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartnerPartyLinkUsingPATCH(id: string, body: PartnerPartyLink3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerPartyLink>> {
            const localVarAxiosArgs = await PartnerPartyLinkEntityApiAxiosParamCreator(configuration).savePartnerPartyLinkUsingPATCH(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary savePartnerPartyLink
         * @param {PartnerPartyLink1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartnerPartyLinkUsingPOST(body: PartnerPartyLink1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerPartyLink>> {
            const localVarAxiosArgs = await PartnerPartyLinkEntityApiAxiosParamCreator(configuration).savePartnerPartyLinkUsingPOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary savePartnerPartyLink
         * @param {string} id id
         * @param {PartnerPartyLink2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartnerPartyLinkUsingPUT(id: string, body: PartnerPartyLink2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerPartyLink>> {
            const localVarAxiosArgs = await PartnerPartyLinkEntityApiAxiosParamCreator(configuration).savePartnerPartyLinkUsingPUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PartnerPartyLinkEntityApi - factory interface
 * @export
 */
export const PartnerPartyLinkEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary deletePartnerPartyLink
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerPartyLinkUsingDELETE(id: string, options?: any): AxiosPromise<void> {
            return PartnerPartyLinkEntityApiFp(configuration).deletePartnerPartyLinkUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findAllPartnerPartyLink
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPartnerPartyLinkUsingGET(page?: number, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelPartnerPartyLink> {
            return PartnerPartyLinkEntityApiFp(configuration).findAllPartnerPartyLinkUsingGET(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByIdPartnerPartyLink
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPartnerPartyLinkUsingGET(id: string, options?: any): AxiosPromise<EntityModelPartnerPartyLink> {
            return PartnerPartyLinkEntityApiFp(configuration).findByIdPartnerPartyLinkUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByPartnerIdInPartnerPartyLink
         * @param {string} [partnerIds] partnerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPartnerIdInPartnerPartyLinkUsingGET(partnerIds?: string, options?: any): AxiosPromise<EntityModelPartnerPartyLink> {
            return PartnerPartyLinkEntityApiFp(configuration).findByPartnerIdInPartnerPartyLinkUsingGET(partnerIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByPartyIdPartnerPartyLink
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPartyIdPartnerPartyLinkUsingGET(options?: any): AxiosPromise<EntityModelPartnerPartyLink> {
            return PartnerPartyLinkEntityApiFp(configuration).findByPartyIdPartnerPartyLinkUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary savePartnerPartyLink
         * @param {string} id id
         * @param {PartnerPartyLink3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerPartyLinkUsingPATCH(id: string, body: PartnerPartyLink3, options?: any): AxiosPromise<EntityModelPartnerPartyLink> {
            return PartnerPartyLinkEntityApiFp(configuration).savePartnerPartyLinkUsingPATCH(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary savePartnerPartyLink
         * @param {PartnerPartyLink1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerPartyLinkUsingPOST(body: PartnerPartyLink1, options?: any): AxiosPromise<EntityModelPartnerPartyLink> {
            return PartnerPartyLinkEntityApiFp(configuration).savePartnerPartyLinkUsingPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary savePartnerPartyLink
         * @param {string} id id
         * @param {PartnerPartyLink2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerPartyLinkUsingPUT(id: string, body: PartnerPartyLink2, options?: any): AxiosPromise<EntityModelPartnerPartyLink> {
            return PartnerPartyLinkEntityApiFp(configuration).savePartnerPartyLinkUsingPUT(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerPartyLinkEntityApi - object-oriented interface
 * @export
 * @class PartnerPartyLinkEntityApi
 * @extends {BaseAPI}
 */
export class PartnerPartyLinkEntityApi extends BaseAPI {
    /**
     * 
     * @summary deletePartnerPartyLink
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyLinkEntityApi
     */
    public deletePartnerPartyLinkUsingDELETE(id: string, options?: any) {
        return PartnerPartyLinkEntityApiFp(this.configuration).deletePartnerPartyLinkUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findAllPartnerPartyLink
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyLinkEntityApi
     */
    public findAllPartnerPartyLinkUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return PartnerPartyLinkEntityApiFp(this.configuration).findAllPartnerPartyLinkUsingGET(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByIdPartnerPartyLink
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyLinkEntityApi
     */
    public findByIdPartnerPartyLinkUsingGET(id: string, options?: any) {
        return PartnerPartyLinkEntityApiFp(this.configuration).findByIdPartnerPartyLinkUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByPartnerIdInPartnerPartyLink
     * @param {string} [partnerIds] partnerIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyLinkEntityApi
     */
    public findByPartnerIdInPartnerPartyLinkUsingGET(partnerIds?: string, options?: any) {
        return PartnerPartyLinkEntityApiFp(this.configuration).findByPartnerIdInPartnerPartyLinkUsingGET(partnerIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByPartyIdPartnerPartyLink
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyLinkEntityApi
     */
    public findByPartyIdPartnerPartyLinkUsingGET(options?: any) {
        return PartnerPartyLinkEntityApiFp(this.configuration).findByPartyIdPartnerPartyLinkUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary savePartnerPartyLink
     * @param {string} id id
     * @param {PartnerPartyLink3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyLinkEntityApi
     */
    public savePartnerPartyLinkUsingPATCH(id: string, body: PartnerPartyLink3, options?: any) {
        return PartnerPartyLinkEntityApiFp(this.configuration).savePartnerPartyLinkUsingPATCH(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary savePartnerPartyLink
     * @param {PartnerPartyLink1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyLinkEntityApi
     */
    public savePartnerPartyLinkUsingPOST(body: PartnerPartyLink1, options?: any) {
        return PartnerPartyLinkEntityApiFp(this.configuration).savePartnerPartyLinkUsingPOST(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary savePartnerPartyLink
     * @param {string} id id
     * @param {PartnerPartyLink2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyLinkEntityApi
     */
    public savePartnerPartyLinkUsingPUT(id: string, body: PartnerPartyLink2, options?: any) {
        return PartnerPartyLinkEntityApiFp(this.configuration).savePartnerPartyLinkUsingPUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PartnerTransactionControllerApi - axios parameter creator
 * @export
 */
export const PartnerTransactionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetchPartyPartnerTransaction
         * @param {SearchParameters7} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartyPartnerTransactionUsingPOST: async (searchParameters: SearchParameters7, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            if (searchParameters === null || searchParameters === undefined) {
                throw new RequiredError('searchParameters','Required parameter searchParameters was null or undefined when calling fetchPartyPartnerTransactionUsingPOST.');
            }
            const localVarPath = `/partner-portal/transactions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchParameters !== undefined ? searchParameters : {}) : (searchParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fetchTransactionDetailsById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTransactionDetailsByIdUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling fetchTransactionDetailsByIdUsingGET.');
            }
            const localVarPath = `/partner-portal/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchPartyTransactions
         * @param {string} partyId partyId
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartyTransactionsUsingGET: async (partyId: string, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partyId' is not null or undefined
            if (partyId === null || partyId === undefined) {
                throw new RequiredError('partyId','Required parameter partyId was null or undefined when calling searchPartyTransactionsUsingGET.');
            }
            const localVarPath = `/partner-portal/transactions/party/{partyId}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerTransactionControllerApi - functional programming interface
 * @export
 */
export const PartnerTransactionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetchPartyPartnerTransaction
         * @param {SearchParameters7} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartyPartnerTransactionUsingPOST(searchParameters: SearchParameters7, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartnerTransactionSummary>> {
            const localVarAxiosArgs = await PartnerTransactionControllerApiAxiosParamCreator(configuration).fetchPartyPartnerTransactionUsingPOST(searchParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary fetchTransactionDetailsById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchTransactionDetailsByIdUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiTransactionDetails>> {
            const localVarAxiosArgs = await PartnerTransactionControllerApiAxiosParamCreator(configuration).fetchTransactionDetailsByIdUsingGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchPartyTransactions
         * @param {string} partyId partyId
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPartyTransactionsUsingGET(partyId: string, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartnerTransaction>> {
            const localVarAxiosArgs = await PartnerTransactionControllerApiAxiosParamCreator(configuration).searchPartyTransactionsUsingGET(partyId, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PartnerTransactionControllerApi - factory interface
 * @export
 */
export const PartnerTransactionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary fetchPartyPartnerTransaction
         * @param {SearchParameters7} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartyPartnerTransactionUsingPOST(searchParameters: SearchParameters7, options?: any): AxiosPromise<ApiResponsePageApiPartnerTransactionSummary> {
            return PartnerTransactionControllerApiFp(configuration).fetchPartyPartnerTransactionUsingPOST(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary fetchTransactionDetailsById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchTransactionDetailsByIdUsingGET(id: string, options?: any): AxiosPromise<ApiResponseApiTransactionDetails> {
            return PartnerTransactionControllerApiFp(configuration).fetchTransactionDetailsByIdUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchPartyTransactions
         * @param {string} partyId partyId
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartyTransactionsUsingGET(partyId: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<ApiResponsePageApiPartnerTransaction> {
            return PartnerTransactionControllerApiFp(configuration).searchPartyTransactionsUsingGET(partyId, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerTransactionControllerApi - object-oriented interface
 * @export
 * @class PartnerTransactionControllerApi
 * @extends {BaseAPI}
 */
export class PartnerTransactionControllerApi extends BaseAPI {
    /**
     * 
     * @summary fetchPartyPartnerTransaction
     * @param {SearchParameters7} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApi
     */
    public fetchPartyPartnerTransactionUsingPOST(searchParameters: SearchParameters7, options?: any) {
        return PartnerTransactionControllerApiFp(this.configuration).fetchPartyPartnerTransactionUsingPOST(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary fetchTransactionDetailsById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApi
     */
    public fetchTransactionDetailsByIdUsingGET(id: string, options?: any) {
        return PartnerTransactionControllerApiFp(this.configuration).fetchTransactionDetailsByIdUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchPartyTransactions
     * @param {string} partyId partyId
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionControllerApi
     */
    public searchPartyTransactionsUsingGET(partyId: string, page?: number, size?: number, sort?: string, options?: any) {
        return PartnerTransactionControllerApiFp(this.configuration).searchPartyTransactionsUsingGET(partyId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PartnerTransactionEntityApi - axios parameter creator
 * @export
 */
export const PartnerTransactionEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deletePartnerTransaction
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerTransactionUsingDELETE: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePartnerTransactionUsingDELETE.');
            }
            const localVarPath = `/partnerTransactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllPartnerTransaction
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPartnerTransactionUsingGET: async (page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partnerTransactions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdAndPartnerIdInPartnerTransaction
         * @param {string} [id] id
         * @param {string} [typedPartnerIds] typedPartnerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAndPartnerIdInPartnerTransactionUsingGET: async (id?: string, typedPartnerIds?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partnerTransactions/search/findByIdAndPartnerIdIn`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (typedPartnerIds !== undefined) {
                localVarQueryParameter['typedPartnerIds'] = typedPartnerIds;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdPartnerTransaction
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPartnerTransactionUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdPartnerTransactionUsingGET.');
            }
            const localVarPath = `/partnerTransactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransaction
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerId] partnerId
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransactionUsingGET: async (page?: number, partnerId?: string, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partnerTransactions/search/findByPartyIdAndPartnerIdInOrderByCreatedDesc`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePartnerTransaction
         * @param {string} id id
         * @param {PartnerTransaction3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerTransactionUsingPATCH: async (id: string, body: PartnerTransaction3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePartnerTransactionUsingPATCH.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartnerTransactionUsingPATCH.');
            }
            const localVarPath = `/partnerTransactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePartnerTransaction
         * @param {PartnerTransaction1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerTransactionUsingPOST: async (body: PartnerTransaction1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartnerTransactionUsingPOST.');
            }
            const localVarPath = `/partnerTransactions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePartnerTransaction
         * @param {string} id id
         * @param {PartnerTransaction2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerTransactionUsingPUT: async (id: string, body: PartnerTransaction2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePartnerTransactionUsingPUT.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartnerTransactionUsingPUT.');
            }
            const localVarPath = `/partnerTransactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerTransactionEntityApi - functional programming interface
 * @export
 */
export const PartnerTransactionEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deletePartnerTransaction
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartnerTransactionUsingDELETE(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PartnerTransactionEntityApiAxiosParamCreator(configuration).deletePartnerTransactionUsingDELETE(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findAllPartnerTransaction
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllPartnerTransactionUsingGET(page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartnerTransaction>> {
            const localVarAxiosArgs = await PartnerTransactionEntityApiAxiosParamCreator(configuration).findAllPartnerTransactionUsingGET(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByIdAndPartnerIdInPartnerTransaction
         * @param {string} [id] id
         * @param {string} [typedPartnerIds] typedPartnerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIdAndPartnerIdInPartnerTransactionUsingGET(id?: string, typedPartnerIds?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerTransaction>> {
            const localVarAxiosArgs = await PartnerTransactionEntityApiAxiosParamCreator(configuration).findByIdAndPartnerIdInPartnerTransactionUsingGET(id, typedPartnerIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByIdPartnerTransaction
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIdPartnerTransactionUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerTransaction>> {
            const localVarAxiosArgs = await PartnerTransactionEntityApiAxiosParamCreator(configuration).findByIdPartnerTransactionUsingGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransaction
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerId] partnerId
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransactionUsingGET(page?: number, partnerId?: string, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartnerTransaction>> {
            const localVarAxiosArgs = await PartnerTransactionEntityApiAxiosParamCreator(configuration).findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransactionUsingGET(page, partnerId, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary savePartnerTransaction
         * @param {string} id id
         * @param {PartnerTransaction3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartnerTransactionUsingPATCH(id: string, body: PartnerTransaction3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerTransaction>> {
            const localVarAxiosArgs = await PartnerTransactionEntityApiAxiosParamCreator(configuration).savePartnerTransactionUsingPATCH(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary savePartnerTransaction
         * @param {PartnerTransaction1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartnerTransactionUsingPOST(body: PartnerTransaction1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerTransaction>> {
            const localVarAxiosArgs = await PartnerTransactionEntityApiAxiosParamCreator(configuration).savePartnerTransactionUsingPOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary savePartnerTransaction
         * @param {string} id id
         * @param {PartnerTransaction2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartnerTransactionUsingPUT(id: string, body: PartnerTransaction2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelPartnerTransaction>> {
            const localVarAxiosArgs = await PartnerTransactionEntityApiAxiosParamCreator(configuration).savePartnerTransactionUsingPUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PartnerTransactionEntityApi - factory interface
 * @export
 */
export const PartnerTransactionEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary deletePartnerTransaction
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerTransactionUsingDELETE(id: string, options?: any): AxiosPromise<void> {
            return PartnerTransactionEntityApiFp(configuration).deletePartnerTransactionUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findAllPartnerTransaction
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPartnerTransactionUsingGET(page?: number, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelPartnerTransaction> {
            return PartnerTransactionEntityApiFp(configuration).findAllPartnerTransactionUsingGET(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByIdAndPartnerIdInPartnerTransaction
         * @param {string} [id] id
         * @param {string} [typedPartnerIds] typedPartnerIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAndPartnerIdInPartnerTransactionUsingGET(id?: string, typedPartnerIds?: string, options?: any): AxiosPromise<EntityModelPartnerTransaction> {
            return PartnerTransactionEntityApiFp(configuration).findByIdAndPartnerIdInPartnerTransactionUsingGET(id, typedPartnerIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByIdPartnerTransaction
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPartnerTransactionUsingGET(id: string, options?: any): AxiosPromise<EntityModelPartnerTransaction> {
            return PartnerTransactionEntityApiFp(configuration).findByIdPartnerTransactionUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransaction
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerId] partnerId
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransactionUsingGET(page?: number, partnerId?: string, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelPartnerTransaction> {
            return PartnerTransactionEntityApiFp(configuration).findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransactionUsingGET(page, partnerId, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary savePartnerTransaction
         * @param {string} id id
         * @param {PartnerTransaction3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerTransactionUsingPATCH(id: string, body: PartnerTransaction3, options?: any): AxiosPromise<EntityModelPartnerTransaction> {
            return PartnerTransactionEntityApiFp(configuration).savePartnerTransactionUsingPATCH(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary savePartnerTransaction
         * @param {PartnerTransaction1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerTransactionUsingPOST(body: PartnerTransaction1, options?: any): AxiosPromise<EntityModelPartnerTransaction> {
            return PartnerTransactionEntityApiFp(configuration).savePartnerTransactionUsingPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary savePartnerTransaction
         * @param {string} id id
         * @param {PartnerTransaction2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartnerTransactionUsingPUT(id: string, body: PartnerTransaction2, options?: any): AxiosPromise<EntityModelPartnerTransaction> {
            return PartnerTransactionEntityApiFp(configuration).savePartnerTransactionUsingPUT(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerTransactionEntityApi - object-oriented interface
 * @export
 * @class PartnerTransactionEntityApi
 * @extends {BaseAPI}
 */
export class PartnerTransactionEntityApi extends BaseAPI {
    /**
     * 
     * @summary deletePartnerTransaction
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionEntityApi
     */
    public deletePartnerTransactionUsingDELETE(id: string, options?: any) {
        return PartnerTransactionEntityApiFp(this.configuration).deletePartnerTransactionUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findAllPartnerTransaction
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionEntityApi
     */
    public findAllPartnerTransactionUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return PartnerTransactionEntityApiFp(this.configuration).findAllPartnerTransactionUsingGET(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByIdAndPartnerIdInPartnerTransaction
     * @param {string} [id] id
     * @param {string} [typedPartnerIds] typedPartnerIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionEntityApi
     */
    public findByIdAndPartnerIdInPartnerTransactionUsingGET(id?: string, typedPartnerIds?: string, options?: any) {
        return PartnerTransactionEntityApiFp(this.configuration).findByIdAndPartnerIdInPartnerTransactionUsingGET(id, typedPartnerIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByIdPartnerTransaction
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionEntityApi
     */
    public findByIdPartnerTransactionUsingGET(id: string, options?: any) {
        return PartnerTransactionEntityApiFp(this.configuration).findByIdPartnerTransactionUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransaction
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {string} [partnerId] partnerId
     * @param {number} [size] Number of records per page.
     * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionEntityApi
     */
    public findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransactionUsingGET(page?: number, partnerId?: string, size?: number, sort?: string, options?: any) {
        return PartnerTransactionEntityApiFp(this.configuration).findByPartyIdAndPartnerIdInOrderByCreatedDescPartnerTransactionUsingGET(page, partnerId, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary savePartnerTransaction
     * @param {string} id id
     * @param {PartnerTransaction3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionEntityApi
     */
    public savePartnerTransactionUsingPATCH(id: string, body: PartnerTransaction3, options?: any) {
        return PartnerTransactionEntityApiFp(this.configuration).savePartnerTransactionUsingPATCH(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary savePartnerTransaction
     * @param {PartnerTransaction1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionEntityApi
     */
    public savePartnerTransactionUsingPOST(body: PartnerTransaction1, options?: any) {
        return PartnerTransactionEntityApiFp(this.configuration).savePartnerTransactionUsingPOST(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary savePartnerTransaction
     * @param {string} id id
     * @param {PartnerTransaction2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerTransactionEntityApi
     */
    public savePartnerTransactionUsingPUT(id: string, body: PartnerTransaction2, options?: any) {
        return PartnerTransactionEntityApiFp(this.configuration).savePartnerTransactionUsingPUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PartnerUserRoleControllerApi - axios parameter creator
 * @export
 */
export const PartnerUserRoleControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createBakktUser
         * @param {CreateUserRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBakktUserUsingPOST: async (request: CreateUserRequest1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling createBakktUserUsingPOST.');
            }
            const localVarPath = `/partner-portal/users/tango-portal/invite`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOrUpdateUser
         * @param {CreateUserRequest} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateUserUsingPOST: async (user: CreateUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling createOrUpdateUserUsingPOST.');
            }
            const localVarPath = `/partner-portal/users`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof user !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(user !== undefined ? user : {}) : (user || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOwner
         * @param {CreatePartnerOwnerRequest} owner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwnerUsingPOST: async (owner: CreatePartnerOwnerRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'owner' is not null or undefined
            if (owner === null || owner === undefined) {
                throw new RequiredError('owner','Required parameter owner was null or undefined when calling createOwnerUsingPOST.');
            }
            const localVarPath = `/partner-portal/users/owner`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof owner !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(owner !== undefined ? owner : {}) : (owner || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createRoleWithPermissions
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleWithPermissionsUsingPOST: async (createRoleRequest: CreateRoleRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRoleRequest' is not null or undefined
            if (createRoleRequest === null || createRoleRequest === undefined) {
                throw new RequiredError('createRoleRequest','Required parameter createRoleRequest was null or undefined when calling createRoleWithPermissionsUsingPOST.');
            }
            const localVarPath = `/partner-portal/role`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof createRoleRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(createRoleRequest !== undefined ? createRoleRequest : {}) : (createRoleRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deactivatePartnerUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePartnerUserUsingPOST: async (fusionAuthRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            if (fusionAuthRef === null || fusionAuthRef === undefined) {
                throw new RequiredError('fusionAuthRef','Required parameter fusionAuthRef was null or undefined when calling deactivatePartnerUserUsingPOST.');
            }
            const localVarPath = `/partner-portal/users/{fusionAuthRef}/deactivate`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPartnerUserSessionInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerUserSessionInfoUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner-portal/users/current`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPortalUserDetail
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUserDetailUsingGET: async (fusionAuthRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            if (fusionAuthRef === null || fusionAuthRef === undefined) {
                throw new RequiredError('fusionAuthRef','Required parameter fusionAuthRef was null or undefined when calling getPortalUserDetailUsingGET.');
            }
            const localVarPath = `/partner-portal/users/{fusionAuthRef}`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPortalUsers
         * @param {SearchParameters8} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUsersUsingPOST: async (searchParameters: SearchParameters8, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            if (searchParameters === null || searchParameters === undefined) {
                throw new RequiredError('searchParameters','Required parameter searchParameters was null or undefined when calling getPortalUsersUsingPOST.');
            }
            const localVarPath = `/partner-portal/users/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchParameters !== undefined ? searchParameters : {}) : (searchParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRoleList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleListUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner-portal/role`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary modifyUserRoles
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {UpdateUserRequest1} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserRolesUsingPOST: async (fusionAuthRef: string, updateUserRequest: UpdateUserRequest1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            if (fusionAuthRef === null || fusionAuthRef === undefined) {
                throw new RequiredError('fusionAuthRef','Required parameter fusionAuthRef was null or undefined when calling modifyUserRolesUsingPOST.');
            }
            // verify required parameter 'updateUserRequest' is not null or undefined
            if (updateUserRequest === null || updateUserRequest === undefined) {
                throw new RequiredError('updateUserRequest','Required parameter updateUserRequest was null or undefined when calling modifyUserRolesUsingPOST.');
            }
            const localVarPath = `/partner-portal/users/{fusionAuthRef}/roles`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateUserRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateUserRequest !== undefined ? updateUserRequest : {}) : (updateUserRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reactivatePartnerUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivatePartnerUserUsingPOST: async (fusionAuthRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            if (fusionAuthRef === null || fusionAuthRef === undefined) {
                throw new RequiredError('fusionAuthRef','Required parameter fusionAuthRef was null or undefined when calling reactivatePartnerUserUsingPOST.');
            }
            const localVarPath = `/partner-portal/users/{fusionAuthRef}/reactivate`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sendInvite
         * @param {InviteUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteUsingPOST: async (request: InviteUserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling sendInviteUsingPOST.');
            }
            const localVarPath = `/partner-portal/invite`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateRoleWithPermissions
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleWithPermissionsUsingPUT: async (updateRoleRequest: UpdateRoleRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRoleRequest' is not null or undefined
            if (updateRoleRequest === null || updateRoleRequest === undefined) {
                throw new RequiredError('updateRoleRequest','Required parameter updateRoleRequest was null or undefined when calling updateRoleWithPermissionsUsingPUT.');
            }
            const localVarPath = `/partner-portal/role`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof updateRoleRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateRoleRequest !== undefined ? updateRoleRequest : {}) : (updateRoleRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateUserPartner
         * @param {UpdateUserPartnerRerquest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPartnerUsingPUT: async (request: UpdateUserPartnerRerquest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling updateUserPartnerUsingPUT.');
            }
            const localVarPath = `/partner-portal/users/{fusionAuthRef}/partner`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerUserRoleControllerApi - functional programming interface
 * @export
 */
export const PartnerUserRoleControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createBakktUser
         * @param {CreateUserRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBakktUserUsingPOST(request: CreateUserRequest1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).createBakktUserUsingPOST(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary createOrUpdateUser
         * @param {CreateUserRequest} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateUserUsingPOST(user: CreateUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).createOrUpdateUserUsingPOST(user, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary createOwner
         * @param {CreatePartnerOwnerRequest} owner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOwnerUsingPOST(owner: CreatePartnerOwnerRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).createOwnerUsingPOST(owner, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary createRoleWithPermissions
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoleWithPermissionsUsingPOST(createRoleRequest: CreateRoleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).createRoleWithPermissionsUsingPOST(createRoleRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deactivatePartnerUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivatePartnerUserUsingPOST(fusionAuthRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).deactivatePartnerUserUsingPOST(fusionAuthRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getPartnerUserSessionInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerUserSessionInfoUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPartnerUserInfo>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).getPartnerUserSessionInfoUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getPortalUserDetail
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortalUserDetailUsingGET(fusionAuthRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiMerchantPortalUser>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).getPortalUserDetailUsingGET(fusionAuthRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getPortalUsers
         * @param {SearchParameters8} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPortalUsersUsingPOST(searchParameters: SearchParameters8, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiMerchantPortalUser>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).getPortalUsersUsingPOST(searchParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary getRoleList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleListUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseListApiPortalRole>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).getRoleListUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary modifyUserRoles
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {UpdateUserRequest1} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyUserRolesUsingPOST(fusionAuthRef: string, updateUserRequest: UpdateUserRequest1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).modifyUserRolesUsingPOST(fusionAuthRef, updateUserRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary reactivatePartnerUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reactivatePartnerUserUsingPOST(fusionAuthRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).reactivatePartnerUserUsingPOST(fusionAuthRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary sendInvite
         * @param {InviteUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendInviteUsingPOST(request: InviteUserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).sendInviteUsingPOST(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateRoleWithPermissions
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoleWithPermissionsUsingPUT(updateRoleRequest: UpdateRoleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).updateRoleWithPermissionsUsingPUT(updateRoleRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary updateUserPartner
         * @param {UpdateUserPartnerRerquest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPartnerUsingPUT(request: UpdateUserPartnerRerquest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseVoid>> {
            const localVarAxiosArgs = await PartnerUserRoleControllerApiAxiosParamCreator(configuration).updateUserPartnerUsingPUT(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PartnerUserRoleControllerApi - factory interface
 * @export
 */
export const PartnerUserRoleControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary createBakktUser
         * @param {CreateUserRequest1} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBakktUserUsingPOST(request: CreateUserRequest1, options?: any): AxiosPromise<ApiResponseVoid> {
            return PartnerUserRoleControllerApiFp(configuration).createBakktUserUsingPOST(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createOrUpdateUser
         * @param {CreateUserRequest} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateUserUsingPOST(user: CreateUserRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return PartnerUserRoleControllerApiFp(configuration).createOrUpdateUserUsingPOST(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createOwner
         * @param {CreatePartnerOwnerRequest} owner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOwnerUsingPOST(owner: CreatePartnerOwnerRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return PartnerUserRoleControllerApiFp(configuration).createOwnerUsingPOST(owner, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary createRoleWithPermissions
         * @param {CreateRoleRequest} createRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleWithPermissionsUsingPOST(createRoleRequest: CreateRoleRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return PartnerUserRoleControllerApiFp(configuration).createRoleWithPermissionsUsingPOST(createRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deactivatePartnerUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePartnerUserUsingPOST(fusionAuthRef: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return PartnerUserRoleControllerApiFp(configuration).deactivatePartnerUserUsingPOST(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPartnerUserSessionInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerUserSessionInfoUsingGET(options?: any): AxiosPromise<ApiPartnerUserInfo> {
            return PartnerUserRoleControllerApiFp(configuration).getPartnerUserSessionInfoUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPortalUserDetail
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUserDetailUsingGET(fusionAuthRef: string, options?: any): AxiosPromise<ApiResponseApiMerchantPortalUser> {
            return PartnerUserRoleControllerApiFp(configuration).getPortalUserDetailUsingGET(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getPortalUsers
         * @param {SearchParameters8} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUsersUsingPOST(searchParameters: SearchParameters8, options?: any): AxiosPromise<ApiResponsePageApiMerchantPortalUser> {
            return PartnerUserRoleControllerApiFp(configuration).getPortalUsersUsingPOST(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getRoleList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleListUsingGET(options?: any): AxiosPromise<ApiResponseListApiPortalRole> {
            return PartnerUserRoleControllerApiFp(configuration).getRoleListUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary modifyUserRoles
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {UpdateUserRequest1} updateUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserRolesUsingPOST(fusionAuthRef: string, updateUserRequest: UpdateUserRequest1, options?: any): AxiosPromise<ApiResponseVoid> {
            return PartnerUserRoleControllerApiFp(configuration).modifyUserRolesUsingPOST(fusionAuthRef, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reactivatePartnerUser
         * @param {string} fusionAuthRef fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivatePartnerUserUsingPOST(fusionAuthRef: string, options?: any): AxiosPromise<ApiResponseVoid> {
            return PartnerUserRoleControllerApiFp(configuration).reactivatePartnerUserUsingPOST(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sendInvite
         * @param {InviteUserRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInviteUsingPOST(request: InviteUserRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return PartnerUserRoleControllerApiFp(configuration).sendInviteUsingPOST(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateRoleWithPermissions
         * @param {UpdateRoleRequest} updateRoleRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleWithPermissionsUsingPUT(updateRoleRequest: UpdateRoleRequest, options?: any): AxiosPromise<ApiResponseVoid> {
            return PartnerUserRoleControllerApiFp(configuration).updateRoleWithPermissionsUsingPUT(updateRoleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateUserPartner
         * @param {UpdateUserPartnerRerquest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPartnerUsingPUT(request: UpdateUserPartnerRerquest, options?: any): AxiosPromise<ApiResponseVoid> {
            return PartnerUserRoleControllerApiFp(configuration).updateUserPartnerUsingPUT(request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerUserRoleControllerApi - object-oriented interface
 * @export
 * @class PartnerUserRoleControllerApi
 * @extends {BaseAPI}
 */
export class PartnerUserRoleControllerApi extends BaseAPI {
    /**
     * 
     * @summary createBakktUser
     * @param {CreateUserRequest1} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public createBakktUserUsingPOST(request: CreateUserRequest1, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).createBakktUserUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createOrUpdateUser
     * @param {CreateUserRequest} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public createOrUpdateUserUsingPOST(user: CreateUserRequest, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).createOrUpdateUserUsingPOST(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createOwner
     * @param {CreatePartnerOwnerRequest} owner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public createOwnerUsingPOST(owner: CreatePartnerOwnerRequest, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).createOwnerUsingPOST(owner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary createRoleWithPermissions
     * @param {CreateRoleRequest} createRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public createRoleWithPermissionsUsingPOST(createRoleRequest: CreateRoleRequest, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).createRoleWithPermissionsUsingPOST(createRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deactivatePartnerUser
     * @param {string} fusionAuthRef fusionAuthRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public deactivatePartnerUserUsingPOST(fusionAuthRef: string, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).deactivatePartnerUserUsingPOST(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPartnerUserSessionInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public getPartnerUserSessionInfoUsingGET(options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).getPartnerUserSessionInfoUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPortalUserDetail
     * @param {string} fusionAuthRef fusionAuthRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public getPortalUserDetailUsingGET(fusionAuthRef: string, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).getPortalUserDetailUsingGET(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getPortalUsers
     * @param {SearchParameters8} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public getPortalUsersUsingPOST(searchParameters: SearchParameters8, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).getPortalUsersUsingPOST(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getRoleList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public getRoleListUsingGET(options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).getRoleListUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary modifyUserRoles
     * @param {string} fusionAuthRef fusionAuthRef
     * @param {UpdateUserRequest1} updateUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public modifyUserRolesUsingPOST(fusionAuthRef: string, updateUserRequest: UpdateUserRequest1, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).modifyUserRolesUsingPOST(fusionAuthRef, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reactivatePartnerUser
     * @param {string} fusionAuthRef fusionAuthRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public reactivatePartnerUserUsingPOST(fusionAuthRef: string, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).reactivatePartnerUserUsingPOST(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sendInvite
     * @param {InviteUserRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public sendInviteUsingPOST(request: InviteUserRequest, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).sendInviteUsingPOST(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateRoleWithPermissions
     * @param {UpdateRoleRequest} updateRoleRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public updateRoleWithPermissionsUsingPUT(updateRoleRequest: UpdateRoleRequest, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).updateRoleWithPermissionsUsingPUT(updateRoleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateUserPartner
     * @param {UpdateUserPartnerRerquest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerUserRoleControllerApi
     */
    public updateUserPartnerUsingPUT(request: UpdateUserPartnerRerquest, options?: any) {
        return PartnerUserRoleControllerApiFp(this.configuration).updateUserPartnerUsingPUT(request, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PartyControllerApi - axios parameter creator
 * @export
 */
export const PartyControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetchPartyDetails
         * @param {string} partyId partyId
         * @param {ApiPartyDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartyDetailsUsingPOST: async (partyId: string, request: ApiPartyDetailsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partyId' is not null or undefined
            if (partyId === null || partyId === undefined) {
                throw new RequiredError('partyId','Required parameter partyId was null or undefined when calling fetchPartyDetailsUsingPOST.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling fetchPartyDetailsUsingPOST.');
            }
            const localVarPath = `/partner-portal/party/{partyId}/details`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrivePortfolio
         * @param {string} partnerId partnerId
         * @param {string} partyId partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrivePortfolioUsingGET: async (partnerId: string, partyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            if (partnerId === null || partnerId === undefined) {
                throw new RequiredError('partnerId','Required parameter partnerId was null or undefined when calling retrivePortfolioUsingGET.');
            }
            // verify required parameter 'partyId' is not null or undefined
            if (partyId === null || partyId === undefined) {
                throw new RequiredError('partyId','Required parameter partyId was null or undefined when calling retrivePortfolioUsingGET.');
            }
            const localVarPath = `/partner-portal/party/{partyId}/partner/{partnerId}/portfolio`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId
         * @param {ApiPartyDashboardSearch} searchRequest 
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyIdUsingPOST: async (searchRequest: ApiPartyDashboardSearch, page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchRequest' is not null or undefined
            if (searchRequest === null || searchRequest === undefined) {
                throw new RequiredError('searchRequest','Required parameter searchRequest was null or undefined when calling searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyIdUsingPOST.');
            }
            const localVarPath = `/partner-portal/party/dashboard/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchRequest !== undefined ? searchRequest : {}) : (searchRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchConsumers
         * @param {SearchParameters6} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumersUsingPOST: async (searchParameters: SearchParameters6, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchParameters' is not null or undefined
            if (searchParameters === null || searchParameters === undefined) {
                throw new RequiredError('searchParameters','Required parameter searchParameters was null or undefined when calling searchConsumersUsingPOST.');
            }
            const localVarPath = `/partner-portal/party/search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof searchParameters !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(searchParameters !== undefined ? searchParameters : {}) : (searchParameters || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary searchParty
         * @param {string} partyId partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartyUsingGET: async (partyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'partyId' is not null or undefined
            if (partyId === null || partyId === undefined) {
                throw new RequiredError('partyId','Required parameter partyId was null or undefined when calling searchPartyUsingGET.');
            }
            const localVarPath = `/partner-portal/party/{partyId}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartyControllerApi - functional programming interface
 * @export
 */
export const PartyControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary fetchPartyDetails
         * @param {string} partyId partyId
         * @param {ApiPartyDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartyDetailsUsingPOST(partyId: string, request: ApiPartyDetailsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiPartyDetails>> {
            const localVarAxiosArgs = await PartyControllerApiAxiosParamCreator(configuration).fetchPartyDetailsUsingPOST(partyId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary retrivePortfolio
         * @param {string} partnerId partnerId
         * @param {string} partyId partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async retrivePortfolioUsingGET(partnerId: string, partyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiPartyPortfolio>> {
            const localVarAxiosArgs = await PartyControllerApiAxiosParamCreator(configuration).retrivePortfolioUsingGET(partnerId, partyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId
         * @param {ApiPartyDashboardSearch} searchRequest 
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyIdUsingPOST(searchRequest: ApiPartyDashboardSearch, page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiPartyBaseSearchResponse>> {
            const localVarAxiosArgs = await PartyControllerApiAxiosParamCreator(configuration).searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyIdUsingPOST(searchRequest, page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchConsumers
         * @param {SearchParameters6} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchConsumersUsingPOST(searchParameters: SearchParameters6, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponsePageApiParty>> {
            const localVarAxiosArgs = await PartyControllerApiAxiosParamCreator(configuration).searchConsumersUsingPOST(searchParameters, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary searchParty
         * @param {string} partyId partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPartyUsingGET(partyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponseApiParty>> {
            const localVarAxiosArgs = await PartyControllerApiAxiosParamCreator(configuration).searchPartyUsingGET(partyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PartyControllerApi - factory interface
 * @export
 */
export const PartyControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary fetchPartyDetails
         * @param {string} partyId partyId
         * @param {ApiPartyDetailsRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartyDetailsUsingPOST(partyId: string, request: ApiPartyDetailsRequest, options?: any): AxiosPromise<ApiResponseApiPartyDetails> {
            return PartyControllerApiFp(configuration).fetchPartyDetailsUsingPOST(partyId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrivePortfolio
         * @param {string} partnerId partnerId
         * @param {string} partyId partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrivePortfolioUsingGET(partnerId: string, partyId: string, options?: any): AxiosPromise<ApiResponseApiPartyPortfolio> {
            return PartyControllerApiFp(configuration).retrivePortfolioUsingGET(partnerId, partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId
         * @param {ApiPartyDashboardSearch} searchRequest 
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyIdUsingPOST(searchRequest: ApiPartyDashboardSearch, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<ApiResponsePageApiPartyBaseSearchResponse> {
            return PartyControllerApiFp(configuration).searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyIdUsingPOST(searchRequest, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchConsumers
         * @param {SearchParameters6} searchParameters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumersUsingPOST(searchParameters: SearchParameters6, options?: any): AxiosPromise<ApiResponsePageApiParty> {
            return PartyControllerApiFp(configuration).searchConsumersUsingPOST(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary searchParty
         * @param {string} partyId partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartyUsingGET(partyId: string, options?: any): AxiosPromise<ApiResponseApiParty> {
            return PartyControllerApiFp(configuration).searchPartyUsingGET(partyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartyControllerApi - object-oriented interface
 * @export
 * @class PartyControllerApi
 * @extends {BaseAPI}
 */
export class PartyControllerApi extends BaseAPI {
    /**
     * 
     * @summary fetchPartyDetails
     * @param {string} partyId partyId
     * @param {ApiPartyDetailsRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApi
     */
    public fetchPartyDetailsUsingPOST(partyId: string, request: ApiPartyDetailsRequest, options?: any) {
        return PartyControllerApiFp(this.configuration).fetchPartyDetailsUsingPOST(partyId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrivePortfolio
     * @param {string} partnerId partnerId
     * @param {string} partyId partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApi
     */
    public retrivePortfolioUsingGET(partnerId: string, partyId: string, options?: any) {
        return PartyControllerApiFp(this.configuration).retrivePortfolioUsingGET(partnerId, partyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyId
     * @param {ApiPartyDashboardSearch} searchRequest 
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApi
     */
    public searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyIdUsingPOST(searchRequest: ApiPartyDashboardSearch, page?: number, size?: number, sort?: string, options?: any) {
        return PartyControllerApiFp(this.configuration).searchConsumersUsingEmailOrPhoneOrNameOrPartnerPartyIdUsingPOST(searchRequest, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchConsumers
     * @param {SearchParameters6} searchParameters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApi
     */
    public searchConsumersUsingPOST(searchParameters: SearchParameters6, options?: any) {
        return PartyControllerApiFp(this.configuration).searchConsumersUsingPOST(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary searchParty
     * @param {string} partyId partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyControllerApi
     */
    public searchPartyUsingGET(partyId: string, options?: any) {
        return PartyControllerApiFp(this.configuration).searchPartyUsingGET(partyId, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * PartyEntityApi - axios parameter creator
 * @export
 */
export const PartyEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteParty
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartyUsingDELETE: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePartyUsingDELETE.');
            }
            const localVarPath = `/parties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary fetchPartyByPartnerIdParty
         * @param {string} [partnerId] partnerId
         * @param {string} [partyId] partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartyByPartnerIdPartyUsingGET: async (partnerId?: string, partyId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parties/search/fetchPartyByPartnerId`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (partyId !== undefined) {
                localVarQueryParameter['partyId'] = partyId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllParty
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPartyUsingGET: async (page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parties`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByEmailHashInOrPhoneHashInParty
         * @param {string} [emailHashList] emailHashList
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerIds] partnerIds
         * @param {string} [phoneHashList] phoneHashList
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEmailHashInOrPhoneHashInPartyUsingGET: async (emailHashList?: string, page?: number, partnerIds?: string, phoneHashList?: string, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parties/search/findByEmailHashInOrPhoneHashIn`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailHashList !== undefined) {
                localVarQueryParameter['emailHashList'] = emailHashList;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partnerIds !== undefined) {
                localVarQueryParameter['partnerIds'] = partnerIds;
            }

            if (phoneHashList !== undefined) {
                localVarQueryParameter['phoneHashList'] = phoneHashList;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByEmailHashParty
         * @param {string} [emailHash] emailHash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEmailHashPartyUsingGET: async (emailHash?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parties/search/findByEmailHash`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailHash !== undefined) {
                localVarQueryParameter['emailHash'] = emailHash;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByFirstNameAndLastNameParty
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerIds] partnerIds
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByFirstNameAndLastNamePartyUsingGET: async (page?: number, partnerIds?: string, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parties/search/findByFirstNameAndLastName`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partnerIds !== undefined) {
                localVarQueryParameter['partnerIds'] = partnerIds;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByFirstNameOrLastNameOrAliasParty
         * @param {string} [alias] alias
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerIds] partnerIds
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByFirstNameOrLastNameOrAliasPartyUsingGET: async (alias?: string, page?: number, partnerIds?: string, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parties/search/findByFirstNameOrLastNameOrAlias`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (alias !== undefined) {
                localVarQueryParameter['alias'] = alias;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partnerIds !== undefined) {
                localVarQueryParameter['partnerIds'] = partnerIds;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdParty
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPartyUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdPartyUsingGET.');
            }
            const localVarPath = `/parties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByPartyIdAndpartnerIdsParty
         * @param {string} [partnerIds] partnerIds
         * @param {string} [partyId] partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPartyIdAndpartnerIdsPartyUsingGET: async (partnerIds?: string, partyId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parties/search/findByPartyIdAndpartnerIds`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerIds !== undefined) {
                localVarQueryParameter['partnerIds'] = partnerIds;
            }

            if (partyId !== undefined) {
                localVarQueryParameter['partyId'] = partyId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsParty
         * @param {string} [partnerIds] partnerIds
         * @param {string} [partnerPartyLinkId] partnerPartyLinkId
         * @param {string} [partnerPartyRef] partnerPartyRef
         * @param {string} [partyId] partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsPartyUsingGET: async (partnerIds?: string, partnerPartyLinkId?: string, partnerPartyRef?: string, partyId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/parties/search/findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIds`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerIds !== undefined) {
                localVarQueryParameter['partnerIds'] = partnerIds;
            }

            if (partnerPartyLinkId !== undefined) {
                localVarQueryParameter['partnerPartyLinkId'] = partnerPartyLinkId;
            }

            if (partnerPartyRef !== undefined) {
                localVarQueryParameter['partnerPartyRef'] = partnerPartyRef;
            }

            if (partyId !== undefined) {
                localVarQueryParameter['partyId'] = partyId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveParty
         * @param {string} id id
         * @param {Party3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartyUsingPATCH: async (id: string, body: Party3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePartyUsingPATCH.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartyUsingPATCH.');
            }
            const localVarPath = `/parties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveParty
         * @param {Party1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartyUsingPOST: async (body: Party1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartyUsingPOST.');
            }
            const localVarPath = `/parties`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveParty
         * @param {string} id id
         * @param {Party2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartyUsingPUT: async (id: string, body: Party2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePartyUsingPUT.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePartyUsingPUT.');
            }
            const localVarPath = `/parties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartyEntityApi - functional programming interface
 * @export
 */
export const PartyEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteParty
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartyUsingDELETE(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).deletePartyUsingDELETE(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary fetchPartyByPartnerIdParty
         * @param {string} [partnerId] partnerId
         * @param {string} [partyId] partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchPartyByPartnerIdPartyUsingGET(partnerId?: string, partyId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelParty>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).fetchPartyByPartnerIdPartyUsingGET(partnerId, partyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findAllParty
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllPartyUsingGET(page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelParty>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).findAllPartyUsingGET(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByEmailHashInOrPhoneHashInParty
         * @param {string} [emailHashList] emailHashList
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerIds] partnerIds
         * @param {string} [phoneHashList] phoneHashList
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByEmailHashInOrPhoneHashInPartyUsingGET(emailHashList?: string, page?: number, partnerIds?: string, phoneHashList?: string, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartnerPartyDetailProjection>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).findByEmailHashInOrPhoneHashInPartyUsingGET(emailHashList, page, partnerIds, phoneHashList, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByEmailHashParty
         * @param {string} [emailHash] emailHash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByEmailHashPartyUsingGET(emailHash?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelParty>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).findByEmailHashPartyUsingGET(emailHash, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByFirstNameAndLastNameParty
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerIds] partnerIds
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByFirstNameAndLastNamePartyUsingGET(page?: number, partnerIds?: string, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartnerPartyDetailProjection>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).findByFirstNameAndLastNamePartyUsingGET(page, partnerIds, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByFirstNameOrLastNameOrAliasParty
         * @param {string} [alias] alias
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerIds] partnerIds
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByFirstNameOrLastNameOrAliasPartyUsingGET(alias?: string, page?: number, partnerIds?: string, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartnerPartyDetailProjection>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).findByFirstNameOrLastNameOrAliasPartyUsingGET(alias, page, partnerIds, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByIdParty
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIdPartyUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelParty>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).findByIdPartyUsingGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByPartyIdAndpartnerIdsParty
         * @param {string} [partnerIds] partnerIds
         * @param {string} [partyId] partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByPartyIdAndpartnerIdsPartyUsingGET(partnerIds?: string, partyId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelParty>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).findByPartyIdAndpartnerIdsPartyUsingGET(partnerIds, partyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsParty
         * @param {string} [partnerIds] partnerIds
         * @param {string} [partnerPartyLinkId] partnerPartyLinkId
         * @param {string} [partnerPartyRef] partnerPartyRef
         * @param {string} [partyId] partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsPartyUsingGET(partnerIds?: string, partnerPartyLinkId?: string, partnerPartyRef?: string, partyId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelPartnerPartyDetailProjection>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsPartyUsingGET(partnerIds, partnerPartyLinkId, partnerPartyRef, partyId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveParty
         * @param {string} id id
         * @param {Party3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartyUsingPATCH(id: string, body: Party3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelParty>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).savePartyUsingPATCH(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveParty
         * @param {Party1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartyUsingPOST(body: Party1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelParty>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).savePartyUsingPOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveParty
         * @param {string} id id
         * @param {Party2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePartyUsingPUT(id: string, body: Party2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelParty>> {
            const localVarAxiosArgs = await PartyEntityApiAxiosParamCreator(configuration).savePartyUsingPUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PartyEntityApi - factory interface
 * @export
 */
export const PartyEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary deleteParty
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartyUsingDELETE(id: string, options?: any): AxiosPromise<void> {
            return PartyEntityApiFp(configuration).deletePartyUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary fetchPartyByPartnerIdParty
         * @param {string} [partnerId] partnerId
         * @param {string} [partyId] partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartyByPartnerIdPartyUsingGET(partnerId?: string, partyId?: string, options?: any): AxiosPromise<EntityModelParty> {
            return PartyEntityApiFp(configuration).fetchPartyByPartnerIdPartyUsingGET(partnerId, partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findAllParty
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPartyUsingGET(page?: number, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelParty> {
            return PartyEntityApiFp(configuration).findAllPartyUsingGET(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByEmailHashInOrPhoneHashInParty
         * @param {string} [emailHashList] emailHashList
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerIds] partnerIds
         * @param {string} [phoneHashList] phoneHashList
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEmailHashInOrPhoneHashInPartyUsingGET(emailHashList?: string, page?: number, partnerIds?: string, phoneHashList?: string, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelPartnerPartyDetailProjection> {
            return PartyEntityApiFp(configuration).findByEmailHashInOrPhoneHashInPartyUsingGET(emailHashList, page, partnerIds, phoneHashList, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByEmailHashParty
         * @param {string} [emailHash] emailHash
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEmailHashPartyUsingGET(emailHash?: string, options?: any): AxiosPromise<EntityModelParty> {
            return PartyEntityApiFp(configuration).findByEmailHashPartyUsingGET(emailHash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByFirstNameAndLastNameParty
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerIds] partnerIds
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByFirstNameAndLastNamePartyUsingGET(page?: number, partnerIds?: string, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelPartnerPartyDetailProjection> {
            return PartyEntityApiFp(configuration).findByFirstNameAndLastNamePartyUsingGET(page, partnerIds, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByFirstNameOrLastNameOrAliasParty
         * @param {string} [alias] alias
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {string} [partnerIds] partnerIds
         * @param {number} [size] Number of records per page.
         * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByFirstNameOrLastNameOrAliasPartyUsingGET(alias?: string, page?: number, partnerIds?: string, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelPartnerPartyDetailProjection> {
            return PartyEntityApiFp(configuration).findByFirstNameOrLastNameOrAliasPartyUsingGET(alias, page, partnerIds, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByIdParty
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPartyUsingGET(id: string, options?: any): AxiosPromise<EntityModelParty> {
            return PartyEntityApiFp(configuration).findByIdPartyUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByPartyIdAndpartnerIdsParty
         * @param {string} [partnerIds] partnerIds
         * @param {string} [partyId] partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPartyIdAndpartnerIdsPartyUsingGET(partnerIds?: string, partyId?: string, options?: any): AxiosPromise<EntityModelParty> {
            return PartyEntityApiFp(configuration).findByPartyIdAndpartnerIdsPartyUsingGET(partnerIds, partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsParty
         * @param {string} [partnerIds] partnerIds
         * @param {string} [partnerPartyLinkId] partnerPartyLinkId
         * @param {string} [partnerPartyRef] partnerPartyRef
         * @param {string} [partyId] partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsPartyUsingGET(partnerIds?: string, partnerPartyLinkId?: string, partnerPartyRef?: string, partyId?: string, options?: any): AxiosPromise<CollectionModelPartnerPartyDetailProjection> {
            return PartyEntityApiFp(configuration).findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsPartyUsingGET(partnerIds, partnerPartyLinkId, partnerPartyRef, partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveParty
         * @param {string} id id
         * @param {Party3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartyUsingPATCH(id: string, body: Party3, options?: any): AxiosPromise<EntityModelParty> {
            return PartyEntityApiFp(configuration).savePartyUsingPATCH(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveParty
         * @param {Party1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartyUsingPOST(body: Party1, options?: any): AxiosPromise<EntityModelParty> {
            return PartyEntityApiFp(configuration).savePartyUsingPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveParty
         * @param {string} id id
         * @param {Party2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePartyUsingPUT(id: string, body: Party2, options?: any): AxiosPromise<EntityModelParty> {
            return PartyEntityApiFp(configuration).savePartyUsingPUT(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartyEntityApi - object-oriented interface
 * @export
 * @class PartyEntityApi
 * @extends {BaseAPI}
 */
export class PartyEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteParty
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public deletePartyUsingDELETE(id: string, options?: any) {
        return PartyEntityApiFp(this.configuration).deletePartyUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary fetchPartyByPartnerIdParty
     * @param {string} [partnerId] partnerId
     * @param {string} [partyId] partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public fetchPartyByPartnerIdPartyUsingGET(partnerId?: string, partyId?: string, options?: any) {
        return PartyEntityApiFp(this.configuration).fetchPartyByPartnerIdPartyUsingGET(partnerId, partyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findAllParty
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public findAllPartyUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return PartyEntityApiFp(this.configuration).findAllPartyUsingGET(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByEmailHashInOrPhoneHashInParty
     * @param {string} [emailHashList] emailHashList
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {string} [partnerIds] partnerIds
     * @param {string} [phoneHashList] phoneHashList
     * @param {number} [size] Number of records per page.
     * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public findByEmailHashInOrPhoneHashInPartyUsingGET(emailHashList?: string, page?: number, partnerIds?: string, phoneHashList?: string, size?: number, sort?: string, options?: any) {
        return PartyEntityApiFp(this.configuration).findByEmailHashInOrPhoneHashInPartyUsingGET(emailHashList, page, partnerIds, phoneHashList, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByEmailHashParty
     * @param {string} [emailHash] emailHash
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public findByEmailHashPartyUsingGET(emailHash?: string, options?: any) {
        return PartyEntityApiFp(this.configuration).findByEmailHashPartyUsingGET(emailHash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByFirstNameAndLastNameParty
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {string} [partnerIds] partnerIds
     * @param {number} [size] Number of records per page.
     * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public findByFirstNameAndLastNamePartyUsingGET(page?: number, partnerIds?: string, size?: number, sort?: string, options?: any) {
        return PartyEntityApiFp(this.configuration).findByFirstNameAndLastNamePartyUsingGET(page, partnerIds, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByFirstNameOrLastNameOrAliasParty
     * @param {string} [alias] alias
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {string} [partnerIds] partnerIds
     * @param {number} [size] Number of records per page.
     * @param {string} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public findByFirstNameOrLastNameOrAliasPartyUsingGET(alias?: string, page?: number, partnerIds?: string, size?: number, sort?: string, options?: any) {
        return PartyEntityApiFp(this.configuration).findByFirstNameOrLastNameOrAliasPartyUsingGET(alias, page, partnerIds, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByIdParty
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public findByIdPartyUsingGET(id: string, options?: any) {
        return PartyEntityApiFp(this.configuration).findByIdPartyUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByPartyIdAndpartnerIdsParty
     * @param {string} [partnerIds] partnerIds
     * @param {string} [partyId] partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public findByPartyIdAndpartnerIdsPartyUsingGET(partnerIds?: string, partyId?: string, options?: any) {
        return PartyEntityApiFp(this.configuration).findByPartyIdAndpartnerIdsPartyUsingGET(partnerIds, partyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsParty
     * @param {string} [partnerIds] partnerIds
     * @param {string} [partnerPartyLinkId] partnerPartyLinkId
     * @param {string} [partnerPartyRef] partnerPartyRef
     * @param {string} [partyId] partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsPartyUsingGET(partnerIds?: string, partnerPartyLinkId?: string, partnerPartyRef?: string, partyId?: string, options?: any) {
        return PartyEntityApiFp(this.configuration).findPartyDetailsByPartnerPartyIdOrPartyIdAndpartnerIdsPartyUsingGET(partnerIds, partnerPartyLinkId, partnerPartyRef, partyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveParty
     * @param {string} id id
     * @param {Party3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public savePartyUsingPATCH(id: string, body: Party3, options?: any) {
        return PartyEntityApiFp(this.configuration).savePartyUsingPATCH(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveParty
     * @param {Party1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public savePartyUsingPOST(body: Party1, options?: any) {
        return PartyEntityApiFp(this.configuration).savePartyUsingPOST(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveParty
     * @param {string} id id
     * @param {Party2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyEntityApi
     */
    public savePartyUsingPUT(id: string, body: Party2, options?: any) {
        return PartyEntityApiFp(this.configuration).savePartyUsingPUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * ProfileControllerApi - axios parameter creator
 * @export
 */
export const ProfileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary listAllFormsOfMetadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllFormsOfMetadataUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary profileOptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileOptionsUsingOPTIONS: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileControllerApi - functional programming interface
 * @export
 */
export const ProfileControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary listAllFormsOfMetadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllFormsOfMetadataUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RepresentationModelObject>> {
            const localVarAxiosArgs = await ProfileControllerApiAxiosParamCreator(configuration).listAllFormsOfMetadataUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary profileOptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileOptionsUsingOPTIONS(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await ProfileControllerApiAxiosParamCreator(configuration).profileOptionsUsingOPTIONS(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProfileControllerApi - factory interface
 * @export
 */
export const ProfileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary listAllFormsOfMetadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllFormsOfMetadataUsingGET(options?: any): AxiosPromise<RepresentationModelObject> {
            return ProfileControllerApiFp(configuration).listAllFormsOfMetadataUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary profileOptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileOptionsUsingOPTIONS(options?: any): AxiosPromise<object> {
            return ProfileControllerApiFp(configuration).profileOptionsUsingOPTIONS(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileControllerApi - object-oriented interface
 * @export
 * @class ProfileControllerApi
 * @extends {BaseAPI}
 */
export class ProfileControllerApi extends BaseAPI {
    /**
     * 
     * @summary listAllFormsOfMetadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public listAllFormsOfMetadataUsingGET(options?: any) {
        return ProfileControllerApiFp(this.configuration).listAllFormsOfMetadataUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary profileOptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public profileOptionsUsingOPTIONS(options?: any) {
        return ProfileControllerApiFp(this.configuration).profileOptionsUsingOPTIONS(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * RolePermissionEntityApi - axios parameter creator
 * @export
 */
export const RolePermissionEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteByRoleNameAndPermissionInRolePermission
         * @param {Array<string>} [permission] permission
         * @param {string} [roleName] roleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByRoleNameAndPermissionInRolePermissionUsingGET: async (permission?: Array<string>, roleName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/rolePermissions/search/deleteByRoleNameAndPermissionIn`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (permission) {
                localVarQueryParameter['permission'] = permission;
            }

            if (roleName !== undefined) {
                localVarQueryParameter['roleName'] = roleName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteRolePermission
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRolePermissionUsingDELETE: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteRolePermissionUsingDELETE.');
            }
            const localVarPath = `/rolePermissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllRolePermission
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllRolePermissionUsingGET: async (page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/rolePermissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdRolePermission
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdRolePermissionUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdRolePermissionUsingGET.');
            }
            const localVarPath = `/rolePermissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByRoleNameAndPartnerIdRolePermission
         * @param {string} [partnerId] partnerId
         * @param {string} [roleName] roleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRoleNameAndPartnerIdRolePermissionUsingGET: async (partnerId?: string, roleName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/rolePermissions/search/findByRoleNameAndPartnerId`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }

            if (roleName !== undefined) {
                localVarQueryParameter['roleName'] = roleName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByRoleNameRolePermission
         * @param {string} [roleName] roleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRoleNameRolePermissionUsingGET: async (roleName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/rolePermissions/search/findByRoleName`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roleName !== undefined) {
                localVarQueryParameter['roleName'] = roleName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveRolePermission
         * @param {string} id id
         * @param {RolePermission3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRolePermissionUsingPATCH: async (id: string, body: RolePermission3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveRolePermissionUsingPATCH.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveRolePermissionUsingPATCH.');
            }
            const localVarPath = `/rolePermissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveRolePermission
         * @param {RolePermission1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRolePermissionUsingPOST: async (body: RolePermission1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveRolePermissionUsingPOST.');
            }
            const localVarPath = `/rolePermissions`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveRolePermission
         * @param {string} id id
         * @param {RolePermission2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRolePermissionUsingPUT: async (id: string, body: RolePermission2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveRolePermissionUsingPUT.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveRolePermissionUsingPUT.');
            }
            const localVarPath = `/rolePermissions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolePermissionEntityApi - functional programming interface
 * @export
 */
export const RolePermissionEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteByRoleNameAndPermissionInRolePermission
         * @param {Array<string>} [permission] permission
         * @param {string} [roleName] roleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByRoleNameAndPermissionInRolePermissionUsingGET(permission?: Array<string>, roleName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await RolePermissionEntityApiAxiosParamCreator(configuration).deleteByRoleNameAndPermissionInRolePermissionUsingGET(permission, roleName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary deleteRolePermission
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRolePermissionUsingDELETE(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await RolePermissionEntityApiAxiosParamCreator(configuration).deleteRolePermissionUsingDELETE(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findAllRolePermission
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllRolePermissionUsingGET(page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelRolePermission>> {
            const localVarAxiosArgs = await RolePermissionEntityApiAxiosParamCreator(configuration).findAllRolePermissionUsingGET(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByIdRolePermission
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIdRolePermissionUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelRolePermission>> {
            const localVarAxiosArgs = await RolePermissionEntityApiAxiosParamCreator(configuration).findByIdRolePermissionUsingGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByRoleNameAndPartnerIdRolePermission
         * @param {string} [partnerId] partnerId
         * @param {string} [roleName] roleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByRoleNameAndPartnerIdRolePermissionUsingGET(partnerId?: string, roleName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelRolePermission>> {
            const localVarAxiosArgs = await RolePermissionEntityApiAxiosParamCreator(configuration).findByRoleNameAndPartnerIdRolePermissionUsingGET(partnerId, roleName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByRoleNameRolePermission
         * @param {string} [roleName] roleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByRoleNameRolePermissionUsingGET(roleName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelRolePermission>> {
            const localVarAxiosArgs = await RolePermissionEntityApiAxiosParamCreator(configuration).findByRoleNameRolePermissionUsingGET(roleName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveRolePermission
         * @param {string} id id
         * @param {RolePermission3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveRolePermissionUsingPATCH(id: string, body: RolePermission3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelRolePermission>> {
            const localVarAxiosArgs = await RolePermissionEntityApiAxiosParamCreator(configuration).saveRolePermissionUsingPATCH(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveRolePermission
         * @param {RolePermission1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveRolePermissionUsingPOST(body: RolePermission1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelRolePermission>> {
            const localVarAxiosArgs = await RolePermissionEntityApiAxiosParamCreator(configuration).saveRolePermissionUsingPOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveRolePermission
         * @param {string} id id
         * @param {RolePermission2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveRolePermissionUsingPUT(id: string, body: RolePermission2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelRolePermission>> {
            const localVarAxiosArgs = await RolePermissionEntityApiAxiosParamCreator(configuration).saveRolePermissionUsingPUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RolePermissionEntityApi - factory interface
 * @export
 */
export const RolePermissionEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary deleteByRoleNameAndPermissionInRolePermission
         * @param {Array<string>} [permission] permission
         * @param {string} [roleName] roleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByRoleNameAndPermissionInRolePermissionUsingGET(permission?: Array<string>, roleName?: string, options?: any): AxiosPromise<void> {
            return RolePermissionEntityApiFp(configuration).deleteByRoleNameAndPermissionInRolePermissionUsingGET(permission, roleName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteRolePermission
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRolePermissionUsingDELETE(id: string, options?: any): AxiosPromise<void> {
            return RolePermissionEntityApiFp(configuration).deleteRolePermissionUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findAllRolePermission
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllRolePermissionUsingGET(page?: number, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelRolePermission> {
            return RolePermissionEntityApiFp(configuration).findAllRolePermissionUsingGET(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByIdRolePermission
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdRolePermissionUsingGET(id: string, options?: any): AxiosPromise<EntityModelRolePermission> {
            return RolePermissionEntityApiFp(configuration).findByIdRolePermissionUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByRoleNameAndPartnerIdRolePermission
         * @param {string} [partnerId] partnerId
         * @param {string} [roleName] roleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRoleNameAndPartnerIdRolePermissionUsingGET(partnerId?: string, roleName?: string, options?: any): AxiosPromise<CollectionModelRolePermission> {
            return RolePermissionEntityApiFp(configuration).findByRoleNameAndPartnerIdRolePermissionUsingGET(partnerId, roleName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByRoleNameRolePermission
         * @param {string} [roleName] roleName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByRoleNameRolePermissionUsingGET(roleName?: string, options?: any): AxiosPromise<CollectionModelRolePermission> {
            return RolePermissionEntityApiFp(configuration).findByRoleNameRolePermissionUsingGET(roleName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveRolePermission
         * @param {string} id id
         * @param {RolePermission3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRolePermissionUsingPATCH(id: string, body: RolePermission3, options?: any): AxiosPromise<EntityModelRolePermission> {
            return RolePermissionEntityApiFp(configuration).saveRolePermissionUsingPATCH(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveRolePermission
         * @param {RolePermission1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRolePermissionUsingPOST(body: RolePermission1, options?: any): AxiosPromise<EntityModelRolePermission> {
            return RolePermissionEntityApiFp(configuration).saveRolePermissionUsingPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveRolePermission
         * @param {string} id id
         * @param {RolePermission2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveRolePermissionUsingPUT(id: string, body: RolePermission2, options?: any): AxiosPromise<EntityModelRolePermission> {
            return RolePermissionEntityApiFp(configuration).saveRolePermissionUsingPUT(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RolePermissionEntityApi - object-oriented interface
 * @export
 * @class RolePermissionEntityApi
 * @extends {BaseAPI}
 */
export class RolePermissionEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteByRoleNameAndPermissionInRolePermission
     * @param {Array<string>} [permission] permission
     * @param {string} [roleName] roleName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolePermissionEntityApi
     */
    public deleteByRoleNameAndPermissionInRolePermissionUsingGET(permission?: Array<string>, roleName?: string, options?: any) {
        return RolePermissionEntityApiFp(this.configuration).deleteByRoleNameAndPermissionInRolePermissionUsingGET(permission, roleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteRolePermission
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolePermissionEntityApi
     */
    public deleteRolePermissionUsingDELETE(id: string, options?: any) {
        return RolePermissionEntityApiFp(this.configuration).deleteRolePermissionUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findAllRolePermission
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolePermissionEntityApi
     */
    public findAllRolePermissionUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return RolePermissionEntityApiFp(this.configuration).findAllRolePermissionUsingGET(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByIdRolePermission
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolePermissionEntityApi
     */
    public findByIdRolePermissionUsingGET(id: string, options?: any) {
        return RolePermissionEntityApiFp(this.configuration).findByIdRolePermissionUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByRoleNameAndPartnerIdRolePermission
     * @param {string} [partnerId] partnerId
     * @param {string} [roleName] roleName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolePermissionEntityApi
     */
    public findByRoleNameAndPartnerIdRolePermissionUsingGET(partnerId?: string, roleName?: string, options?: any) {
        return RolePermissionEntityApiFp(this.configuration).findByRoleNameAndPartnerIdRolePermissionUsingGET(partnerId, roleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByRoleNameRolePermission
     * @param {string} [roleName] roleName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolePermissionEntityApi
     */
    public findByRoleNameRolePermissionUsingGET(roleName?: string, options?: any) {
        return RolePermissionEntityApiFp(this.configuration).findByRoleNameRolePermissionUsingGET(roleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveRolePermission
     * @param {string} id id
     * @param {RolePermission3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolePermissionEntityApi
     */
    public saveRolePermissionUsingPATCH(id: string, body: RolePermission3, options?: any) {
        return RolePermissionEntityApiFp(this.configuration).saveRolePermissionUsingPATCH(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveRolePermission
     * @param {RolePermission1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolePermissionEntityApi
     */
    public saveRolePermissionUsingPOST(body: RolePermission1, options?: any) {
        return RolePermissionEntityApiFp(this.configuration).saveRolePermissionUsingPOST(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveRolePermission
     * @param {string} id id
     * @param {RolePermission2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolePermissionEntityApi
     */
    public saveRolePermissionUsingPUT(id: string, body: RolePermission2, options?: any) {
        return RolePermissionEntityApiFp(this.configuration).saveRolePermissionUsingPUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SMSHistoryEntityApi - axios parameter creator
 * @export
 */
export const SMSHistoryEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteSMSHistory
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSMSHistoryUsingDELETE: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteSMSHistoryUsingDELETE.');
            }
            const localVarPath = `/sMSHistories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllSMSHistory
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSMSHistoryUsingGET: async (page?: number, size?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sMSHistories`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdSMSHistory
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdSMSHistoryUsingGET: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdSMSHistoryUsingGET.');
            }
            const localVarPath = `/sMSHistories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByPartyIdOrderByCreatedDescSMSHistory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPartyIdOrderByCreatedDescSMSHistoryUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sMSHistories/search/findByPartyIdOrderByCreatedDesc`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByTemplateNameAndCreatedAfterSMSHistory
         * @param {string} [param0] param0
         * @param {string} [param1] param1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTemplateNameAndCreatedAfterSMSHistoryUsingGET: async (param0?: string, param1?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/sMSHistories/search/findByTemplateNameAndCreatedAfter`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (param0 !== undefined) {
                localVarQueryParameter['param0'] = param0;
            }

            if (param1 !== undefined) {
                localVarQueryParameter['param1'] = (param1 as any instanceof Date) ?
                    (param1 as any).toISOString() :
                    param1;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveSMSHistory
         * @param {string} id id
         * @param {SMSHistory3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSMSHistoryUsingPATCH: async (id: string, body: SMSHistory3, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveSMSHistoryUsingPATCH.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveSMSHistoryUsingPATCH.');
            }
            const localVarPath = `/sMSHistories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveSMSHistory
         * @param {SMSHistory1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSMSHistoryUsingPOST: async (body: SMSHistory1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveSMSHistoryUsingPOST.');
            }
            const localVarPath = `/sMSHistories`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveSMSHistory
         * @param {string} id id
         * @param {SMSHistory2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSMSHistoryUsingPUT: async (id: string, body: SMSHistory2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveSMSHistoryUsingPUT.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveSMSHistoryUsingPUT.');
            }
            const localVarPath = `/sMSHistories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SMSHistoryEntityApi - functional programming interface
 * @export
 */
export const SMSHistoryEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteSMSHistory
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSMSHistoryUsingDELETE(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await SMSHistoryEntityApiAxiosParamCreator(configuration).deleteSMSHistoryUsingDELETE(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findAllSMSHistory
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllSMSHistoryUsingGET(page?: number, size?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelSMSHistory>> {
            const localVarAxiosArgs = await SMSHistoryEntityApiAxiosParamCreator(configuration).findAllSMSHistoryUsingGET(page, size, sort, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByIdSMSHistory
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByIdSMSHistoryUsingGET(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelSMSHistory>> {
            const localVarAxiosArgs = await SMSHistoryEntityApiAxiosParamCreator(configuration).findByIdSMSHistoryUsingGET(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByPartyIdOrderByCreatedDescSMSHistory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByPartyIdOrderByCreatedDescSMSHistoryUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelSMSHistory>> {
            const localVarAxiosArgs = await SMSHistoryEntityApiAxiosParamCreator(configuration).findByPartyIdOrderByCreatedDescSMSHistoryUsingGET(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary findByTemplateNameAndCreatedAfterSMSHistory
         * @param {string} [param0] param0
         * @param {string} [param1] param1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByTemplateNameAndCreatedAfterSMSHistoryUsingGET(param0?: string, param1?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectionModelSMSHistory>> {
            const localVarAxiosArgs = await SMSHistoryEntityApiAxiosParamCreator(configuration).findByTemplateNameAndCreatedAfterSMSHistoryUsingGET(param0, param1, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveSMSHistory
         * @param {string} id id
         * @param {SMSHistory3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSMSHistoryUsingPATCH(id: string, body: SMSHistory3, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelSMSHistory>> {
            const localVarAxiosArgs = await SMSHistoryEntityApiAxiosParamCreator(configuration).saveSMSHistoryUsingPATCH(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveSMSHistory
         * @param {SMSHistory1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSMSHistoryUsingPOST(body: SMSHistory1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelSMSHistory>> {
            const localVarAxiosArgs = await SMSHistoryEntityApiAxiosParamCreator(configuration).saveSMSHistoryUsingPOST(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary saveSMSHistory
         * @param {string} id id
         * @param {SMSHistory2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSMSHistoryUsingPUT(id: string, body: SMSHistory2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityModelSMSHistory>> {
            const localVarAxiosArgs = await SMSHistoryEntityApiAxiosParamCreator(configuration).saveSMSHistoryUsingPUT(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SMSHistoryEntityApi - factory interface
 * @export
 */
export const SMSHistoryEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary deleteSMSHistory
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSMSHistoryUsingDELETE(id: string, options?: any): AxiosPromise<void> {
            return SMSHistoryEntityApiFp(configuration).deleteSMSHistoryUsingDELETE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findAllSMSHistory
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllSMSHistoryUsingGET(page?: number, size?: number, sort?: string, options?: any): AxiosPromise<CollectionModelSMSHistory> {
            return SMSHistoryEntityApiFp(configuration).findAllSMSHistoryUsingGET(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByIdSMSHistory
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdSMSHistoryUsingGET(id: string, options?: any): AxiosPromise<EntityModelSMSHistory> {
            return SMSHistoryEntityApiFp(configuration).findByIdSMSHistoryUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByPartyIdOrderByCreatedDescSMSHistory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByPartyIdOrderByCreatedDescSMSHistoryUsingGET(options?: any): AxiosPromise<CollectionModelSMSHistory> {
            return SMSHistoryEntityApiFp(configuration).findByPartyIdOrderByCreatedDescSMSHistoryUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary findByTemplateNameAndCreatedAfterSMSHistory
         * @param {string} [param0] param0
         * @param {string} [param1] param1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTemplateNameAndCreatedAfterSMSHistoryUsingGET(param0?: string, param1?: string, options?: any): AxiosPromise<CollectionModelSMSHistory> {
            return SMSHistoryEntityApiFp(configuration).findByTemplateNameAndCreatedAfterSMSHistoryUsingGET(param0, param1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveSMSHistory
         * @param {string} id id
         * @param {SMSHistory3} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSMSHistoryUsingPATCH(id: string, body: SMSHistory3, options?: any): AxiosPromise<EntityModelSMSHistory> {
            return SMSHistoryEntityApiFp(configuration).saveSMSHistoryUsingPATCH(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveSMSHistory
         * @param {SMSHistory1} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSMSHistoryUsingPOST(body: SMSHistory1, options?: any): AxiosPromise<EntityModelSMSHistory> {
            return SMSHistoryEntityApiFp(configuration).saveSMSHistoryUsingPOST(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saveSMSHistory
         * @param {string} id id
         * @param {SMSHistory2} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSMSHistoryUsingPUT(id: string, body: SMSHistory2, options?: any): AxiosPromise<EntityModelSMSHistory> {
            return SMSHistoryEntityApiFp(configuration).saveSMSHistoryUsingPUT(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SMSHistoryEntityApi - object-oriented interface
 * @export
 * @class SMSHistoryEntityApi
 * @extends {BaseAPI}
 */
export class SMSHistoryEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteSMSHistory
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSHistoryEntityApi
     */
    public deleteSMSHistoryUsingDELETE(id: string, options?: any) {
        return SMSHistoryEntityApiFp(this.configuration).deleteSMSHistoryUsingDELETE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findAllSMSHistory
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSHistoryEntityApi
     */
    public findAllSMSHistoryUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return SMSHistoryEntityApiFp(this.configuration).findAllSMSHistoryUsingGET(page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByIdSMSHistory
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSHistoryEntityApi
     */
    public findByIdSMSHistoryUsingGET(id: string, options?: any) {
        return SMSHistoryEntityApiFp(this.configuration).findByIdSMSHistoryUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByPartyIdOrderByCreatedDescSMSHistory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSHistoryEntityApi
     */
    public findByPartyIdOrderByCreatedDescSMSHistoryUsingGET(options?: any) {
        return SMSHistoryEntityApiFp(this.configuration).findByPartyIdOrderByCreatedDescSMSHistoryUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary findByTemplateNameAndCreatedAfterSMSHistory
     * @param {string} [param0] param0
     * @param {string} [param1] param1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSHistoryEntityApi
     */
    public findByTemplateNameAndCreatedAfterSMSHistoryUsingGET(param0?: string, param1?: string, options?: any) {
        return SMSHistoryEntityApiFp(this.configuration).findByTemplateNameAndCreatedAfterSMSHistoryUsingGET(param0, param1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveSMSHistory
     * @param {string} id id
     * @param {SMSHistory3} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSHistoryEntityApi
     */
    public saveSMSHistoryUsingPATCH(id: string, body: SMSHistory3, options?: any) {
        return SMSHistoryEntityApiFp(this.configuration).saveSMSHistoryUsingPATCH(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveSMSHistory
     * @param {SMSHistory1} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSHistoryEntityApi
     */
    public saveSMSHistoryUsingPOST(body: SMSHistory1, options?: any) {
        return SMSHistoryEntityApiFp(this.configuration).saveSMSHistoryUsingPOST(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saveSMSHistory
     * @param {string} id id
     * @param {SMSHistory2} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SMSHistoryEntityApi
     */
    public saveSMSHistoryUsingPUT(id: string, body: SMSHistory2, options?: any) {
        return SMSHistoryEntityApiFp(this.configuration).saveSMSHistoryUsingPUT(id, body, options).then((request) => request(this.axios, this.basePath));
    }

}


