import React from 'react'
import Workspace from './app/components/Workspace'
import './App.css'
import Loading from './app/components/Loading'
import {useSelector} from 'react-redux'
import {selectCurrentUser, selectCurrentType} from './app/reducer'
import {VBox} from '@bakkt/components'
import MerchantHeader from './merchant/general/MerchantHeader'
import PartnerHeader from './partner/general/PartnerHeader'
function App() {
	const currentUser = useSelector(selectCurrentUser)
	const currentType = useSelector(selectCurrentType)

	if (!currentUser) {
		return <Loading />
	}
	return (
		<>
			<VBox className='App' style={{paddingLeft: 0, paddingRight: 0}}>
				{currentType && currentType === 'merchant' && <MerchantHeader></MerchantHeader>}
				{currentType && currentType === 'partner' && <PartnerHeader></PartnerHeader>}
				<Workspace />
			</VBox>
			<div id='popupArea' />
			<div id='popupMenu' />
			<Loading />
		</>
	)
}

export default App
