import numeral from 'numeral'
import * as api from '@bakkt/api'

export const formatWithSeparatorAndDecimals = (value: number | undefined) => {
	return value && numeral(value).format('0,0.00')
}

export const formatWithDollarSign = (value: number | undefined) => {
	return value !== undefined && '$' + numeral(value).format('0,0.00')
}

export const convertToPercentage = (value: number) => {
	if (!value && value !== 0) {
		return '--'
	}
	return Number((value * 100).toFixed(2)) + '%'
}

export const convertToNumberPercentage = (value: number) => {
	return Number((value * 100).toFixed(4))
}

export const convertFromPercentageToDecimal = (percentage: number) => {
	return Number((percentage / 100).toFixed(4))
}

export const validatePercentage = (value: number) => {
	if (value > 1 || value < 0 || value === undefined || value === null) {
		return false
	}
	return true
}

export const maskString = (str: string, requestedLength: number) => {
	if (str) {
		const unmaskLength = str.length < requestedLength ? str.length : requestedLength
		const unMaskedChars = unmaskLength ? str.slice(-unmaskLength) : ''
		return 'x'.repeat(str.length - unmaskLength) + unMaskedChars
	}
	return null
}

export const bytesToSize = (bytes: number) => {
	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
	if (bytes == 0) return '0 Byte'
	const i = Math.floor(Math.log(bytes) / Math.log(1024))
	return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}

const CURRENCY_SYMBOL = {
	[api.ApiMerchantPortalTransactionCurrencyEnum.USD]: '$',
	[api.ApiMerchantPortalTransactionCurrencyEnum.BTC]: '฿',
	[api.ApiMerchantPortalTransactionCurrencyEnum.LTC]: 'Ł',
	[api.ApiMerchantPortalTransactionCurrencyEnum.BCH]: '₿',
	[api.ApiMerchantPortalTransactionCurrencyEnum.ETH]: 'Ξ',
}

const CURRENCY_SCALE = {
	[api.ApiMerchantPortalTransactionCurrencyEnum.USD]: 2,
	[api.ApiMerchantPortalTransactionCurrencyEnum.BTC]: 8,
	[api.ApiMerchantPortalTransactionCurrencyEnum.LTC]: 8,
	[api.ApiMerchantPortalTransactionCurrencyEnum.BCH]: 8,
	[api.ApiMerchantPortalTransactionCurrencyEnum.ETH]: 18,
}

export function formatCurrencyToSymbol(currency: string) {
	return CURRENCY_SYMBOL[currency] || CURRENCY_SYMBOL.USD
}

export function formatAmount(value: number, currency: string) {
	if (isNaN(value) || !currency) {
		return ''
	}
	if (CURRENCY_SYMBOL[currency] === CURRENCY_SYMBOL.USD) {
		return `${formatCurrencyToSymbol(currency)}${formatWithSeparatorAndDecimals(value)}`
	} else {
		return ` ${formatCryptoAmount(value, currency)}`
	}
}

export function formatCryptoAmount(value: number, currency: string) {
	if (!value || isNaN(value) || !currency) {
		return ''
	}
	return `${CURRENCY_SYMBOL[currency] || currency} ${formatAmountValue(value, currency)}`
}
export function formatAmountValue(value: number, currency: string) {
	return formatDecimalNumber(value, CURRENCY_SCALE[currency] | 0)
}
export function formatDecimalNumber(value: number, maxDecimalPlace: number) {
	return value.toLocaleString(undefined, {maximumFractionDigits: maxDecimalPlace})
}

export function formatWithWholeNumber(value: number) {
	return numeral(value).format('0')
}

export function formatNumbersToKandM(value: number): string {
	if (value == null) {
		return null
	}
	if (value > 999 && value < 1000000) {
		return (value / 1000).toFixed(2) + 'K'
	} else if (value > 1000000) {
		return (value / 1000000).toFixed(2) + 'M'
	} else if (value < 900) {
		return String(value)
	}
}
