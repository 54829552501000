export const TRANSACTION_DETAILS = {
	type: 'TXN Type',
	bakktTransactionId: 'Bakkt TXN ID',
	merchantTransactionId: 'Partner TXN ID',
	merchantConsumerId: 'Partner Customer ID',
	createdDate: 'Created Date',
	consumerName: 'Customer Name',
	consumerEmail: 'Customer Email',
	amount: 'Amount',
	fee: 'Fee',
	actionTakenBy: 'Action Taken By',
	status: 'Status',
	disputeStatus: 'Dispute Status',
	settlementDate: 'Settlement Date',
	description: 'Description',
	txnDate: 'Txn Date',
	id: 'TXN ID',
	notes: 'Notes',
	agent: 'Agent',
}

export const buttonList = {
	cancel: {
		displayText: 'Cancel',
		title: 'Cancel',
	},
	void: {
		displayText: 'Void',
		title: 'Void',
	},
	refund: {
		displayText: 'Refund',
		title: 'Refund',
	},
}
